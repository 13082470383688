import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


export default class Prev3D extends Component {

    constructor(props){
        super(props);

        this.state={
            error:"",
            data_row:{}
        }

        this.data = []
        this.data_column=[
            {dataField:'id_model',hidden:'true'},
            {dataField:'model_name',text:'Kacamata'},
        ]
        this.selectRow = {
            mode:'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: {backgroundColor:"#c8e6c9"},
            onSelect: (row, isSelect, rowIndex, e) => {
              this.setState({data_row:row})
            }
        }
    }
    
    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        AuthService.mediaList().then(
            (data) => {
                if (data.results){
            
                    this.data=data.results
                  }
                  else{
                    console.log(data.message)
                    this.setState({error:data.message})
                  }
            }
        )
    }

    pilih = () =>{
      this.props.change3D(this.state.data_row.id_model,this.state.data_row.model_name)
      this.props.close()
    }
    
  render() {
    const { SearchBar, ClearSearchButton } = Search;
    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 10,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: false,
      alwaysShowAllBtns: false,
      onPageChange: function (page, sizePerPage) {

      },
      onSizePerPageChange: function (page, sizePerPage) {
      }
    });

    return (
    
      <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pilih 3D</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
                <div className="col">
                  <model-viewer alt='test' camera-controls auto-rotate src={this.state.data_row.location}
                  style={{width:100+'%',height:100+'%'}}>
                  </model-viewer>
                </div>
                <div className="col">
                <ToolkitProvider
              bootstrap4
              keyField='id_model'
              data={this.data}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      
                      <BootstrapTable
                      id = 'list3d'
                      condensed
                      hover
                      
                      selectRow={this.selectRow}
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>

                </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={this.props.close}>
                Close
            </button>
            <button type="button" className="btn btn-primary" onClick={this.pilih}>
                Pilih
            </button>
          </Modal.Footer>
        </Modal>
      
    )
  }
}
