import React, { Component, Fragment } from 'react'
import {Modal} from 'react-bootstrap';

export default class PopGlobal extends Component {
  render() {
    return (
    <Modal
        show={this.props.show}
        onHide={this.props.close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={"alert "+ this.props.message_type} role="alert">
            {this.props.message}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={this.props.close}>
              OKE
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
