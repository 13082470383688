import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";

import cookie from "react-cookies";
import Media from "./components/media/media";
import Login from "./components/auth/login";

import List_users from "./components/user_matta/list-user-matta";
import Detail_user from "./components/user_matta/detail-user-matta";
import Product_matta from "./components/product_matta/all-product-matta";
import New_product from "./components/product_matta/add-new-product";
import DetailProduct from "./components/product_matta/detail-product";
import AddLens from "./components/product_matta/add-new-lens";
import Lens_matta from "./components/product_matta/all-lens-matta";
import DetailLens from "./components/product_matta/detail-lens";

//admins
import List_admin from "./components/user_matta/list-admin-matta";
import AddAdmin from "./components/user_matta/add-admin";
import DetailAdmin from "./components/user_matta/detail-admin_matta";

//transaction
import AllTransaction from "./components/transaction_matta/all_transaction/all-trx";
import AllShipping from "./components/transaction_matta/shipping/shipping";
import Payment from "./components/transaction_matta/payment/payment";
//import Promotion from './components/transaction_matta/promotion/promo';
import Promotion from "./components/transaction_matta/promotion/new_promo";

// loyalty
import AllLoyalty from "./components/loyalty/all-loyalty";
import AddLoyalty from "./components/loyalty/add-loyalty";
import RewardLoyalty from "./components/loyalty/reward-loyalty";
import AddReward from "./components/loyalty/add-reward";

// campaign
import AllCampaign from "./components/campaign/all_campaign/all-campaign";
import AddCampaign from "./components/campaign/add_campaign/add-campaign";
import EditCampaign from "./components/campaign/all_campaign/edit-campaign";

// notification
import AllNotification from './components/campaign/notification/all-notification';
import AddNotification from './components/campaign/notification/add-notification';

//dashboardmatta
import Dashboard from "./components/dashboard_matta";

//brand
import List_brand from "./components/manage/brand_kacamata/list_brand_kacamata";
import AddNewBrandFrame from "./components/manage/brand_kacamata/new_brand_kacamata";
import DetailBrandFrame from "./components/manage/brand_kacamata/detail_brand_frame";
import List_brand_lensa from "./components/manage/brand_lensa/list_brand_lensa";
import AddNewBrandLensa from "./components/manage/brand_lensa/new_brand_lensa";
import DetailBrandLensa from "./components/manage/brand_lensa/detail_brand_lensa";

//perloginan
import ForgotPassword from "./components/auth/forgot_password";
import OtpForgot from "./components/auth/otp_forgot";
import NewPassword from "./components/auth/new_password";
import OtpForce from "./components/auth/otp_force";

//testmata
import ConstantMatta from "./components/test_matta/constantmatta";

class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_in: false,
      user_data: {},
    };
  }

  componentDidMount() {
    let logged_in = cookie.load("logged_in");
    let status = "";

    if (logged_in) {
      status = logged_in.status;
    }
    if (status === true) {
      this.setState({ logged_in: true });
    }
  }

  render() {
    return (
      <BrowserRouter basename={"/"}>
        <ScrollContext>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              render={(props) => <Login {...props} />}
            />

            {/* <Route 
                    exact 
                    path={`${process.env.PUBLIC_URL}/`} 
                    >
                        <Redirect to="/dashboard"/>
                    </Route> */}

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/login`}
              render={(props) => (
                <Login {...props} logged_in={this.state.logged_in} />
              )}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/forgot`}
              render={(props) => <ForgotPassword {...props} />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/otpforgot`}
              render={(props) => <OtpForgot {...props} />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/newpassword`}
              render={(props) => <NewPassword {...props} />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/otpforce`}
              render={(props) => <OtpForce {...props} />}
            />

            <App logged_in={this.state.logged_in}>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={Dashboard}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/media`}
                component={Media}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/list-user`}
                component={List_users}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/details`}
                component={Detail_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products`}
                component={Product_matta}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/new-product`}
                component={New_product}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/list-transaction`}
                component={AllTransaction}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/shipping`}
                component={AllShipping}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/payment`}
                component={Payment}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/promotion`}
                component={Promotion}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/all-loyalty`}
                component={AllLoyalty}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/add-loyalty`}
                component={AddLoyalty}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/reward-loyalty`}
                component={RewardLoyalty}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/add-reward`}
                component={AddReward}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/all-campaign`}
                component={AllCampaign}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/add-campaign`}
                component={AddCampaign}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/edit-campaign/:id`}
                render={(props) => (
                  <EditCampaign {...props} id={props.match.params.id} />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/view-campaign/:id`}
                render={(props) => (
                  <EditCampaign
                    {...props}
                    id={props.match.params.id}
                    readonly={true}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/notification`}
                component={AllNotification}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/notification/add`}
                component={AddNotification}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/notification/edit/:id`}
                render={(props) => <AddNotification {...props} edit />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product-detail`}
                component={DetailProduct}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/new-lens`}
                component={AddLens}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lens`}
                component={Lens_matta}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lens-detail`}
                component={DetailLens}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/admins`}
                component={List_admin}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/create-admin`}
                component={AddAdmin}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/detail-admin`}
                component={DetailAdmin}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/frame-brands`}
                component={List_brand}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/new-frame-brands`}
                component={AddNewBrandFrame}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/details-frame-brands`}
                component={DetailBrandFrame}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lens-brands`}
                component={List_brand_lensa}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/new-lens-brands`}
                component={AddNewBrandLensa}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/details-lens-brands`}
                component={DetailBrandLensa}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/constant-matta`}
                component={ConstantMatta}
              />
            </App>
          </Switch>
        </ScrollContext>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
