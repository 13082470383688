import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Edit2, Save, Trash2, Plus } from 'react-feather';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AuthService from '../api_getter/auth_service';
import PrevFoto from '../product_matta_preview/prev_foto';
import Prev3D from '../product_matta_preview/prev_3d';
import SuccessPOP from '../product_matta_preview/pop_success';
import ColorPicker from '../product_matta_preview/color_picker';
import cookie from 'react-cookies';
import ExitNoSave from './exitnosave';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const animatedComponents = makeAnimated();

const ChildProduct = (props) => {
	const [show, setShow] = useState(false);
	const closeModal = () => setShow(false);
	const showModal = () => setShow(true);

	const [show3d, setShow3d] = useState(false);
	const closeModal3D = () => setShow3d(false);
	const showModal3D = () => setShow3d(true);

	const [ColorPop, setColorPop] = useState(false);
	const showColor = () => setColorPop(true);
	const closeColor = () => setColorPop(false);

	const param = {
		id_id: props.data.id_id,
		nama_warna: props.data.nama_warna,
		nama_foto: props.data.nama_foto,
		id_foto: props.data.id_foto,
		nama_3d: props.data.nama_3d,
		id_3d: props.data.id_3d,
		stock: props.data.stock,
		color_code: props.data.color_code,
	};

	const { ...params } = param;
	const [parameter, setParam] = useState(params);
	const changePhoto = (id_photo, name_photo) =>
		setParam({
			...parameter,
			id_foto: id_photo,
			nama_foto: name_photo,
		});
	const change3D = (id_3d, name_3d) =>
		setParam({ ...parameter, id_3d: id_3d, nama_3d: name_3d });
	const changeColor = (colorHex) =>
		setParam({ ...parameter, color_code: colorHex });
	useEffect(() => {
		props.callback(parameter, parameter.id_id);
	});
	return (
		<div className='row' id={props.data.id_id}>
			<div className='col-md-3'>
				<label for='nama_warna'>Nama warna</label>

				<div class='input-group mb-3'>
					<div
						class='input-group-prepend'
						style={{ paddingTop: 10 + 'px', marginRight: 5 + 'px' }}
					>
						<svg width='20' height='20'>
							<rect
								width='20'
								height='20'
								fill={parameter.color_code}
								onClick={showColor}
							/>
						</svg>
					</div>
					<input
						type='text'
						name='nama_warna'
						id='nama_warna'
						className='form-control'
						placeholder='Tambah warna baru'
						value={parameter.nama_warna}
						onChange={(e) => {
							setParam({
								...parameter,
								nama_warna: e.target.value,
							});
						}}
					/>
				</div>
			</div>

			<div className='col-md-3'>
				<label>Foto Model</label>
				<br />
				<div class='input-group mb-3'>
					<input
						type='text'
						name='foto_model'
						class='form-control'
						disabled
						value={parameter.nama_foto}
					/>
					<div class='input-group-append'>
						<button
							class='btn btn-primary'
							type='button'
							onClick={showModal}
						>
							Pilih
						</button>
					</div>
				</div>
			</div>

			<div className='col-md-3'>
				<label>3d Model</label>
				<br />
				<div class='input-group mb-3'>
					<input
						type='text'
						name='model_3d'
						class='form-control'
						disabled
						value={parameter.nama_3d}
					/>
					<div class='input-group-append'>
						<button
							class='btn btn-primary'
							type='button'
							onClick={showModal3D}
						>
							Pilih
						</button>
					</div>
				</div>
			</div>

			<div className='col-md-3 '>
				<label for='counter'>Jumlah Stok</label>
				<div className='input-group mb-3' id='counter'>
					<div class='input-group-prepend'>
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => {
								setParam({
									...parameter,
									stock: parameter.stock - 1,
								});
							}}
						>
							{'-'}
						</button>
					</div>
					<input
						type='number'
						id='stock'
						name='stock'
						className='form-control'
						style={{ textAlign: 'center' }}
						min='0'
						step='1'
						max='999999999'
						placeholder=''
						value={parameter.stock}
						onChange={(e) => {
							setParam({
								...parameter,
								stock: parseInt(e.target.value),
							});
						}}
					/>

					<div class='input-group-append'>
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => {
								setParam({
									...parameter,
									stock: parameter.stock + 1,
								});
							}}
						>
							{'+'}
						</button>
					</div>
				</div>
			</div>

			<PrevFoto
				show={show}
				close={closeModal}
				changePhoto={changePhoto}
			/>
			<Prev3D show={show3d} close={closeModal3D} change3D={change3D} />
			<ColorPicker
				show={ColorPop}
				close={closeColor}
				changeColor={changeColor}
			/>
		</div>
	);
};

const Identitas = (props) => {
	const data = props.data;
	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Identitas Produk</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={props.edit}
						>
							Edit
						</button>
					</div>
				</div>
			</div>
			<div className='card-body'>
				<div className='row'>
					<div className='col-md-2'>
						<label>Nama Produk</label>
					</div>
					<div className='col-md-4'>
						<label>{data.nama_produk}</label>
					</div>
					<div className='col-md-2'>
						<label>Harga Modal</label>
					</div>
					<div className='col-md-4'>
						<label>{'Rp. ' + data.harga_modal}</label>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-2'>
						<label>Merek</label>
					</div>
					<div className='col-md-4'>
						<label>{data.merek}</label>
					</div>
					<div className='col-md-2'>
						<label>Harga Awal</label>
					</div>
					<div className='col-md-4'>
						<label>{'Rp. ' + data.harga_awal}</label>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-2'>
						<label>Tipe</label>
					</div>
					<div className='col-md-4'>
						<label>{data.tipe_produk}</label>
					</div>
					<div className='col-md-2'>
						<label>Diskon</label>
					</div>
					<div className='col-md-4'>
						<label>{'Rp. ' + data.discount}</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-2'>
						<label>Ukuran</label>
					</div>
					<div className='col-md-4'>
						<label>{data.ukuran}</label>
					</div>
					<div className='col-md-2'>
						<label>Harga Akhir</label>
					</div>
					<div className='col-md-4'>
						<label>{'Rp. ' + data.harga_akhir}</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-2'>
						<label>Description</label>
					</div>
					<div className='demo-editor-text col-md-10'>
						<div
							dangerouslySetInnerHTML={{
								__html: data.description,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const IdentitasEdit = (props) => {
	//data utk di tampilkan
	const data = props.data;

	//index merek
	let idx_merek = 0;
	//list merek dari api
	const merek = props.merek;

	const option = () => {
		let opt = [];
		merek.forEach((element, index) => {
			let obj = {
				value: element.brand_id,
				label: element.brand_name,
			};
			opt.push(obj);

			if (data.merek === element.brand_name) {
				idx_merek = index;
			}
		});

		return opt;
	};
	const optout = option();

	const [load, setLoad] = useState(true);
	const [nama_produk, Setnamaproduk] = useState(props.data.nama_produk);
	const [harga_modal, Sethargamodal] = useState(props.data.harga_modal);
	const [harga_awal, Sethargaawal] = useState(props.data.harga_awal);
	const [discount, Setdiscount] = useState(props.data.discount);
	const [merek_produk, Setmerek] = useState(props.data.merek);
	const [ukuran, Setukuran] = useState(props.data.ukuran);
	const [tipe_produk, Settipeproduk] = useState(props.data.tipe_produk);
	const [id_merek, Setid_merek] = useState(props.data.id_merek);
	const [description, Setdescription] = useState();
	const contentBlock = htmlToDraft(props.data.description);
	if (contentBlock && load) {
		const contentState = ContentState.createFromBlockArray(
			contentBlock.contentBlocks,
		);
		const editorState = EditorState.createWithContent(contentState);
		Setdescription(editorState);
		setLoad(false);
	}

	const updateMerek = (e) => {
		Setmerek(e.label);
		Setid_merek(e.value);
	};

	const onEditorStateChange = (editorState) => {
		Setdescription(editorState);
	};

	const CloseAndConstruck = () => {
		let dataid = {
			nama_produk: nama_produk,
			merek: merek_produk,
			id_merek: id_merek,
			tipe_produk: tipe_produk,
			ukuran: ukuran,
			harga_modal: harga_modal,
			harga_awal: harga_awal,
			discount: discount,
			harga_akhir: harga_awal - discount,
			description: draftToHtml(
				convertToRaw(description.getCurrentContent()),
			),
		};
		props.update(dataid);
		props.save();
	};

	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Identitas Frame</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={CloseAndConstruck}
						>
							Selesai
						</button>
					</div>
				</div>
			</div>

			<div className='card-body matta-no-arrow'>
				<div className='row'>
					<div className='col-md-6'>
						<label for='nama_product'>Nama Frame</label>
						<input
							type='text'
							name='nama_product'
							className='form-control'
							placeholder='Masukan nama produk'
							value={nama_produk}
							onChange={(e) => Setnamaproduk(e.target.value)}
						/>
					</div>

					<div className='col-md-6'>
						<label for='harga_modal'>Harga Modal</label>
						<div className='input-group mb-3'>
							<div class='input-group-prepend'>
								<span class='input-group-text'>Rp</span>
							</div>
							<input
								type='number'
								name='harga_modal'
								className='form-control'
								min='0'
								step='1000'
								max='999999999'
								placeholder='modal ...'
								value={harga_modal}
								onChange={(e) => Sethargamodal(e.target.value)}
							/>
						</div>
					</div>
				</div>

				<br />

				<div className='row'>
					<div className='col-md-3'>
						<label for='nama_merk'>Nama merek</label>
						<Select
							components={animatedComponents}
							options={optout}
							defaultValue={optout[idx_merek]}
							name='merek'
							placeholder='Pilih merek'
							// onChange={e=>Setmerek(e.label)}
							onChange={(e) => updateMerek(e)}
						/>
					</div>

					<div className='col-md-3'>
						<label for='tipe_produk'>Tipe</label>
						<input
							type='text'
							name='tipe_produk'
							className='form-control'
							placeholder='Masukan tipe produk'
							value={tipe_produk}
							onChange={(e) => Settipeproduk(e.target.value)}
						/>
					</div>

					<div className='col-md-3'>
						<label for='harga_awal'>Harga Awal</label>
						<div className='input-group mb-3'>
							<div class='input-group-prepend'>
								<span class='input-group-text'>Rp</span>
							</div>

							<input
								type='number'
								name='harga_awal'
								className='form-control'
								min='0'
								step='1'
								max='999999999'
								placeholder='harga awal ...'
								value={harga_awal}
								onChange={(e) => Sethargaawal(e.target.value)}
							/>
						</div>
					</div>

					<div className='col-md-3'>
						<label for='diskon'>Diskon</label>
						<div className='input-group mb-3'>
							<div class='input-group-prepend'>
								<span class='input-group-text'>Rp</span>
							</div>
							<input
								type='number'
								name='diskon'
								className='form-control'
								min='0'
								step='1'
								max='999999999'
								placeholder='diskon ...'
								value={discount}
								onChange={(e) => Setdiscount(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<br />

				<div className='row'>
					<div className='col-md-6'>
						<label for='ukuran_product'>Ukuran Frame</label>
						<input
							type='text'
							name='ukuran_product'
							className='form-control'
							placeholder='Masukan ukuran produk'
							value={ukuran}
							onChange={(e) => Setukuran(e.target.value)}
						/>
					</div>
					<div className='col-md-6'>
						<label for='harga_akhir'>Harga Akhir</label>
						<br />
						<label id='harga_akhir'>
							<strong>
								<h3>{'Rp. ' + (harga_awal - discount)}</h3>
							</strong>
						</label>
					</div>
				</div>

				<br />
				<div className='row'>
					<div className='col-md-12'>
						<div className='form-group'>
							<label>Description</label>
							{description !== undefined && (
								<Editor
									editorState={description}
									wrapperClassName='demo-wrapper'
									editorClassName='demo-editor border rounded p-3'
									onEditorStateChange={onEditorStateChange}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Dokumentasi = (props) => {
	const ObjWarna = () => {
		return Object.entries(props.data).map(([key, value], i) => {
			return (
				<div key={key} className='row'>
					<div className='col-md-2'>
						<label>{value.nama_warna}</label>
					</div>
					<div className='col-md-4'>
						<label>{value.nama_foto.replace(/_/g, ' ')}</label>
					</div>
					<div className='col-md-4'>
						<label>{value.nama_3d.replace(/_/g, ' ')}</label>
					</div>
					<div className='col-md-2'>
						<label>{value.stock}</label>
					</div>
				</div>
			);
		});
	};
	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Dokumentasi Frame</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={props.edit}
						>
							Edit
						</button>
					</div>
				</div>
			</div>
			<div className='card-body'>{ObjWarna()}</div>
		</div>
	);
};

const DokumentasiEdit = (props) => {
	let data = props.data;
	let children = [];
	let varians = [];
	const [numChild, SetnumChild] = useState(data.length);

	const addChild = () => {
		SetnumChild(numChild + 1);

		const newObj = {
			id_id: numChild,
			nama_warna: '',
			nama_foto: '',
			id_foto: '',
			nama_3d: '',
			id_3d: '',
			stock: 0,
		};

		data.push(newObj);
	};

	const delChild = () => {
		SetnumChild(numChild - 1);
		varians.pop();
		data.pop();
	};

	const tampungHook = (param, key) => {
		varians[key] = param;
	};

	const CloseandSave = () => {
		props.update(varians);
		props.save();
	};

	for (var i = 0; i < numChild; i += 1) {
		children.push(
			<ChildProduct key={i} data={data[i]} callback={tampungHook} />,
		);
	}

	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Dokumentasi Frame</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={CloseandSave}
						>
							Selesai
						</button>
					</div>
				</div>
			</div>

			<div className='card-body matta-no-arrow'>
				{children}
				<div className='row'>
					{numChild >= 1 && (
						<div className='col-md-6 custom_inner_matta_2'>
							<a onClick={delChild}>
								<Trash2 />
								<p>Hapus warna</p>
							</a>
						</div>
					)}

					<div className='col-md-6 custom_inner_matta'>
						<a onClick={addChild}>
							<Plus />
							<p>Tambah warna baru</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const Informasi = (props) => {
	const data = props.data;
	let kat = data.kategori;
	let cocok = data.cocok_untuk;
	let strkat = '';
	let strcocok = '';
	const constructArray = () => {
		if (typeof kat === 'object') {
			kat.forEach((element) => {
				strkat = strkat + element.value + ',';
			});
		} else {
			strkat = kat;
		}

		if (typeof cocok === 'object') {
			cocok.forEach((element) => {
				strcocok = strcocok + element.value + ',';
			});
		} else {
			strcocok = cocok;
		}
	};
	constructArray();

	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Detail Informasi Frame</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={props.edit}
						>
							Edit
						</button>
					</div>
				</div>
			</div>
			<div className='card-body'>
				<div className='row'>
					<div className='col-md-3'>
						<label>Kategori</label>
					</div>
					<div className='col-md-3'>
						<label>{strkat}</label>
					</div>
					<div className='col-md-3'>
						<label>Cocok untuk</label>
					</div>
					<div className='col-md-3'>
						<label>{strcocok}</label>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-3'>
						<label>Originalitas</label>
					</div>
					<div className='col-md-3'>
						<label>{data.originalitas}</label>
					</div>
					<div className='col-md-3'>
						<label>Bentuk</label>
					</div>
					<div className='col-md-3'>
						<label>{data.bentuk}</label>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-3'>
						<label>Material</label>
					</div>
					<div className='col-md-3'>
						<label>{data.material}</label>
					</div>
					<div className='col-md-3'>
						<label>Berat</label>
					</div>
					<div className='col-md-3'>
						<label>{data.berat}</label>
					</div>
					<div className='col-md-3'>
						<label>Panjang</label>
					</div>
					<div className='col-md-3'>
						<label>{data.panjang || '-'}</label>
					</div>
					<div className='col-md-3'>
						<label>Lebar</label>
					</div>
					<div className='col-md-3'>
						<label>{data.lebar || '-'}</label>
					</div>
					<div className='col-md-3'>
						<label>Tinggi</label>
					</div>
					<div className='col-md-3'>
						<label>{data.tinggi || '-'}</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-3'>
						<label>Nosepad</label>
					</div>
					<div className='col-md-3'>
						<label>{data.nosepad}</label>
					</div>
					<div className='col-md-3'>
						<label>Gratis lensa blue safe</label>
					</div>
					<div className='col-md-3'>
						<label>{data.bluesafe}</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-3'>
						<label>Gratis Casing</label>
					</div>
					<div className='col-md-3'>
						<label>{data.casing}</label>
					</div>
					<div className='col-md-3'>
						<label>Gratis Pembersih Kaca</label>
					</div>
					<div className='col-md-3'>
						<label>{data.bersih}</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-3'>
						<label>Gratis lap kacamata</label>
					</div>
					<div className='col-md-3'>
						<label>{data.lap}</label>
					</div>
					<div className='col-md-3'>
						<label>Memenuhi Kriteria Lensa Progressif</label>
					</div>
					<div className='col-md-3'>
						<label>{data.lensa_prog}</label>
					</div>
				</div>
			</div>
		</div>
	);
};

const InformasiEdit = (props) => {
	const data = props.data;

	const kategori = [
		{ value: 'Fullframe', label: 'Fullframe' },
		{ value: 'Kacamata Wanita', label: 'Kacamata Wanita' },
		{ value: 'Kacamata Pria', label: 'Kacamata Pria' },
		{ value: 'Kacamata Anak', label: 'Kacamata Anak' },
		{ value: 'Kacamata Unisex', label: 'Kacamata Unisex' },
		{ value: 'Usia 10thn keatas', label: 'Usia 10thn keatas' },
		{ value: 'Usia 12thn keatas', label: 'Usia 12thn keatas' },
		{ value: 'Usia 15thn keatas', label: 'Usia 15thn keatas' },
		{ value: 'Usia 20thn keatas', label: 'Usia 20thn keatas' },
		{ value: 'Usia 10 s.d 30 thn', label: 'Usia 10 s.d 30 thn' },
		{ value: 'Usia 20 s.d 45 thn', label: 'Usia 20 s.d 45 thn' },
		{ value: 'Usia 20 s.d 40 thn', label: 'Usia 20 s.d 40 thn' },
		{ value: 'Usia 35thn keatas', label: 'Usia 35thn keatas' },
	];
	const cocok_untuk = [
		{ value: 'Casual', label: 'Casual' },
		{ value: 'Fasion', label: 'Fasion' },
		{ value: 'Work', label: 'Work' },
		{ value: 'Formal', label: 'Formal' },
		{ value: 'Sport', label: 'Sport' },
	];

	const originalitas = [
		{ value: 'Housebrand Ori', label: 'Housebrand Ori' },
		{ value: 'Ori Branded', label: 'Ori Branded' },
	];

	const bentuk = [
		{ value: 'Setengah bulat', label: 'Setengah bulat' },
		{ value: 'Bulat', label: 'Bulat' },
		{ value: 'Oval', label: 'Oval' },
		{ value: 'Kotak Besar', label: 'Kotak Besar' },
		{ value: 'Kotak', label: 'Kotak' },
		{ value: 'Polygon', label: 'Polygon' },
		{ value: 'Sporty', label: 'Sporty' },
	];

	const material = [
		{ value: 'Plastik dan Metal', label: 'Plastik dan Metal' },
		{ value: 'Bahan TR dan Plastik', label: 'Bahan TR dan Plastik' },
	];

	const berat = [
		{ value: '1 Kg', label: '1 Kg' },
		{ value: '2 Kg', label: '2 Kg' },
		{ value: '3 Kg', label: '3 Kg' },
	];

	let idx_kategori = [];
	let idx_cocok = [];
	let idx_ori = 0;
	let idx_bentuk = 0;
	let idx_material = 0;
	let idx_berat = 0;

	let PropKat = data.kategori;
	let PropCocok = data.cocok_untuk;

	const option_idxAll = () => {
		kategori.forEach((element, index) => {
			if (typeof PropKat === 'object') {
				PropKat.forEach((ObjKat) => {
					if (ObjKat.label === element.label) {
						idx_kategori.push(kategori[index]);
					}
				});
			} else {
				if (PropKat === element.label) {
					idx_kategori.push(kategori[index]);
				}
			}
		});

		cocok_untuk.forEach((element, index) => {
			if (typeof PropCocok === 'object') {
				PropCocok.forEach((ObjCocok) => {
					if (ObjCocok.label === element.label) {
						idx_cocok.push(cocok_untuk[index]);
					}
				});
			} else {
				if (PropCocok === element.label) {
					idx_cocok.push(cocok_untuk[index]);
				}
			}
		});
		originalitas.forEach((element, index) => {
			if (data.originalitas === element.label) {
				idx_ori = index;
			}
		});
		bentuk.forEach((element, index) => {
			if (data.bentuk === element.label) {
				idx_bentuk = index;
			}
		});
		material.forEach((element, index) => {
			if (data.material === element.label) {
				idx_material = index;
			}
		});
		berat.forEach((element, index) => {
			if (data.berat === element.label) {
				idx_berat = index;
			}
		});
	};

	option_idxAll();

	const [kategoriVal, Setkategori] = useState(props.data.kategori);
	const [cocokVal, Setcocok] = useState(props.data.cocok_untuk);
	const [oriVal, Setori] = useState(props.data.originalitas);
	const [bentukVal, Setbentuk] = useState(props.data.bentuk);
	const [materiVal, Setmaterial] = useState(props.data.material);
	const [beratVal, Setberat] = useState(props.data.berat);
	const [panjangVal, Setpanjang] = useState(props.data.panjang);
	const [lebarVal, Setlebar] = useState(props.data.lebar);
	const [tinggiVal, Settinggi] = useState(props.data.tinggi);
	const [nosepadVal, Setnosepad] = useState(props.data.nosepad);
	const [bluesafeVal, Setbluesafe] = useState(props.data.bluesafe);
	const [casingVal, Setcasing] = useState(props.data.casing);
	const [bersihVal, Setbersih] = useState(props.data.bersih);
	const [lapVal, Setlap] = useState(props.data.lap);
	const [lensaVal, Setlensa] = useState(props.data.lensa_prog);

	const CloseandConstruct = () => {
		let DataInfo = {
			kategori: kategoriVal,
			cocok_untuk: cocokVal,
			originalitas: oriVal,
			bentuk: bentukVal,
			material: materiVal,
			berat: beratVal,
			nosepad: nosepadVal,
			bluesafe: bluesafeVal,
			casing: casingVal,
			bersih: bersihVal,
			lap: lapVal,
			lensa_prog: lensaVal,
		};
		props.update(DataInfo);
		props.save();
	};

	return (
		<div className='card'>
			<div className='card-header'>
				<div className='d-flex bd-highlight mb-3'>
					<div className='p-2 bd-highlight'>
						<h5>Detail Informasi Produk</h5>
					</div>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-outline-info'
							onClick={CloseandConstruct}
						>
							selesai
						</button>
					</div>
				</div>
			</div>

			<div className='card-body'>
				<div className='row'>
					<div className='col-md-6'>
						<label>Kategori</label>
						<Select
							components={animatedComponents}
							isMulti
							options={kategori}
							onChange={(e) => Setkategori(e)}
							name='kategori'
							placeholder='Pilih disini'
							defaultValue={idx_kategori}
						/>
					</div>
					<div className='col-md-6'>
						<label>Cocok untuk</label>
						<Select
							components={animatedComponents}
							isMulti
							options={cocok_untuk}
							onChange={(e) => Setcocok(e)}
							name='cocok_untuk'
							placeholder='Pilih disini'
							defaultValue={idx_cocok}
						/>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<label>Originalitas</label>
						<Select
							components={animatedComponents}
							options={originalitas}
							onChange={(e) => Setori(e.value)}
							name='originalitas'
							placeholder='Pilih disini'
							defaultValue={originalitas[idx_ori]}
						/>
					</div>
					<div className='col-md-6'>
						<label>Bentuk</label>
						<Select
							components={animatedComponents}
							options={bentuk}
							onChange={(e) => Setbentuk(e.value)}
							name='bentuk'
							placeholder='Pilih disini'
							defaultValue={bentuk[idx_bentuk]}
						/>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<label>Material</label>
						<Select
							components={animatedComponents}
							options={material}
							onChange={(e) => Setmaterial(e.value)}
							name='material'
							placeholder='Pilih disini'
							defaultValue={material[idx_material]}
						/>
					</div>
					<div className='col-md-6 matta-no-arrow'>
						<label>Berat</label>
						<input
							type='number'
							name='berat'
							className='form-control'
							min='0'
							step='10'
							max='999999999'
							placeholder='berat'
							value={beratVal}
							onChange={(e) => Setberat(e.target.value)}
						/>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6 matta-no-arrow'>
						<label>Panjang</label>
						<input
							type='number'
							name='panjang'
							className='form-control'
							min='0'
							step='10'
							max='999999999'
							placeholder='panjang'
							value={panjangVal}
							onChange={(e) => Setpanjang(e.target.value)}
						/>
					</div>
					<div className='col-md-6 matta-no-arrow'>
						<label>Lebar</label>
						<input
							type='number'
							name='lebar'
							className='form-control'
							min='0'
							step='10'
							max='999999999'
							placeholder='lebar'
							value={lebarVal}
							onChange={(e) => Setlebar(e.target.value)}
						/>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6 matta-no-arrow'>
						<label>Tinggi</label>
						<input
							type='number'
							name='tinggi'
							className='form-control'
							min='0'
							step='10'
							max='999999999'
							placeholder='tinggi'
							value={tinggiVal}
							onChange={(e) => Settinggi(e.target.value)}
						/>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<label>Nosepad</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='nosepad'
								value='Ya'
								checked={nosepadVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setnosepad('Ya')}
							/>
							<label className='form-check-label' for='nosepad1'>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='nosepad'
								value='Tidak'
								checked={nosepadVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setnosepad('Tidak')}
							/>
							<label class='form-check-label' for='nosepad2'>
								Tidak
							</label>
						</div>
					</div>

					<div className='col-md-6'>
						<label>Gratis lensa blue safe</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='bluesafe'
								value='Ya'
								checked={bluesafeVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setbluesafe('Ya')}
							/>
							<label
								className='form-check-label'
								for='inlineRadio1'
							>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='bluesafe'
								value='Tidak'
								checked={bluesafeVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setbluesafe('Tidak')}
							/>
							<label class='form-check-label' for='inlineRadio2'>
								Tidak
							</label>
						</div>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<label>Gratis Casing</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='casing'
								value='Ya'
								checked={casingVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setcasing('Ya')}
							/>
							<label className='form-check-label' for='nosepad1'>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='casing'
								value='Tidak'
								checked={casingVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setcasing('Tidak')}
							/>
							<label class='form-check-label' for='nosepad2'>
								Tidak
							</label>
						</div>
					</div>

					<div className='col-md-6'>
						<label>Gratis pembersih lensa</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='bersih'
								value='Ya'
								checked={bersihVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setbersih('Ya')}
							/>
							<label
								className='form-check-label'
								for='inlineRadio1'
							>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='bersih'
								value='Tidak'
								checked={bersihVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setbersih('Tidak')}
							/>
							<label class='form-check-label' for='inlineRadio2'>
								Tidak
							</label>
						</div>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<label>Gratis lap kacamata</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='lap'
								value='Ya'
								checked={lapVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setlap('Ya')}
							/>
							<label className='form-check-label' for='nosepad1'>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='lap'
								value='Tidak'
								checked={lapVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setlap('Tidak')}
							/>
							<label class='form-check-label' for='nosepad2'>
								Tidak
							</label>
						</div>
					</div>

					<div className='col-md-6'>
						<label>Memenuhi kriteria lensa progressif</label>
						<br />
						<div className='form-check form-check-inline'>
							<input
								className='form-check-input'
								type='radio'
								name='lensa_prog'
								value='Ya'
								checked={lensaVal === 'Ya' ? 'checked' : ''}
								onChange={() => Setlensa('Ya')}
							/>
							<label
								className='form-check-label'
								for='inlineRadio1'
							>
								Ya
							</label>
						</div>
						<div class='form-check form-check-inline'>
							<input
								class='form-check-input'
								type='radio'
								name='lensa_prog'
								value='Tidak'
								checked={lensaVal === 'Ya' ? '' : 'checked'}
								onChange={() => Setlensa('Tidak')}
							/>
							<label class='form-check-label' for='inlineRadio2'>
								Tidak
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Fixed_bar = (props) => {
	return (
		<div>
			{props.render ? (
				<nav className='navbar fixed-bottom navbar-light bg-light d-flex'>
					<div className='ml-auto p-2 bd-highlight'>
						<button
							type='button'
							className='btn btn-info'
							onClick={props.updateProduct}
						>
							Simpan perubahan
						</button>
					</div>
				</nav>
			) : null}
		</div>
	);
};

const Allin = (props) => {
	const [showExit, SetshowExit] = useState(false);
	const ref = useRef({
		is_changed: false,
	});
	const [is_change, Setchange] = useState(false);
	const [editId, SeteditId] = useState(false);
	const [editWarna, SeteditWarna] = useState(false);
	const [editInfo, SeteditInfo] = useState(false);
	const [init_IdProduct, Setinit_IdProduct] = useState(props.data_id);
	const [init_InfoProduct, Setinit_InfoProduct] = useState(props.data_info);
	const [init_DocProduct, Setinit_DocProduct] = useState(props.data_warna);
	const [message, Setmessage] = useState('');
	const [typemessage, Settype] = useState('');
	const [showmodal, Setshowmodal] = useState(false);

	const editIdentitas = () => {
		SeteditId(true);
	};
	const saveIdentitas = () => {
		SeteditId(false);
	};
	const editDokumen = () => {
		SeteditWarna(true);
	};
	const saveDokumen = () => {
		SeteditWarna(false);
	};
	const editInformasi = () => {
		SeteditInfo(true);
	};
	const saveInformasi = () => {
		SeteditInfo(false);
	};

	//bagian update
	const updateIdProduct = (newidData) => {
		Setinit_IdProduct(newidData);
	};
	const updateInfoProduct = (newInfoData) => {
		Setinit_InfoProduct(newInfoData);
	};
	const updateDocProduct = (newDocData) => {
		Setinit_DocProduct(newDocData);
	};

	//default value
	const [ori_IdProduct, Setori_IdProduct] = useState(props.data_id);
	const [ori_InfoProduct, Setori_InfoProduct] = useState(props.data_info);
	const [ori_DocProduct, Setori_DocProduct] = useState(props.data_warna);

	//bagian untuk update langsung ke API
	const updateProduct = () => {
		let init_product = {
			nama_produk: init_IdProduct.nama_produk,
			id_merek: init_IdProduct.id_merek,
			tipe_produk: parseInt(init_IdProduct.tipe_produk),
			ukuran: init_IdProduct.ukuran,
			harga_modal: init_IdProduct.harga_modal,
			harga_awal: init_IdProduct.harga_awal,
			harga_akhir: init_IdProduct.harga_akhir,
			discount: init_IdProduct.discount,
		};
		AuthService.EditProduct(
			props.kacamata,
			init_product,
			init_DocProduct,
			init_InfoProduct,
			init_IdProduct.description,
		).then((data) => {
			if (data.results) {
				Setmessage(data.results);
				Settype('alert-success');
				Setshowmodal(true);
			} else {
				Setmessage(data.error.message);
				Settype('alert-danger');
				Setshowmodal(true);
			}
		});
	};

	//redir ke list product
	const redirProduct = () => {
		props.redir();
	};

	useEffect(() => {
		let id_value = Object.values(init_IdProduct);
		let id_value_ori = Object.values(ori_IdProduct);
		let info_value = Object.values(init_InfoProduct);
		let info_value_ori = Object.values(ori_InfoProduct);
		let doc_value = Object.values(init_DocProduct);
		let doc_value_ori = Object.values(ori_DocProduct);

		id_value.forEach((val, idx) => {
			if (val !== id_value_ori[idx]) {
				ref.current.is_changed = true;

				if (ref.current.is_changed !== is_change) {
					Setchange(ref.current.is_changed);
				}
			}
		});

		info_value.forEach((val, idx) => {
			if (val !== info_value_ori[idx]) {
				ref.current.is_changed = true;

				if (ref.current.is_changed !== is_change) {
					Setchange(ref.current.is_changed);
				}
			}
		});

		doc_value.forEach((val, idx) => {
			if (val !== doc_value_ori[idx]) {
				ref.current.is_changed = true;

				if (ref.current.is_changed !== is_change) {
					Setchange(ref.current.is_changed);
				}
			}
		});
	});

	useEffect(() => {
		return () => {
			if (ref.current.is_changed === true) {
				console.log('triggered');
				SetshowExit(true);
			}
		};
	}, []);

	return (
		<Fragment>
			<Breadcrumb title='Detail Produk' parent='Produk / Frame' />
			<div className='container-fluid'>
				{editId ? (
					<IdentitasEdit
						save={saveIdentitas}
						data={init_IdProduct}
						merek={props.merek}
						update={updateIdProduct}
					/>
				) : (
					<Identitas edit={editIdentitas} data={init_IdProduct} />
				)}

				{editWarna ? (
					<DokumentasiEdit
						save={saveDokumen}
						data={init_DocProduct}
						update={updateDocProduct}
					/>
				) : (
					<Dokumentasi edit={editDokumen} data={init_DocProduct} />
				)}

				{editInfo ? (
					<InformasiEdit
						save={saveInformasi}
						data={init_InfoProduct}
						update={updateInfoProduct}
					/>
				) : (
					<Informasi edit={editInformasi} data={init_InfoProduct} />
				)}

				<Fixed_bar
					render={ref.current.is_changed}
					updateProduct={updateProduct}
				/>
			</div>
			<SuccessPOP
				show={showmodal}
				close={redirProduct}
				message={message}
			/>

			<ExitNoSave
				show={showExit}
				close={() => SetshowExit(false)}
				savechange={updateProduct}
			/>
		</Fragment>
	);
};

export default class DetailProduct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editId: false,
			editWarna: false,
			editInfo: false,
			merek: [],
			message: '',
		};
	}
	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		AuthService.pageNewProduct().then((data) => {
			if (data.results) {
				this.setState({ merek: data.results });
			} else {
				this.setState({ message: data.error.message });
			}
		});
	};

	redirToListProd = () => {
		this.props.history.push(`${process.env.PUBLIC_URL}/products`);
	};

	render() {
		// const data = cookie.load("product_detail");
		const data = JSON.parse(localStorage.getItem('product_detail'));
		const kacamata_id = data.kacamata_id;
		const init_IdProduct = {
			nama_produk: data.name,
			merek: data.brand_name,
			id_merek: data.brand_id,
			tipe_produk: data.product_type,
			ukuran: data.size_product,
			harga_modal: data.price_kapital,
			harga_awal: data.price_starter,
			discount: data.discount,
			harga_akhir: data.price_final,
			description: data.description,
		};
		const init_DocProduct = data.varians;
		const init_InfoProduct = data.specs;

		return (
			<Allin
				data_id={init_IdProduct}
				data_warna={init_DocProduct}
				data_info={init_InfoProduct}
				merek={this.state.merek}
				kacamata={kacamata_id}
				redir={this.redirToListProd}
			/>
		);
	}
}
