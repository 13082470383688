import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';

export default class SuccessPromo extends Component {
    
  render() {
    return (
      <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h4>{this.props.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={this.props.close}>
                OKE
            </button>
          </Modal.Footer>
        </Modal>
    )
  }
}
