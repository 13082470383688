import {
    Home,
    Box,
    Users,
    Truck,
    Image,
    Settings,
    Award,
    Tag,
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        badgeType: 'primary',
        active: false,
    },
    {
        title: 'Pengguna',
        icon: Users,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/users/list-user',
                title: 'Pengguna Aplikasi',
                type: 'link',
            },
            {path: '/users/admins', title: 'Admin', type: 'link'},
        ],
    },
    {
        title: 'Produk',
        icon: Box,
        type: 'sub',
        active: false,
        children: [
            {path: '/products', title: 'Frame', type: 'link'},
            {path: '/lens', title: 'Lensa', type: 'link'},
            {path: '/frame-brands', title: 'Merek Frame', type: 'link'},
            {path: '/lens-brands', title: 'Merek Lensa', type: 'link'},
        ],
    },
    {
        path: '/media',
        title: 'Media',
        icon: Image,
        type: 'link',
        active: false,
        badgeType: 'primary',
    },
    {
        title: 'Transaksi',
        icon: Truck,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/list-transaction',
                title: 'Semua transaksi',
                type: 'link',
            },
            {path: '/shipping', title: 'Pengiriman', type: 'link'},
            {path: '/payment', title: 'Pembayaran', type: 'link'},
            // { path: '/promotion', title: 'Promosi', type: 'link' },
        ],
    },
    {
        title: 'Cust. Loyalty Program',
        icon: Award,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/all-loyalty',
                title: 'Semua Loyalty Level',
                type: 'link',
            },
            {
                path: '/reward-loyalty',
                title: 'Rewards',
                type: 'link',
            },
        ],
    },
    {
        title: 'Campaign management',
        icon: Tag,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/all-campaign',
                title: 'Semua campaign',
                type: 'link',
            },
            {
                path: '/add-campaign',
                title: 'Buat campaign baru',
                type: 'link',
            },
            {
                path: '/notification',
                title: 'Notifikasi',
                type: 'link',
            },
        ],
    },
    {
        title: 'Pengaturan',
        icon: Settings,
        type: 'sub',
        active: false,
        children: [
            {path: '/constant-matta', title: 'Konstanta', type: 'link'},
        ],
    },
];
