import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class PopAdmin extends Component {
	quit = () => {
		this.props.close();
		if (this.props.handleQuit != undefined) {
			this.props.handleQuit()
		} else {
			this.props.history.push(`${process.env.PUBLIC_URL}/all-loyalty`);
		}
		
	};

	render() {
		let message = this.props.message;
		let message_type = this.props.message_type;
		return (
			<Modal
				show={this.props.show}
				onHide={this.quit}
				backdrop='static'
				keyboard={false}
				centered
				size='md'
			>
				<Modal.Header closeButton>
					<Modal.Title>Info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={'alert' + message_type} role='alert'>
						{message}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button
						className='btn btn-outline-success'
						onClick={this.quit}
					>
						OKE
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default withRouter(PopAdmin);
