import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { Redirect } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cookie from 'react-cookies';
import AuthService from '../api_getter/auth_service';


export default class NewPassword extends Component {
    constructor(props){
        super(props);

        this.state={
            password1:'',
            password2:'',
            message:'',
            message_type:''
        }
    }
    
    Back_to_login = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`);
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        });
    }
    SetNewPassword = (event) =>{
        event.preventDefault();
        AuthService.setnewpassword(this.state.password1)
        .then( (data) => {
            
            if (data.results){
                cookie.save('forget_data', {}, { path: '/' });
                this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`);
            }
            else {
                this.setState({message:data.error.message});
            };
        });
    }
    
    render() {
        let login_status = cookie.load('logged_in')
        if (login_status !== undefined){
            if (login_status.status===true){
                return <Redirect to="/dashboard"/>
            }
        }
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-7 p-0 mx-auto">
                                    <div className="card tab2-card">
                                        <div className="shadow-lg rounded">
                                        <div className="card-body">
                                        
                                    <form className="form-horizontal auth-form" onSubmit={this.SetNewPassword}>
                                        
                                        <div className="form-group text-center">
                                            <h5>Password Baru</h5><br/>
                                        </div>

                                        {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                            </div>
                                        </div>
                                        )}

                                        <div className="form-group">
                                            
                                            <input required="" name="password1" type="password" className="form-control" placeholder="masukkan password baru" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                        
                                            <input required="" name="password2" type="password" className="form-control" placeholder="ulangi password baru" onChange={this.handleChange}/>
                                        </div>

                                        <div className="form-button">
                                            <button className="btn btn-primary" type="submit" 
                                            disabled={(this.state.password1!==this.state.password2 || this.state.password1==='')?'disabled':''}>Simpan</button>
                                        </div>
                              
                                    </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

