import axios from 'axios';
import cookie from 'react-cookies';

class AuthService {
    admin_login(user_data, password) {
        return axios
            .post(process.env.REACT_APP_API_URL + '/admin/auth/login', {
                user_data,
                password,
            })
            .then((response) => {
                if (response.data.results) {
                    cookie.save('user_data', JSON.stringify(response.data), {
                        path: '/',
                    });
                    cookie.save('logged_in', JSON.stringify({status: true}), {
                        path: '/',
                    });
                } else {
                    cookie.save('user_data', {}, {path: '/'});
                    cookie.save('logged_in', JSON.stringify({status: false}), {
                        path: '/',
                    });
                }
                return response.data;
            });
    }

    forget_password(email) {
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/forgot_password',
            data: {
                email: email,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    newOtpForget() {
        let data_ = cookie.load('forget_data');

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/new_otp',
            data: {
                email: data_.email,
                admin_name: data_.user_name,
                token: data_.forgot_verifier,
                user_lang: 'id_id',
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    verify_forget(code) {
        let data_ = cookie.load('forget_data');

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/verify_password',
            data: {
                email: data_.email,
                forgot_verifier: data_.forgot_verifier,
                code: code,
                user_lang: 'id_id',
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    setnewpassword(password) {
        let data_ = cookie.load('forget_data');
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/set_new_password',
            data: {
                email: data_.email,
                forgot_verifier: data_.forgot_verifier,
                new_password: password,
                user_lang: 'id_id',
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    login(user_data, password) {
        return axios
            .post(process.env.REACT_APP_API_URL + '/account/login', {
                user_data,
                password,
            })
            .then((response) => {
                if (response.data.results) {
                    cookie.save('user_data', JSON.stringify(response.data), {
                        path: '/',
                    });
                    cookie.save('logged_in', JSON.stringify({status: true}), {
                        path: '/',
                    });
                }
                return response.data;
            });
    }

    logout() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        console.log(token);
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/logout',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    force_login(user, password) {
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/force_login',
            data: {
                user_data: user,
                password: password,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    newOtpForce() {
        let data_ = cookie.load('data_force');

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/new_otp',
            data: {
                email: data_.results.email,
                admin_name: data_.results.admin_name,
                token: data_.meta.token,
                user_lang: 'id_id',
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    verify_force(code) {
        let data_ = cookie.load('data_force');

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/verify_force_login',
            data: {
                email: data_.results.email,
                admin_name: data_.results.admin_name,
                token: data_.meta.token,
                code: code,
                user_lang: 'id_id',
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    addAdmin(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/auth/register',
            headers: {
                token: token,
            },
            data: data,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    editAdmin(admin_id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/change_status',
            headers: {
                token: token,
            },
            data: {
                admin_id: admin_id,
                status: status,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteAdmin(admin_id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_admin',
            headers: {
                token: token,
            },
            data: {
                admin_id: admin_id,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    changeRoleAdmin(admin_id, role) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/change_role',
            headers: {
                token: token,
            },
            data: {
                admin_id: admin_id,
                role: role,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    userManData() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/users',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    userEdit(user_id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/update_user',
            headers: {
                token: token,
            },
            data: {
                user_id: user_id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    userDelete(user_id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_user',
            headers: {
                token: token,
            },
            data: {
                user_id: user_id,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getListAdmin() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/admins',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    Orders() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/orders',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    Products(status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/products',
            headers: {
                token: token,
            },
            data: {
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    UpdateProduct(status, product_id, reason, file) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let actor = user.results.user_id;
        let attch = file ? 'true' : '';
        let files = attch ? file : null;

        var formData = new FormData();
        formData.append('actor_id', actor);
        formData.append('product_id', product_id);
        formData.append('status', status);
        formData.append('reason', reason);
        formData.append('attachment', attch);
        formData.append('3d_obj', files);

        let url = process.env.REACT_APP_API_URL + '/admin/products/update';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                console.log(response);

                return response.data;
            });
    }

    dashboard() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        //let actor = user.results.user_id
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/data_dashboard',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    popular() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        //let actor = user.results.user_id
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS + '/payment/api/v1/product/best-seller',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    Upload3D(file) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let files = file;
        //console.log(file)
        var formData = new FormData();
        formData.append('3d_obj', files);

        let url = process.env.REACT_APP_API_URL + '/admin/upload';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                //console.log(response)

                return response.data;
            });
    }

    mediaList() {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/list_3d',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    DeleteMedia(media_id, media_type) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_media',
            headers: {
                token: token,
            },
            data: {
                media_id: media_id,
                media_type: media_type,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    photoList() {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/list_photo',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    userTestMata(userid) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URL + '/admin/users-test?user_id=' + userid,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    pageNewProduct() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/add_new_product',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    sendNewProduct(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let nama_product = data.nama_product;
        let harga_modal = data.harga_modal;
        let nama_merk = data.nama_merk;
        let tipe_produk = data.tipe_produk;
        let harga_awal = data.harga_awal;
        let diskon = data.diskon;
        let harga_akhir = data.harga_akhir;
        let ukuran_product = data.ukuran_product;
        let varian = data.varian;
        let spec = data.spec;
        let description = data.description;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/save_new_product',
            headers: {
                token: token,
            },
            data: {
                name: nama_product,
                price_kapital: harga_modal,
                brand_id: nama_merk,
                product_type: tipe_produk,
                price_starter: harga_awal,
                discount: diskon,
                price_final: harga_akhir,
                size_product: ukuran_product,
                varian: varian,
                spec: spec,
                description: description,
            },
        };

        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllProduct() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/all_product',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getTransaction(sub) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/all_trx',
            params: {sub},
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    setStatus(invoice, status) {
        console.log(invoice, status);
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URLS + '/payment/api/v1/shipping',
            headers: {
                token: token,
            },
            data: {
                invoice: invoice,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteTransaction(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'delete',
            url: process.env.REACT_APP_API_URLS + `/payment/api/v1/dashboard/order/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateTransaction(order_id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/action_trx',
            headers: {
                token: token,
            },
            data: {
                order_id: order_id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getShipping() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/shipping',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateShiper(shiper, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/change_shiper',
            headers: {
                token: token,
            },
            data: {
                shiper: shiper,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getPayment() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/payment',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updatePayment(payment, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/change_payment',
            headers: {
                token: token,
            },
            data: {
                payment: payment,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    addPromo(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let admin_id = user.results.admin_id;

        let kacamata_id = data.kacamata_id;
        let lens_id = data.lens_id;
        let discount = data.discount;
        let file = data.file;
        let description = data.description;
        let file_attach = data.file === null ? '' : 'Yes';
        let url = process.env.REACT_APP_API_URL + '/admin/add_promo';
        var formPromo = new FormData();
        formPromo.append('kacamata_id', kacamata_id);
        formPromo.append('lens_id', lens_id);
        formPromo.append('discount', discount);
        formPromo.append('admin_id', admin_id);
        formPromo.append('promo_img', file);
        formPromo.append('file_attach', file_attach);
        formPromo.append('description', description);

        return axios
            .post(url, formPromo, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    getPromo() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/promo',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    editPromo(id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/edit_promo',
            headers: {
                token: token,
            },
            data: {
                promo_id: id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deletePromo(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_promo',
            headers: {
                token: token,
            },
            data: {
                promo_id: id,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getTranship(sub) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/shipping_list?sub=' + sub,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateResi(id, noresi) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URLS + '/payment/api/v1/shipping-resi',
            headers: {
                token: token,
            },
            data: {
                id_shipping: id,
                receipt: noresi,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getTracker(shipid) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URL +
                '/admin/detail_shipping?shipid=' +
                shipid,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getTrackNew(data) {
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URLS + '/payment/api/v1/tracking-awb',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };
        return axios(config).then((response) => {
            return response;
        });
    }

    EditProduct(kaca_id, identitas, dokumentasi, infoproduk, description) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/update_product',
            headers: {
                token: token,
            },
            data: {
                kacamata_id: kaca_id,
                identitas: identitas,
                dokumentasi: dokumentasi,
                infoproduk: infoproduk,
                user_lang: user_lang,
                description: description,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    EditProductStatus(kaca_id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/edit_status_product',
            headers: {
                token: token,
            },
            data: {
                kacamata_id: kaca_id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    DeleteProduk(kaca_id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_product',
            headers: {
                token: token,
            },
            data: {
                kacamata_id: kaca_id,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    AddnewLensa(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        var formData = new FormData();
        formData.append('id_brand', data.id_brand);
        formData.append('lens_material', data.lens_material);
        formData.append('lens_name', data.lens_name);
        formData.append('lens_type', data.lens_type);
        formData.append('minus_min', data.minus_min);
        formData.append('minus_max', data.minus_max);
        formData.append('plus_min', data.plus_min);
        formData.append('plus_max', data.plus_max);
        formData.append('astigmatism_min', data.astigmatism_min);
        formData.append('astigmatism_max', data.astigmatism_max);
        formData.append('addition_min', data.addition_min);
        formData.append('addition_max', data.addition_max);
        formData.append('axis_cyl_min', data.axis_cyl_min);
        formData.append('axis_cyl_max', data.axis_cyl_max);
        formData.append('description', data.description);
        formData.append('price_capital', data.price_capital);
        formData.append('price', data.price);
        formData.append('discount', data.discount);
        formData.append('price_sale', data.price_sale);
        formData.append('lens_color', data.lens_color);
        formData.append('stock', data.stock);
        formData.append('guarantee', data.guarantee);
        formData.append('foto', data.foto);

        let url = process.env.REACT_APP_API_URL + '/admin/add_new_lens';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    getAllLens() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/all_lens',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    editLensa(data) {
        console.log(data);
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let foto_attach = data.files === null ? 'No' : 'Yes';

        var formData = new FormData();
        formData.append('lens_id', data.lens_id);
        formData.append('id_brand', data.id_brand);
        formData.append('lens_name', data.lens_name);
        formData.append('lens_type', data.lens_type);
        formData.append('minus_min', data.minus_min);
        formData.append('minus_max', data.minus_max);
        formData.append('plus_min', data.plus_min);
        formData.append('plus_max', data.plus_max);
        formData.append('silinder_min', data.silinder_min);
        formData.append('silinder_max', data.silinder_max);
        formData.append('addition_min', data.addition_min);
        formData.append('addition_max', data.addition_max);
        formData.append('axis_cyl_min', data.axis_cyl_min);
        formData.append('axis_cyl_max', data.axis_cyl_max);
        formData.append('description', data.description);
        formData.append('price_capital', data.price_capital);
        formData.append('price', data.price);
        formData.append('discount', data.discount);
        formData.append('price_sale', data.price_sale);
        formData.append('lens_color', data.lens_color);
        formData.append('stock', data.stock);
        formData.append('guarantee', data.guarantee);
        formData.append('lens_material', data.lens_material);
        formData.append('foto', data.files);
        formData.append('foto_attach', foto_attach);

        let url = process.env.REACT_APP_API_URL + '/admin/edit_lens';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    editLensaStatus(id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/edit_status_lens',
            headers: {
                token: token,
            },
            data: {
                lens_id: id,
                status: status,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    DeleteLensa(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let user_lang = user.results.user_lang;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_lens',
            headers: {
                token: token,
            },
            data: {
                lens_id: id,
                user_lang: user_lang,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllBrand() {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/all_frame_brand',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    addNewBrand(file, brand) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        var formData = new FormData();
        formData.append('brand_name', brand);
        formData.append('logo', file);

        let url = process.env.REACT_APP_API_URL + '/admin/add_frame_brand';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    updateBrand(id, file, brand) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let logo_attach = file === null ? 'No' : 'Yes';

        var formData = new FormData();
        formData.append('brand_id', id);
        formData.append('brand_name', brand);
        formData.append('logo_attach', logo_attach);
        formData.append('logo', file);

        let url = process.env.REACT_APP_API_URL + '/admin/update_frame_brand';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    updStatusBrand(id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/update_status_frame_brand',
            headers: {
                token: token,
            },
            data: {
                brand_id: id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteBrand(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_frame_brand',
            headers: {
                token: token,
            },
            data: {
                brand_id: id,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllBrandLens() {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/all_lens_brand',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    addNewBrandLens(file, brand) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        var formData = new FormData();
        formData.append('brand_name', brand);
        formData.append('logo', file);

        let url = process.env.REACT_APP_API_URL + '/admin/add_lens_brand';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    updateBrandLens(id, file, brand) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let logo_attach = file === null ? 'No' : 'Yes';

        var formData = new FormData();
        formData.append('id_brand_lens', id);
        formData.append('nama_brand', brand);
        formData.append('logo_attach', logo_attach);
        formData.append('logo', file);

        let url = process.env.REACT_APP_API_URL + '/admin/update_lens_brand';

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    updStatusBrandLens(id, status) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/update_status_lens_brand',
            headers: {
                token: token,
            },
            data: {
                id_brand_lens: id,
                status: status,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteBrandLens(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/delete_lens_brand',
            headers: {
                token: token,
            },
            data: {
                id_brand_lens: id,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getConstant() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/admin/constant',
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    editConstant(myoa, myob, hypa, hypb) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/admin/edit_constant',
            headers: {
                token: token,
            },
            data: {
                myoa: parseFloat(myoa),
                myob: parseFloat(myob),
                hypa: parseFloat(hypa),
                hypb: parseFloat(hypb),
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getLabel(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;

        var formData = new FormData();
        formData.append('invoice_number', data);

        let url =
            process.env.REACT_APP_API_URLS_SHIPPING + `/api/v1/shipping/label`;

        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: token,
                },
            })
            .then((response) => {
                localStorage.setItem(
                    'detail_label',
                    JSON.stringify(response.data.data[0])
                );
                return response.data;
            });
    }

    createDataMaster(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/product/meta`,
            data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllLoyalty(data = {}) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/loyalty`,
            params: data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    createLoyalty(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URLS + `/payment/api/v1/dashboard/loyalty`,
            data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateLoyalty(id, data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/loyalty/${id}`,
            data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteLoyalty(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'delete',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/loyalty/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllReward(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/reward`,
            params: data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    detailReward(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/reward/${data}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    createReward(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URLS + `/payment/api/v1/dashboard/reward`,
            data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateReward(id, data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/reward/${id}`,
            data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteReward(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'delete',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/reward/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllCampaign(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign`,
            params: data,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    uploadImageCampaign(file) {
        const data = new FormData();
        data.append('image', file, file.name);
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/upload/create`,
            headers: {
                token: token,
            },
            data,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    addCampaign(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS + `/payment/api/v1/dashboard/campaign`,
            headers: {
                token: token,
            },
            data,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    updateCampaign(id, data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/${id}`,
            headers: {
                token: token,
            },
            data,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteCampaign(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'delete',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    detailCampaign(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getCampaignProduct() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/product/list`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    generateCampaignCode() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/campaign/code/generate`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getAllNotification(data) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification`,
            params: data ?? {},
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    generateNotificationCode() {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification/code/generate`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    AddNotification(payload) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification`,
            headers: {
                token: token,
            },
            data: payload,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    EditNotification(id, payload) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification/${id}`,
            headers: {
                token: token,
            },
            data: payload,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    DetailNotification(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    AddImageNotification(file) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        const formData = new FormData();
        formData.append('image', file, file.name);
        let config = {
            method: 'post',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification/upload/create`,
            headers: {
                token: token,
            },
            data: formData,
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    deleteNotification(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'delete',
            url:
                process.env.REACT_APP_API_URLS +
                `/payment/api/v1/dashboard/notification/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }

    getDetailTrx(id) {
        let user = cookie.load('user_data');
        let token = user.meta.token;
        let config = {
            method: 'get',
            url:
                process.env.REACT_APP_API_URLS + `/payment/api/v1/transaction/${id}`,
            headers: {
                token: token,
            },
        };
        return axios(config).then((response) => {
            return response.data;
        });
    }
}

export default new AuthService();
