import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../api_getter/auth_service';

export default class ForceLogin extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
            success:false
        }
    }

    quit = () =>{
        this.setState({
            message:'',
            message_type:'',
            success:false
        })
        this.props.close()
    }
    send = () =>{
        this.props.SendForce();
        this.props.close();
    }

  render() {
    return (
        <Modal
        show={this.props.show}
        onHide={this.quit}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                <div className='text-center'>
                    <h5>Akun anda telah digunakan pada perangkat lain</h5>
                </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <button className='btn btn-primary form-control' onClick={this.send}>Log out perangkat lain</button>

                <br/>
                <br/>

                <button className='btn btn-secondary form-control' onClick={this.quit}>Batal</button>

                
 
            </Modal.Body>
        </Modal>
    )
  }
}
