import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import Test_user from '../user_matta/test-user-matta';
import ModalEdit from './modal_edit';
import ModalDelete from './modal_delete';
import { withRouter } from 'react-router';
import cookie from 'react-cookies';
class Detail_user extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_edit:false,
            show_delete:false,
            status:'',
            user_id:null,
            user_name:'',
        }

        this.Style = {
            image: {
              background: '#f8f8f9',
              height:50,
              width:50
            }
          }
    }

//   componentDidMount(){
//     if(this.state.data!==this.props.location.state.row){
//         this.setState({
//             data:this.props.location.state.row,
            
//         })
//     }
//   }

  render() {
    // let data = null
    // let alamat = null
    // try {
    //     data= this.props.location.state.row;
    //     alamat = data.province +' '+ data.city+' '+data.subdistrict+' '+data.street;
    // } catch (error) {
    //     console.log('masuk sini')
    //     this.props.history.push(`${process.env.PUBLIC_URL}/users/list-user`)
    // }

    const data =  cookie.load('user_data_row');
    const alamat = data.province +' '+ data.city+' '+data.subdistrict+' '+data.street;
   


    return (
      <Fragment>
          <Breadcrumb title="Detail Pengguna" parent="Pengguna"/>
          <div className="container-fluid">
              <div className="card">
                  <div className="card-header">
                  <h5>Profile Pengguna</h5>
                  </div>
                
                    
                  <div className="card-body">
                    <div className="row">
                        <div className="col-1">
                            <img src={data.media_url} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                        </div>

                        <div className="col-7">
                            <table style={{tableLayout:"fixed",width:"auto"}}>

                                    <tr>
                                        <td><p>Nama Lengkap</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.full_name}</td>
                                    </tr>
                                    <tr>
                                        <td><p>Username</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.user_name}</td>
                                    </tr>
                                    <tr>
                                        <td><p>Email</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.email}</td>
                                    </tr>
                                    <tr>
                                        <td><p>Alamat</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{alamat}</td>
                                    </tr>
                            </table>                            
                        </div>

                        <div className="col-4">
                            <div className="row justify-content-start">
                                <tr>
                                    <td>
                                        <div className="col no-padds"><strong>Status</strong></div>
                                    </td>
                                    <td>
                                        <div className="col no-padds" style={{textTransform:"capitalize"}}><h4><span class="badge badge-success">{data.status_user}</span></h4></div>
                                    </td>
                                </tr>
                            </div>
                            <div className="row" style={{paddingTop:2+"em"}}>
                                {data.status_user === "blacklisted"&&(
                                <div className="col no-padds">
                                    <button type="button" className="btn btn-outline-success"
                                    onClick={()=>this.setState({show_edit:true,status:'active',
                                    user_id:data.user_id, user_name:data.user_name})}
                                    >
                                        Aktifkan
                                    </button>
                                </div>)}
                                {data.status_user === "active"&&(
                                <div className="col no-padds">
                                    <button type="button" className="btn btn-outline-dark"
                                        onClick={()=>this.setState({show_edit:true,status:'blacklisted',
                                        user_id:data.user_id, user_name:data.user_name})}
                                    >Black List</button></div>)}

                                <div className="col"><button type="button" className="btn btn-outline-danger"
                                onClick={()=>this.setState({show_delete:true,user_id:data.user_id, user_name:data.user_name})}
                                >Hapus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  
              </div>

              <div className="card">
                  <div className="card-header">
                  <h5>Riwayat Cek Mata</h5>
                  </div>

                  <div className="card-body">
                      <Test_user user_id={data.user_id}/>
                  </div>
                  
              </div>

          </div>
          <ModalEdit show={this.state.show_edit} close={()=>this.setState({show_edit:false})}
          status={this.state.status} user_id={this.state.user_id} user_name={this.state.user_name}
          />
          <ModalDelete
          show={this.state.show_delete} close={()=>this.setState({show_delete:false})}
          user_id={this.state.user_id} user_name={this.state.user_name}
          />
      </Fragment>
    )
  }
}

export default withRouter(Detail_user)
