import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AuthService from "../api_getter/auth_service";
import { withRouter } from "react-router-dom";

class ModalDetail extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    console.log(this.props);
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={this.props.data.capture_url}
            style={{ width: "100%", height: "500px", objectFit: "contain" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={this.props.close}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default withRouter(ModalDetail);
