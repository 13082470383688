import React, { Component, Fragment, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import Switch from "react-switch";
import AuthService from '../../api_getter/auth_service';
import ConfirmPay from './confirm_payment';
import PopGlobal from '../../error/response_global';


export default class Payment extends Component {
    constructor(props){
        super(props);

        this.state = {
            BCA:false,
            BNI:false,
            BRI:false,
            MANDIRI:false,
            CIMB:false,
            PERMATA:false,
            confirm_change:null,
            confirm_id:'',
            confirm_show:false,
            code:0,
            showGlobal:false

        }
        this.Style = {
            image: {
              background: '#f8f8f9',
              height:50,
              width:50
            }
          }
        this.payments = []
    }
    componentDidMount(){
        this.loadData()
      }

    loadData = () =>{
        AuthService.getPayment()
        .then(
          (data) => {
            if (data.results){
              this.payments=data.results;
              this.formatData()
  
              
            }
            else{
              this.setState({
                message:data.error.message,
                message_type:'alert-danger',
                action_selected:'',
                code:data.error.code,
                showGlobal:true
              
              })
              
            }
        
           
          }
        )
      }
      goToDashboard =()=>{
      
        if (this.state.code===307){
            this.setState({
            message:'',
            message_type:'',
            data:{},
            data_loaded:false,
            showGlobal:false 
            })
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`)
        }
        else{
          this.setState({
            message:'',
            message_type:'',
            data:{},
            data_loaded:false,
            showGlobal:false 
          })
        }
  
    }
    formatData=()=>{
        const data = this.payments;
        let active = null
        data.forEach(element => {
          active = element.active_flag === 1 ? true : false;
          this.setState({
            [element.issuer_name]:active
          })
        });
      }
  
    changePayment = (c,e,id) =>{
        this.setState({
          confirm_show:true,
          confirm_id:id,
          confirm_change:c
        })
      }
    confirm_close = () =>
      {
        this.setState({confirm_show:false})
      }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Pembayaran" parent="Transaksi" />
        <div className="container-fluid">
            
            <div className='card'>
                <div className='card-header'>
                    <h5>Pengaturan Metode Pembayaran</h5>
                </div>
                <div className='card-body'>

                    <div className='row'>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/bca.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                              <div className='p-2'>BCA</div>
                                <div className='ml-auto p-2'>
                                
                                <Switch
                                  checked={this.state.BCA}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={true}
                                  checkedIcon={true}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='BCA'
                                />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/bni.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                                <div className='p-2'>BNI</div>
                                <div className='ml-auto p-2'>
                                <Switch
                                  checked={this.state.BNI}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='BNI'
                                />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/mandiri.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                                <div className='p-2'>MANDIRI</div>
                                <div className='ml-auto p-2'>
                                <Switch
                                  checked={this.state.BNI}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='MANDIRI'
                                />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style={{width:100+'%'}}/>
                    <div className='row'>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/bri.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                                <div className='p-2'>BRI</div>
                                <div className='ml-auto p-2'>
                                <Switch
                                  checked={this.state.BRI}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='BRI'
                                />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/cimb.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                                <div className='p-2'>CIMB</div>
                                <div className='ml-auto p-2'>
                                <Switch
                                  checked={this.state.CIMB}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='CIMB'
                                />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex'>
                            <img src={process.env.PUBLIC_URL + '/permata.png'} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/>
                                <div className='p-2'>PERMATA</div>
                                <div className='ml-auto p-2'>
                                <Switch
                                  checked={this.state.PERMATA}
                                  onChange={this.changePayment}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={15}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={10}
                                  width={24}
                                  className="react-switch"
                                  id='PERMATA'
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{width:100+'%'}}/>
                    
                </div>
            </div>

        </div>
        <ConfirmPay show={this.state.confirm_show}
            id={this.state.confirm_id}
            change={this.state.confirm_change}
            close={this.confirm_close}
            reload={this.loadData} />
        <PopGlobal show={this.state.showGlobal} close={this.goToDashboard} message={this.state.message}
          message_type={this.state.message_type}/>
      </Fragment>
    )
  }
}
