import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ShippingChild from './shiping_child';

export default class TabShiping extends Component {
    constructor(props){
        super(props)
        this.state={
            tab_key:'pending',
            
        }

    }
    setKey=(k)=>{
        this.setState({tab_key:k})
        }

  render() {
    return (
      <Fragment>
          
                <Tabs
                    activeKey={this.state.tab_key}
                    onSelect={(k) => this.setKey(k)}
                    className='mb-4'
                    id="ship_tabs"
                    >
                    <Tab eventKey="pending" title="Pending" >
                        <ShippingChild id="pending"  event={this.state.tab_key} shiperout={this.props.shiperout}/>
                    </Tab>
                    {/* <Tab eventKey="packing" title="Packing">
                        <ShippingChild id="packing"  event={this.state.tab_key}/>
                    </Tab> */}
                    <Tab eventKey="sending" title="Sending">
                        <ShippingChild id="sending"  event={this.state.tab_key}/>
                    </Tab>
                    <Tab eventKey="finished" title="Finished">
                        <ShippingChild id="finished"  event={this.state.tab_key}/>
                    </Tab>
                    <Tab eventKey="canceled" title="Canceled">
                        <ShippingChild id="canceled"  event={this.state.tab_key}/>
                    </Tab>
                    <Tab eventKey="challenge" title="Challenge">
                        <ShippingChild id="challenge"  event={this.state.tab_key}/>
                    </Tab>

                </Tabs>
    </Fragment>
    )
  }
}
