import React, { Component, Fragment } from 'react';
import AuthService from '../api_getter/auth_service';
import Breadcrumb from '../common/breadcrumb';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cookie from 'react-cookies';
import { ExternalLink, Edit2, Trash2 } from 'react-feather';
import moment from 'moment';
import EditReward from './edit-reward';
import DeleteReward from './delete-reward';
import Switch from 'react-switch';

export default class AllReward extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            data_loaded: false,
            data_all_reward: [],
            list_frame: [],
            list_lens: [],
            data_row: [],
            message: '',
            message_type: '',
            showEdit: false,
            showHapus: false,
            page: 1,
            limit: 10,
            total_data: 0,
            q: '',
            searchTimeout: undefined,
        };
        this.data_column = [
            { dataField: 'id', text: 'ID', hidden: 'true' },
            { dataField: 'title', text: 'Nama Reward' },
            { text: 'Tipe', formatter: this.TypeFormater },
            { formatter: this.TypePromo, text: 'Kode Promo' },
            { text: 'Berlaku', formatter: this.ValidFormater },
            { text: 'Status', formatter: this.StatusFormater },
            {
                dataField: 'actions',
                text: 'Aksi',
                formatter: this.ActionFormater,
            },
        ];
        this.Style = {
            image: {
                background: '#fefefe',
                height: 25,
                width: 25,
            },
        };
    }

    TypeFormater = (cell, row) => {
        return <div>{row.type.label}</div>;
    };
    TypePromo = (cell, row) => {
        return <div>{row.promo_code}</div>;
    };

    ValidFormater = (cell, row) => {
        return (
            <div>
                {moment(row.start_date).format('DD MMM Y')} -{' '}
                {moment(row.end_date).format('DD MMM Y')}
            </div>
        );
    };

    StatusFormater = (cell, row) => {
        let status = row.status.value === 1 ? true : false;
        const changeStatus = () => {
            let payload = {
                ...row,
                // title: row.title,
                // start_date: row.start_date,
                // end_date: row.end_date,
                // promo_code: row.promo_code,
                // description: row.description,
                limit_redeem: 0,
                type: row.type.value,
                status: status ? 1 : 0,
            };
            AuthService.updateReward(row.id, payload).then(() => {
                this.loadAllReward();
            });
        };
        return (
            <Switch
                checked={status}
                onChange={changeStatus}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={15}
                uncheckedIcon={true}
                checkedIcon={true}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={24}
                className="react-switch"
            />
            // <div onClick={changeStatus}>
            // 	<label class='switch'>
            // 		<input type='checkbox' checked={status} />
            // 		<span class='slider round' />
            // 	</label>{' '}
            // 	{row.status.label}
            // </div>
        );
    };

    componentDidMount() {
        this.loadAllProduct();
        this.loadAllReward();
    }

    loadAllProduct = () => {
        AuthService.getAllProduct().then((res) => {
            if (res) {
                let payload = [];
                res.results.map((item) => {
                    payload.push({ value: item.kacamata_id, label: item.name });
                });
                this.setState({
                    list_frame: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
        AuthService.getAllLens().then((res) => {
            if (res) {
                let payload = [];
                res.results.map((item) => {
                    payload.push({
                        value: item.lens_id,
                        label: item.lens_name,
                    });
                });
                this.setState({
                    list_lens: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    loadAllReward = (reset) => {
        if (reset) {
            this.setState({ page: 1 });
        }
        AuthService.getAllReward({
            page: this.state.page,
            limit: this.state.limit,
            q: this.state.q,
        }).then((res) => {
            if (res.success) {
                this.setState({
                    data_all_reward: res.data.data,
                    data_loaded: true,
                    total_data: res.data.total,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    handleEdit = (data, isEdit = false) => {
        this.setState({
            isEdit,
        });
        localStorage.setItem('detail_reward', JSON.stringify(data));
        this.setState({ showEdit: true, data_row: data });
    };

    ActionFormater = (cell, row) => {
        return (
            <div>
                <a
                    href="javascript:void(0)"
                    onClick={() => this.handleEdit(row)}
                >
                    <ExternalLink size="20" />
                </a>
                &ensp;&ensp;
                <a
                    href="javascript:void(0)"
                    onClick={() => this.handleEdit(row, true)}
                >
                    <Edit2 color="green" size="20" />
                </a>
                &ensp;&ensp;
                <a
                    href="javascript:void(0)"
                    onClick={() =>
                        this.setState({ showHapus: true, data_row: row })
                    }
                >
                    <Trash2 color="red" size="20" />
                </a>
            </div>
        );
    };

    addReward = () => {
        this.props.history.push('/add-reward');
    };

    closeEditStatus = () => {
        this.setState({ showEdit: false });
    };

    handlePage = (limit, page) => {
        this.setState({
            page,
            limit,
        });
        setTimeout(() => {
            this.loadAllReward();
        }, 3e2);
    };

    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const vm = this;
        const options = {
            page: this.state.page,
            sizePerPage: this.state.limit,
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            showTotal: true,
            totalSize: this.state.total_data,
            alwaysShowAllBtns: true,
            onPageChange: function (page, limit) {
                vm.handlePage(limit, page);
            },
            onSizePerPageChange: function (limit, page) {
                vm.handlePage(limit, page);
            },
        };
        const pagination = paginationFactory(options);
        const handleChanged = (e) => {
            console.log(e.target);
            this.setState({ q: e.target.value });
            clearTimeout(this.state.searchTimeout);
            this.setState({ searchTimeout: undefined });
            this.setState({
                searchTimeout: setTimeout(() => {
                    this.loadAllReward(true);
                }, 3e2),
            });
        };
        const clearSearch = (e) => {
            this.setState({ q: '' });
            clearTimeout(this.state.searchTimeout);
            this.setState({ searchTimeout: undefined });
            this.setState({
                searchTimeout: setTimeout(() => {
                    this.loadAllReward(true);
                }, 3e2),
            });
        };

        return (
            <Fragment>
                <Breadcrumb title="LOYALTY PROGRAM REWARDS" parent="Loyalty" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5>Daftar Rewards</h5>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.addReward}
                                >
                                    Tambah Reward
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.q}
                                    onChange={handleChanged}
                                    placeholder="Search"
                                    style={{ width: 'auto' }}
                                />
                                <button
                                    className="btn btn-default"
                                    onClick={clearSearch}
                                >
                                    CLEAR
                                </button>
                            </div>
                            <ToolkitProvider
                                bootstrap4
                                keyField="user_id"
                                data={this.state.data_all_reward}
                                columns={this.data_column}
                            >
                                {(props) => (
                                    <div>
                                        <BootstrapTable
                                            id="user_table"
                                            striped
                                            hover
                                            condensed
                                            pagination={pagination}
                                            remote
                                            onTableChange={() => {}}
                                            {...props.baseProps}
                                            classes="table table-borderless"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
                <EditReward
                    edit={this.state.isEdit}
                    show={this.state.showEdit}
                    detailLoyalty={this.state.data_row}
                    listProduct={this.state.list_frame}
                    listLens={this.state.list_lens}
                    status={true}
                    id={this.state.data_row.id}
                    close={this.closeEditStatus}
                    reload={() => {
                        this.loadAllReward(true);
                        this.loadAllProduct();
                    }}
                />
                <DeleteReward
                    show={this.state.showHapus}
                    close={() => this.setState({ showHapus: false })}
                    id={this.state.data_row.id}
                    product={this.state.data_row.name}
                    reload={() => {
                        this.loadAllReward(true);
                        this.loadAllProduct();
                    }}
                />
            </Fragment>
        );
    }
}
