import React, { Component, Fragment } from 'react';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Breadcrumb from '../common/breadcrumb';
import {ExternalLink,Edit2,Trash2} from 'react-feather';
import { Link } from 'react-router-dom';
//import EditStatus from './edit_status';
import EditLensaStatus from './edit_lensa';
import HapusLensa from './hapus_lensa';
import cookie from 'react-cookies';

export default class Lens_matta extends Component {
    constructor(props){
        super(props);

        this.state={
            logged_in:false,
            data_loaded:false,
            data_user:{},
            data_row:{},
            message:'',
            message_type:'',
            showEdit:false,
            showHapus:false,
        }
        this.data_all_product = []
        this.data_column=[
        {dataField:'lens_id',text:'ID',hidden:'true'},
        {dataField:'lens_name',text:'Nama'},
        {dataField:'lens_brand',text:'Merek'},
        {dataField:'lens_type',text:'Tipe'},
        {dataField:'stock',text:'Stok'},
        {dataField:'lens_status',text:'Status',formatter:this.statusFormater},
        {dataField:'actions',text:'Aksi',formatter:this.ActionFormater}
        ]
        this.Style = {
          image: {
            background: '#fefefe',
            height:25,
            width:25
          }
        }
    }
    componentDidMount(){
        this.loadAllProduct()
    }

    loadAllProduct = () =>{
      AuthService.getAllLens().then(
        (data) => {
          if (data.results){
            this.data_all_product = data.results;
            this.setState({data_loaded:true});
          }
          else{
            this.setState({message:data.error.message,message_type:'alert-danger'})
          }
      
         
        } 
      )
    }
    goToDetail = (row) => {
      localStorage.setItem("lens_detail", JSON.stringify(row));
      cookie.save('lens_detail', row, { path: '/' });
      this.props.history.push(`${process.env.PUBLIC_URL}/lens-detail`);
    }
    ActionFormater = (cell,row) => {
      return(
        <div >
          <a href="javascript:void(0)" onClick={()=>this.goToDetail(row)}>
            <ExternalLink size='20'/>
          </a>
          &ensp;&ensp;
          <a href='javascript:void(0)' onClick={()=>this.setState({showEdit:true,data_row:row})}>
            <Edit2 color='green' size='20'/></a>
          &ensp;&ensp;
          <a href='javascript:void(0)' onClick={()=>this.setState({showHapus:true,data_row:row})}>
            <Trash2 color='red' size='20'/></a>
          
        </div>
      )
    }
    statusFormater = (cell,row) =>{
      let status = row.lens_status === 1?'Active':'Inactive';
      return(
        status
      )
    }

    newProduct = () =>
    {
        this.props.history.push("/new-lens")    
    }

    closeEditStatus = () =>{
      this.setState({showEdit:false})
    }

  render() {
    const { SearchBar, ClearSearchButton } = Search;

    

    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {

      },
      onSizePerPageChange: function (page, sizePerPage) {
      }
    });

    return (
      <Fragment>
        <Breadcrumb title="Lensa" parent="Products" />
        <div className="container-fluid">
            <div className='card'>
              <div className='card-header'>
                <h5>Daftar Lensa</h5>
                <div className="float-sm-right">
                    <button type="button" className="btn btn-primary" onClick={this.newProduct}>Tambah Lensa</button>
                </div>
                
              </div>
              <div className='card-body'>
                
              <ToolkitProvider
              bootstrap4
              keyField='lens_id'
              data={this.data_all_product}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      
                      
                      <BootstrapTable
                      id = 'lens_table'
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              
            </div>
            </div>
            </div>
            {/* <EditStatus show={this.state.showEdit} data={this.state.data_row} 
            close={this.closeEditStatus} reload={this.loadAllProduct} /> */}
            <EditLensaStatus show={this.state.showEdit} status={this.state.data_row.lens_status} id={this.state.data_row.lens_id}
            close={this.closeEditStatus} reload={this.loadAllProduct}
            />
            <HapusLensa show={this.state.showHapus} close={()=>this.setState({showHapus:false})} 
            id = {this.state.data_row.lens_id} product={this.state.data_row.lens_name} reload={this.loadAllProduct}/>
        
      </Fragment>
    )
  }
}
