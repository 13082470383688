import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import AdminStatus from './edit_admin_status_pop';
import ChangeRole from './change_role_admin_pop';
import AdminDelete from './delete_admin_pop';
import Avatar from 'react-avatar';
import cookie from 'react-cookies';

export default class DetailAdmin extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_edit:false,
            show_delete:false,
            show_change_role:false,
            status:null,
            user_id:null,
            user_name:''
        }

        this.Style = {
            image: {
              background: '#f8f8f9',
              height:50,
              width:50
            }
          }
    }



  render() {
    const data = cookie.load('admin_data_row');
    return (
      <Fragment>
          <Breadcrumb title="Detail Admin" parent="Pengguna / Admin"/>
          <div className="container-fluid">
              <div className="card">
                  <div className="card-header">
                  <h5>Profile Admin</h5>
                  </div>
                
                    
                  <div className="card-body">
                    <div className="row">
                        <div className="col-1">
                            {/* <img src={data.media_url} className='rounded-circle' 
                            style={this.Style.image} alt='loading'/> */}
                            <Avatar name={data.admin_name} round={true} size='60px'/>
                        </div>

                        <div className="col-5">
                            <table style={{tableLayout:"fixed",width:"auto"}}>

                                    <tr>
                                        <td><p>Nama Lengkap</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.admin_fullname}</td>
                                    </tr>
                                    <tr>
                                        <td><p>Username</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.admin_name}</td>
                                    </tr>
                                    <tr>
                                        <td><p>Email</p></td>
                                        <td><p>&nbsp;&nbsp;</p></td>
                                        <td>{data.email}</td>
                                    </tr>
                                    
                            </table>                            
                        </div>

                        <div className="col-6">
                            <div className="d-flex justify-content-between">
                                <tr>
                                    <td>
                                        <div className="col no-padds"><strong>Status</strong></div>
                                    </td>
                                    <td>
                                        <div className="col no-padds" style={{textTransform:"capitalize"}}>
                                            <h4><span class="badge badge-success">
                                                {data.status_admin===1?'aktif':'tidak aktif'}</span></h4>
                                        </div>
                                    </td>
                                </tr>
                            </div>
                            <div className="d-flex justify-content-between">
                                <tr>
                                    <td>
                                        <div className="col no-padds"><strong>{'Role  '}</strong></div>
                                    </td>
                                    <td>
                                        <div className="col no-padds" style={{textTransform:"capitalize"}}>
                                            <h4><span class="badge badge-info">
                                                {data.admin_role}</span></h4>
                                        </div>
                                    </td>
                                </tr>
                            </div>
                            
                            <br/><br/>
                            <div className='d-flex justify-content-between' style={{paddingLeft:0}}>
                                    {data.status_admin === 0 &&(
                                    <button type="button" className="btn btn-outline-success"
                                    onClick={()=>
                                        this.setState({
                                            show_edit:true,status:1,
                                            user_id:data.admin_id,user_name:data.admin_name
                                        })
                                    }
                                    >
                                        Aktifkan
                                    </button>)}
                                    
                                    {data.status_admin === 1 &&(
                                    <button type="button" className="btn btn-outline-dark"
                                    onClick={()=>
                                        this.setState({
                                            show_edit:true,status:0,
                                            user_id:data.admin_id,user_name:data.admin_name
                                        })
                                    }
                                    >
                                        Non Aktifkan
                                    </button>)}

                                    <button type="button" className="btn btn-outline-warning"
                                    onClick={()=>
                                        this.setState({
                                            show_change_role:true
                                        })
                                    }
                                    >
                                        Ganti Role
                                    </button>

                                    <button type="button" className="btn btn-outline-danger"
                                    onClick={()=>this.setState({show_delete:true})}
                                    >
                                        Hapus
                                    </button>
                            </div>
                        </div>
                    </div>
                  </div>
                  
              </div>

              

          </div>
          <AdminStatus show={this.state.show_edit} close={()=>this.setState({show_edit:false})}
          status={this.state.status} admin_id={this.state.user_id} admin_name={this.state.user_name}
          />
          <ChangeRole show={this.state.show_change_role} close={()=>this.setState({show_change_role:false})} 
          admin_id={data.admin_id} admin_role={data.admin_role}
          />
          <AdminDelete 
          show={this.state.show_delete} close={()=>this.setState({show_delete:false})}
          admin_id={data.admin_id} admin_name={data.admin_name}
          />
          
      </Fragment>
    )
  }
}
