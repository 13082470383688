import React, {Component, Fragment} from 'react';
import AuthService from '../api_getter/auth_service';
import Breadcrumb from '../common/breadcrumb';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PopAdmin from './pop_admin';
import DatePicker from 'react-date-picker';
import moment from 'moment';

export default class AllLoyalty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            type: 1,
            title: '',
            limit_redeem: null,
            discount_percentage: 0,
            discount_price: 0,
            start_date: new Date(),
            end_date: new Date(),
            frame_id: [],
            lens_id: [],
            promo_code: '',
            description: '',
            status: 1,
            list_frame: [],
            list_lens: [],
            message: '',
            message_type: '',
            show_pop: false,
            selected_discount: 1,
            is_max_percentage: 1,
            discount_percentage_max_value: 0,
        };
    }

    handleQuit = () => {
        if (this.state.success)
            this.props.history.push(`${process.env.PUBLIC_URL}/reward-loyalty`);
    }

    selectRadioType = (event) => {
        this.setState({
            type: parseInt(event.target.value),
        });
    };

    selectRadioDiscount = (event) => {
        this.setState({
            selected_discount: parseInt(event.target.value),
        });
    };

    selectRadioStatus = (event) => {
        this.setState({
            status: parseInt(event.target.value),
        });
    };
    selectRadioMaxPercentage = (event) => {
        this.setState({
            is_max_percentage: parseInt(event.target.value),
        });
    };

    changeInput = (event) => {
        let value = event.target.value
        if (event.target.name == 'discount_percentage')
            value = isNaN(value) ? undefined : parseInt(value)
        this.setState({
            [event.target.name]: value,
        });
    };

    selectFrame = (event) => {
        this.setState({
            frame_id: event?.value,
        });
    };

    selectLens = (event) => {
        this.setState({
            lens_id: event?.value,
        });
    };

    onChangeDateStart = (date) => {
        this.setState({
            start_date: date,
        });
    };

    onChangeDateEnd = (date) => {
        this.setState({
            end_date: date,
        });
    };

    loadAllReward = () => {
        AuthService.getAllProduct().then((res) => {
            if (res) {
                let payload = [];
                res.results.map((item) => {
                    payload.push({value: item.kacamata_id, label: item.name});
                });
                this.setState({
                    list_frame: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
        AuthService.getAllLens().then((res) => {
            if (res) {
                let payload = [];
                res.results.map((item) => {
                    payload.push({
                        value: item.lens_id,
                        label: item.lens_name,
                    });
                });
                this.setState({
                    list_lens: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    addReward = () => {
        let payload = {
            type: this.state.type,
            title: this.state.title,
            start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.end_date).format('YYYY-MM-DD'),
            frame_id: this.state.frame_id,
            lens_id: this.state.lens_id,
            promo_code: this.state.promo_code,
            description: this.state.description,
            status: this.state.status,
            limit_redeem: this.state.limit_redeem,
            point_use: this.state.point_use,
        };
        if (this.state.type === 1 && this.state.selected_discount === 1) {
            payload.discount_percentage = this.state.discount_percentage
            payload.is_max_percentage = this.state.is_max_percentage === 1 ? 0 : 1
            payload.discount_type = this.state.selected_discount === 1 ? 0 : 1
            if (this.state.is_max_percentage === 1) {
                payload.discount_percentage_max_value = this.state.discount_percentage_max_value
            }
        }
        if (this.state.type === 1 && this.state.selected_discount === 2) {
            payload.discount_price = this.state.discount_price
            payload.discount_type = this.state.selected_discount === 1 ? 0 : 1
        }
        AuthService.createReward(payload).then((res) => {
            if (res.success) {
                this.setState({
                    success: true,
                    message: res.message,
                    message_type: 'alert-success',
                    show_pop: true,
                });
            } else {
                throw new Error(res.message)
            }
        }).catch(res => {
            let message = res.message
            console.log(res.response?.data)
            if (res.response?.data)
                message = res.response.data.message
            this.setState({
                success: false,
                message: message,
                message_type: 'alert-danger',
                show_pop: true,
            });
        });
    };

    componentDidMount() {
        this.loadAllReward();
    }

    render() {
        const animatedComponents = makeAnimated();
        const listFrame = this.state.list_frame;
        const listLens = this.state.list_lens;
        return (
            <Fragment>
                <Breadcrumb title='LOYALTY PROGRAM REWARDS' parent='Loyalty'/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5>Tambah Reward</h5>
                        </div>
                        <div className='card-body'>
                            <div className='row align-items-stretch'>
                                <div className='col-md-6'>
                                    <div>
                                        <label>Tipe Reward</label>
                                        <br/>
                                        <div className='form-check form-check-inline'>
                                            <input
                                                id='type1'
                                                className='form-check-input'
                                                type='radio'
                                                name='type'
                                                value={1}
                                                onChange={this.selectRadioType}
                                                checked={this.state.type === 1}
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='type1'
                                            >
                                                Promo
                                            </label>
                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input
                                                id='type2'
                                                className='form-check-input'
                                                type='radio'
                                                name='type'
                                                value={2}
                                                onChange={this.selectRadioType}
                                                checked={this.state.type === 2}
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='type2'
                                            >
                                                Giveaway
                                            </label>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Judul</label>
                                        <br/>
                                        <input
                                            type='text'
                                            name='title'
                                            placeholder='Masukkan nama promo/hadiah'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <br/>
                                    {this.state.type === 1 && (
                                        <>
                                            <div className='w-full'>
                                                <label htmlFor='discount_percentage'>
                                                    Presentase Diskon
                                                </label>
                                                <div className='input-group align-items-center mb-3'>
                                                    <input
                                                        type='radio'
                                                        name='selected_discount'
                                                        value={1}
                                                        onChange={
                                                            this.selectRadioDiscount
                                                        }
                                                        checked={
                                                            this.state
                                                                .selected_discount === 1
                                                        }
                                                    />
                                                    <div className='input-group-prepend ml-4'>
												<span className='input-group-text'>
													%
												</span>
                                                    </div>
                                                    <input
                                                        type='number'
                                                        name='discount_percentage'
                                                        className='form-control'
                                                        value={
                                                            this.state
                                                                .discount_percentage
                                                        }
                                                        onChange={this.changeInput}
                                                        min='0'
                                                        step='1'
                                                        max='100'
                                                        placeholder='Masukkan nilai persentase diskon'
                                                        disabled={
                                                            this.state
                                                                .selected_discount !== 1
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {this.state.selected_discount === 1 && (
                                                <>
                                                    <div>
                                                        <label>Max Percentage</label>
                                                        <br/>
                                                        <div className='form-check form-check-inline'>
                                                            <input
                                                                id='isMaxPercentage1'
                                                                className='form-check-input'
                                                                type='radio'
                                                                name='is_max_percentage'
                                                                value={1}
                                                                onChange={
                                                                    this.selectRadioMaxPercentage
                                                                }
                                                                checked={
                                                                    this.state.is_max_percentage === 1
                                                                }
                                                            />
                                                            <label
                                                                className='form-check-label'
                                                                htmlFor='isMaxPercentage1'
                                                            >
                                                                Aktif
                                                            </label>
                                                        </div>
                                                        <div className='form-check form-check-inline'>
                                                            <input
                                                                id='isMaxPercentage2'
                                                                className='form-check-input'
                                                                type='radio'
                                                                name='is_max_percentage'
                                                                value={2}
                                                                onChange={
                                                                    this.selectRadioMaxPercentage
                                                                }
                                                                checked={
                                                                    this.state.is_max_percentage === 2
                                                                }
                                                            />
                                                            <label
                                                                className='form-check-label'
                                                                htmlFor='isMaxPercentage2'
                                                            >
                                                                Tidak Aktif
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <br/>
                                            {this.state.is_max_percentage === 1 && (
                                                <>
                                                    <div className='w-full'>
                                                        <label htmlFor='discount_percentage_max_value'>
                                                            Max Nominal Diskon
                                                        </label>
                                                        <div className='input-group align-items-center mb-3'>
                                                            <input
                                                                type='number'
                                                                name='discount_percentage_max_value'
                                                                className='form-control'
                                                                value={
                                                                    this.state
                                                                        .discount_percentage_max_value
                                                                }
                                                                onChange={this.changeInput}
                                                                min='0'
                                                                step='1'
                                                                max='100'
                                                                placeholder='Masukkan nilai max nominal diskon'
                                                                disabled={
                                                                    this.state
                                                                        .selected_discount !== 1
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <br/>
                                            <div className='w-full'>
                                                <label htmlFor='discount_price'>
                                                    Potongan Harga
                                                </label>
                                                <div className='input-group align-items-center mb-3'>
                                                    <input
                                                        type='radio'
                                                        name='selected_discount'
                                                        value={2}
                                                        onChange={
                                                            this.selectRadioDiscount
                                                        }
                                                        checked={
                                                            this.state
                                                                .selected_discount === 2
                                                        }
                                                    />
                                                    <div className='input-group-prepend ml-4'>
												<span className='input-group-text'>
													Rp.
												</span>
                                                    </div>
                                                    <input
                                                        type='number'
                                                        name='discount_price'
                                                        className='form-control'
                                                        value={
                                                            this.state.discount_price
                                                        }
                                                        onChange={this.changeInput}
                                                        min='0'
                                                        step='1'
                                                        placeholder='Masukkan nominal potongan harga'
                                                        disabled={
                                                            this.state
                                                                .selected_discount !== 2
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label>Tanggal Berlaku</label>
                                            <DatePicker
                                                name='start_date'
                                                format='y-MM-dd'
                                                clearIcon={null}
                                                value={this.state.start_date}
                                                onChange={
                                                    this.onChangeDateStart
                                                }
                                                className='form-control p-0'
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>Hingga</label>
                                            <DatePicker
                                                name='end_date'
                                                format='y-MM-dd'
                                                clearIcon={null}
                                                value={this.state.end_date}
                                                onChange={this.onChangeDateEnd}
                                                className='form-control p-0'
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Produk Frame</label>
                                        <br/>
                                        <Select
                                            components={animatedComponents}
                                            isMulti={false}
                                            isClearable={true}
                                            options={listFrame}
                                            onChange={this.selectFrame}
                                            name='frame_id'
                                            placeholder='Pilih disini'
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Produk Lensa</label>
                                        <br/>
                                        <Select
                                            components={animatedComponents}
                                            isClearable={true}
                                            options={listLens}
                                            onChange={this.selectLens}
                                            name='lens_id'
                                            placeholder='Pilih disini'
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div>
                                        <label>Kode Promo</label>
                                        <br/>
                                        <input
                                            type='text'
                                            name='promo_code'
                                            placeholder='Masukkan nama promo/hadiah'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Limit Redeem</label>
                                        <br/>
                                        <input
                                            type='number'
                                            name='limit_redeem'
                                            placeholder='Masukkan limit redeem'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Point Use</label>
                                        <br/>
                                        <input
                                            type='number'
                                            name='point_use'
                                            placeholder='Masukkan Point Use'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Deskripsi</label>
                                        <br/>
                                        <textarea
                                            name='description'
                                            className='form-control'
                                            rows='3'
                                            placeholder='Masukkan deskripsi'
                                            onChange={this.changeInput}
                                            value={this.state.description}
                                        />
                                    </div>
                                    <br/>

                                    <div>
                                        <label>Status</label>
                                        <br/>
                                        <div className='form-check form-check-inline'>
                                            <input
                                                id='status1'
                                                className='form-check-input'
                                                type='radio'
                                                name='status'
                                                value={1}
                                                onChange={
                                                    this.selectRadioStatus
                                                }
                                                checked={
                                                    this.state.status === 1
                                                }
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='status1'
                                            >
                                                Aktif
                                            </label>
                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input
                                                id='status2'
                                                className='form-check-input'
                                                type='radio'
                                                name='status'
                                                value={0}
                                                onChange={
                                                    this.selectRadioStatus
                                                }
                                                checked={
                                                    this.state.status === 0
                                                }
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='status2'
                                            >
                                                Tidak Aktif
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <button
                                type='button'
                                className='btn btn-primary mt-'
                                onClick={this.addReward}
                            >
                                Tambahkan Reward
                            </button>
                        </div>
                    </div>
                </div>
                <PopAdmin
                    show={this.state.show_pop}
                    close={() => this.setState({show_pop: false})}
                    message={this.state.message}
                    message_type={this.state.message_type}
                    handleQuit={this.handleQuit}
                />
            </Fragment>
        );
    }
}
