import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import AuthService from '../api_getter/auth_service';
import PopGlobal from '../error/response_global';

export default class ConstantMatta extends Component {
    constructor(props){
        super(props);

        this.state={
            myoa:'',
            myob:'',
            hypa:'',
            hypb:'',
            message:'',
            message_type:'',
            input:'disabled',
            showGlobal:false
        }
    }
    handleChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        });
    }
    editField = () =>{
        this.setState({
            input:''
        })
    }
    saveField = () =>{
        AuthService.editConstant(this.state.myoa,this.state.myob,this.state.hypa,this.state.hypb)
        .then((data)=>{
            if(data.results){
                this.setState({
                    input:'disabled',
                    message:data.results,
                    message_type:'alert-success',
                    showGlobal:true
                })
            }
            else{
                this.setState({
                    input:'disabled',
                    message:data.error.message,
                    message_type:'alert-danger',
                    showGlobal:true
                })
            }
        })
        
    }
    componentDidMount(){
        AuthService.getConstant()
        .then((data)=>{
            if(data.results){
                this.setState({
                    myoa:data.results['myoa'],
                    myob:data.results['myob'],
                    hypa:data.results['hypa'],
                    hypb:data.results['hypb']
                })
            }
            else{
                this.setState({
                    message:data.error.message,message_type:'alert-danger'
                })
            }
        })
    }
    goToDashboard =()=>{
      
        if (this.state.code===307){
            this.setState({
            message:'',
            message_type:'',
            showGlobal:false 
            })
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`)
        }
        else{
          this.setState({
            message:'',
            message_type:'',
            showGlobal:false 
          })
        }
  
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Konstanta" parent="Pengaturan"/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5>Pengaturan konstanta</h5>
                            <div className="float-sm-right">
                            {this.state.input==='disabled'?(<button type="button" className="btn btn-primary" onClick={this.editField} >Edit</button>
                            ):(
                            <button type="button" className="btn btn-primary" onClick={this.saveField}>Simpan</button>)}
                        </div>
                        <br/>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-2'>Konstanta</div>
                                <div className='col-md-3'>Myopia</div>
                                <div className='col-md-3'>Hypermetropia</div>
                                <div className='col-md-4'></div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                <hr style={{width:100+'%'}}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'><label>A</label></div>
                                <div className='col-md-3'>
                                    <input name='myoa' className='control-form' value={this.state.myoa} disabled={this.state.input}
                                    onChange={this.handleChange}/>
                                    </div>
                                <div className='col-md-3'>
                                    <input name='hypa' className='control-form' value={this.state.hypa} disabled={this.state.input}
                                    onChange={this.handleChange}/>
                                    </div>
                                <div className='col-md-4'></div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className='col-md-2'><label>B</label></div>
                                <div className='col-md-3'>
                                    <input className='control-form' name='myob' value={this.state.myob} disabled={this.state.input}
                                    onChange={this.handleChange}/>
                                    </div>
                                <div className='col-md-3'>
                                    <input className='control-form' name='hypb' value={this.state.hypb} disabled={this.state.input}
                                    onChange={this.handleChange}/>
                                    </div>
                                <div className='col-md-4'></div>
                            </div>

                        </div>
                
                    </div>

                    </div>

                </div>
                <PopGlobal show={this.state.showGlobal} close={this.goToDashboard} message={this.state.message}
                message_type={this.state.message_type}
              />
            </Fragment>
        )
    }
}
