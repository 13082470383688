import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../../api_getter/auth_service';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {Edit2} from 'react-feather';

const animatedComponents = makeAnimated();

export default class EditPromo extends Component {
    constructor(props){
        super(props);

        this.state={
            action:null,
            edit:false,
            message:'',
            message_type:'',
            success:false
        }
        
    }
    selectOption = (e) =>{
        let act = e.value === 1 ?'Active':'Inactive';
        let edt = this.props.status !== act
        this.setState({
          action:e.value,edit:edt
        })
        
      }
    ChangeAction = () =>{
        AuthService.editPromo(this.props.id,this.state.action)
        .then(
            (data) => {
                if(data.results){
                    this.setState({
                    message:data.results,
                    message_type:'alert-success',
                    edit:false
                    })
                    this.props.reload() 
                    }
              else{
                this.setState({
                    message:data.error.message,
                    message_type:'alert-danger'
                })
            }
        })
    }
    quit = () =>{
        this.setState({
            action:null,
            edit:false,
            message:'',
            message_type:'',
            success:false
        })
        this.props.close()
    }
    render() {
        const action = [
            { value: 1, label: 'Activate' },
            { value: 0, label: 'Deactivate' }
          ]
        return (
        <Modal
          show={this.props.show}
          onHide={this.quit}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit promo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message?(
                    <div className={'alert '+this.state.message_type}>
                        {this.state.message}
                    </div>
                ):(
                <div className='row'>
                    <div className='col-md-6'>
                    <label>Silahkan pilih aksi </label>
                    </div>
                    <div className='col-md-6'>
                    <Select
                        components={animatedComponents}
                        options={action}
                        onChange={this.selectOption}
                        name="kurir"
                        placeholder="Pilih aksi"
                    />
                    </div>
                    
                </div>)}

            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary' onClick={this.quit}>Close</button>
                {this.state.edit&&(<button className='btn btn-primary'
                onClick={this.ChangeAction} >Save</button>)}
            </Modal.Footer>
        </Modal>
        )
    }
}
