import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cookie from 'react-cookies';
import OtpInput from 'react-otp-input';
import AuthService from '../api_getter/auth_service';
import Countdown,{zeroPad} from "react-countdown";


export default class OtpForgot extends Component {
    constructor(props){
        super(props);

        this.state={
            otp:'',
            message:'',
            message_type:'',
            show_otp:true
        }
    }
    
    Back_to_login = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`);
    }

    handleChange = (otp) =>{
        
        this.setState({
            otp:otp
        });
    }
    Verify = (event) =>{
        event.preventDefault();

        AuthService.verify_forget(this.state.otp)
        .then( (data) => {
            
            if (data.results){
                this.props.history.push(`${process.env.PUBLIC_URL}/auth/newpassword`);
            }
            else {
                this.setState({message:data.error.message});
            };
        });
    }
    KirimUlang = () =>{
        AuthService.newOtpForget()
        .then((data)=>{
            if (data.results){
                window.location.reload()
            }
            else {
                this.setState({message:data.error.message});
            };
        })
    }

    count_render = ({ minutes, seconds, completed }) => {
        if (completed) {
          this.setState({show_otp:false})
          return <label>selesai</label>;
        } 
        else {
          return (
            <strong>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </strong>
          );
        }
    };
    
    
    render() {
        let login_status = cookie.load('logged_in')
        if (login_status.status===true){
            return <Redirect to="/dashboard"/>
        }
    
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-7 p-0 mx-auto">
                                    <div className="card tab2-card">
                                        <div className="shadow-lg rounded">
                                        <div className="card-body">
                                        
                                    <form className="form-horizontal auth-form" onSubmit={this.Verify}>
                                        
                                        <div className="form-group text-center">
                                            <h5>Cek email anda dan masukkan 6 digit verifikasi</h5><br/>
                                            {this.state.show_otp?(<Countdown date={Date.now() + 180000} renderer={this.count_render}/>):(
                                            <label>anda kehabisan waktu</label>
                                            )}
                                        </div>

                                        {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                            </div>
                                        </div>
                                        )}

                                        <div style={{marginLeft:'auto',
                                        marginRight:'auto'
                                        }}>
                                        {this.state.show_otp&&(<OtpInput
                                                value={this.state.otp}
                                                onChange={this.handleChange}
                                                numInputs={6}
                                                separator={<span>&ensp;</span>}
                                                inputStyle={'matta-otp'}
                                        />)}
                                        </div>
                                        <br/>
                                        <div className="form-group text-center">
                                            <label>Tidak menerima kode apapun? <a href='javascript:void(0)' onClick={this.KirimUlang}>Kirim Ulang</a></label>
                                        </div>
                                        <div className="form-button" style={{marginLeft:'auto',marginRight:'auto',width:10+'em'}}>
                                            <button className="btn btn-primary" type="submit" 
                                            disabled={(this.state.otp.length!==6)?'disabled':''}>Lanjut</button>
                                        </div>
                              
                                    </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

