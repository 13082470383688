import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Breadcrumb from '../common/breadcrumb';
import { Upload } from 'react-feather';
import AuthService from '../api_getter/auth_service';
import SuccessPOPLensa from '../product_matta_preview/pop_success_lensa';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const animatedComponents = makeAnimated();

export default class AddLens extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nama_lensa: '',
			harga_modal: '',
			merek_lensa: '',
			harga_awal: 0,
			discount: 0,
			garansi: '',
			foto_name: '',
			foto_files: null,
			harga_akhir: 0,
			tipe_lensa: '',
			bahan_lensa: '',
			warna_lensa: '',
			stock: 0,
			minus_min: 0,
			minus_max: 0,
			plus_min: 0,
			plus_max: 0,
			addition_min: 0,
			addition_max: 0,
			silinder_min: 0,
			silinder_max: 0,
			axis_cyl_min: 0,
			axis_cyl_max: 0,
			foto_state: '',
			show: false,
			message: '',
			message_type: '',
			merek: [],
			format_merek: [],
			description: EditorState.createEmpty(),
			berat: '',
			panjang: '',
			lebar: '',
			tinggi: '',
		};
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			description: editorState,
		});
	};

	loadAllProduct = () => {
		AuthService.getAllLens().then((data) => {
			let result = data.results.slice(-1);
			let payload = {
				type: 'lens',
				product_id: result[0].lens_id,
				weight: this.state.berat,
				width: this.state.lebar,
				height: this.state.tinggi,
				length: this.state.panjang,
			};
			AuthService.createDataMaster(payload).then(() => {
				this.setState({ show: true });
			});
		});
	};

	ConstAndSaveData = () => {
		let gar = this.state.garansi === 'Ya' ? 1 : 0;
		let data_kirim = {
			id_brand: this.state.merek_lensa,
			lens_name: this.state.nama_lensa,
			lens_type: this.state.tipe_lensa,
			lens_material: this.state.bahan_lensa,
			minus_min: this.state.minus_min,
			minus_max: this.state.minus_max,
			plus_min: this.state.plus_min,
			plus_max: this.state.plus_max,
			addition_max: this.state.addition_max,
			addition_min: this.state.addition_min,
			axis_cyl_max: this.state.axis_cyl_max,
			axis_cyl_min: this.state.axis_cyl_min,
			description: draftToHtml(
				convertToRaw(this.state.description.getCurrentContent()),
			),
			astigmatism_min: this.state.silinder_min,
			astigmatism_max: this.state.silinder_max,
			price_capital: this.state.harga_modal,
			price: this.state.harga_awal,
			discount: this.state.discount,
			price_sale: this.state.harga_awal - this.state.discount,
			lens_color: this.state.warna_lensa,
			stock: this.state.stock,
			guarantee: gar,
			foto: this.state.foto_files,
		};
		AuthService.AddnewLensa(data_kirim).then((data) => {
			if (data.results) {
				this.loadAllProduct();
				this.setState({ message: data.results });
			} else {
				this.setState({ message: data.error.message, show: true });
			}
		});
	};

	ChangeInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	ChangeOption = (value, actionMeta) => {
		this.setState({
			[actionMeta.name]: value.value,
		});
	};
	ChangeFoto = (e) => {
		let file = e.target.files[0];
		let name = file.name;
		this.setState({
			foto_state: 'loaded',
			foto_name: name,
			foto_files: file,
		});
	};
	componentDidMount() {
		this.loadAllBrand();
	}

	loadAllBrand = () => {
		AuthService.getAllBrandLens().then((data) => {
			if (data.results) {
				this.setState({ merek: data.results });
				let mereks = this.constructOption();
				this.setState({ format_merek: mereks });
			} else {
				this.setState({
					message: data.error.message,
					message_type: 'alert-danger',
				});
			}
		});
	};

	constructOption = () => {
		let opt = [];
		this.state.merek.forEach((element, index) => {
			let obj = {
				value: element.id_brand_lens,
				label: element.nama_brand,
			};
			opt.push(obj);
		});

		return opt;
	};

	render() {
		const merek_lensa = [
			{ value: 1, label: 'Roddenstock' },
			{ value: 2, label: 'Essilor' },
			{ value: 3, label: 'CalvinKlein' },
			{ value: 4, label: 'Hoya' },
			{ value: 5, label: 'Oakley' },
		];
		const tipe_lensa = [
			{ value: 'Single Vision', label: 'Single Vision' },
			{ value: 'Bifokal', label: 'Bifokal' },
			{ value: 'Progressive', label: 'Progressive' },
			{ value: 'Office', label: 'Office' },
			{ value: 'Fatigue Free', label: 'Fatigue Free' },
		];
		const bahan_lensa = [
			{ value: 'Plastik', label: 'Plastik' },
			{ value: 'Kaca', label: 'Kaca' },
			{ value: 'Blue Light Shield', label: 'Blue Light Shield' },
		];
		const warna_lensa = [
			{ value: 'Photochromic', label: 'Photochromic' },
			{ value: 'Polarized', label: 'Polarized' },
			{ value: 'Drive Wear', label: 'Drive Wear' },
			{ value: 'Tinting', label: 'Tinting' },
		];
		return (
			<Fragment>
				<Breadcrumb title='Tambah Lensa Baru' parent='Produk / Lensa' />
				<div className='container-fluid'>
					<div className='card'>
						<div className='card-header'>
							<h5>Identitas Lensa</h5>
						</div>

						<div className='card-body matta-no-arrow'>
							<div className='row'>
								<div className='col-md-6'>
									<label>Nama Lensa</label>
									<input
										type='text'
										name='nama_lensa'
										className='form-control'
										placeholder='Masukkan nama lensa'
										onChange={this.ChangeInput}
									/>
								</div>

								<div className='col-md-6'>
									<label for='harga_modal'>Harga Modal</label>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span className='input-group-text'>
												Rp
											</span>
										</div>
										<input
											type='number'
											name='harga_modal'
											className='form-control'
											onChange={this.ChangeInput}
											min='0'
											step='1000'
											max='999999999'
											placeholder='modal ...'
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<label>Merek lensa</label>
									<Select
										components={animatedComponents}
										options={this.state.format_merek}
										name='merek_lensa'
										placeholder='Pilih disini'
										onChange={this.ChangeOption}
									/>
								</div>

								<div className='col-md-3'>
									<label for='harga_awal'>Harga Awal</label>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span className='input-group-text'>
												Rp
											</span>
										</div>

										<input
											type='number'
											name='harga_awal'
											className='form-control'
											min='0'
											step='1'
											max='999999999'
											placeholder='harga awal ...'
											onChange={this.ChangeInput}
										/>
									</div>
								</div>

								<div className='col-md-3'>
									<label for='diskon'>Diskon</label>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span className='input-group-text'>
												Rp
											</span>
										</div>
										<input
											type='number'
											name='discount'
											className='form-control'
											min='0'
											step='1'
											max='999999999'
											placeholder='diskon ...'
											onChange={this.ChangeInput}
										/>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-2'>
									<label>Garansi</label>
									<br />
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='garansi'
											value='Ya'
											onChange={this.ChangeInput}
										/>
										<label
											className='form-check-label'
											for='nosepad1'
										>
											Ya
										</label>
									</div>
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='garansi'
											value='Tidak'
											onChange={this.ChangeInput}
										/>
										<label
											className='form-check-label'
											for='nosepad2'
										>
											Tidak
										</label>
									</div>
								</div>

								<div className='col-md-4'>
									<label>Upload Foto</label>
									<br />
									<div
										className='input-group mb-3'
										style={{ lineHeight: 50 + '%' }}
									>
										<input
											type='text'
											className='form-control'
											placeholder='Pilih foto lensa'
											disabled
											value={this.state.foto_name}
										/>
										<div className='input-group-append'>
											<span
												className='input-group-text'
												style={{
													paddingTop: 13 + 'px',
												}}
											>
												<label for='file-input'>
													<i>
														<Upload size='20' />
													</i>
												</label>
												<input
													id='file-input'
													type='file'
													onChange={this.ChangeFoto}
													accept='image/*'
													style={{ display: 'none' }}
												/>
											</span>
										</div>
									</div>
								</div>

								<div className='col-md-6'>
									<label for='harga_akhir'>Harga Akhir</label>
									<br />
									<br />
									<label id='harga_akhir'>
										<strong>
											<h3>
												Rp.{' '}
												{this.state.harga_awal -
													this.state.discount}
											</h3>
										</strong>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div className='card'>
						<div className='card-header'>
							<h5>Informasi Lensa</h5>
						</div>
						<div className='card-body'>
							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Tipe Lensa</label>
										<input
											type='text'
											name='tipe_lensa'
											className='form-control'
											placeholder='Masukkan tipe lensa'
											onChange={this.ChangeInput}
										/>
										<small className='form-text text-muted'>
											Contoh: Single Vision, Bifokal, dan
											Lain-lain
										</small>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Bahan Lensa</label>
										<input
											type='text'
											name='bahan_lensa'
											className='form-control'
											placeholder='Masukkan bahan lensa'
											onChange={this.ChangeInput}
										/>
										<small className='form-text text-muted'>
											Contoh: Plastik, Kaca, dan Lain-lain
										</small>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>Warna Lensa</label>
										<input
											type='text'
											name='warna_lensa'
											className='form-control'
											placeholder='Masukkan warna lensa'
											onChange={this.ChangeInput}
										/>
										<small className='form-text text-muted'>
											Contoh: Photochromic, Polarized, dan
											Lain-lain
										</small>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label for='counter'>Jumlah Stok</label>
										<div
											className='input-group mb-3'
											id='counter'
										>
											<div className='input-group-prepend'>
												<button
													className='btn btn-primary'
													type='button'
													onClick={() =>
														this.setState({
															stock:
																this.state
																	.stock - 1,
														})
													}
												>
													{'-'}
												</button>
											</div>
											<input
												type='number'
												id='stock'
												name='stock'
												className='form-control'
												style={{ textAlign: 'center' }}
												min='0'
												step='1'
												max='999999999'
												placeholder=''
												value={this.state.stock}
												onChange={this.ChangeInput}
											/>

											<div className='input-group-append'>
												<button
													className='btn btn-primary'
													type='button'
													onClick={() =>
														this.setState({
															stock:
																this.state
																	.stock + 1,
														})
													}
												>
													{'+'}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 matta-no-arrow'>
									<label>Berat</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='berat'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Berat kacamata'
										/>
										<div class='input-group-append'>
											<span class='input-group-text'>
												gram
											</span>
										</div>
									</div>
								</div>
								<div className='col-md-6 matta-no-arrow'>
									<label>Panjang</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='panjang'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Panjang kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 matta-no-arrow'>
									<label>Lebar</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='lebar'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Lebar kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
								<div className='col-md-6 matta-no-arrow'>
									<label>Tinggi</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='tinggi'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Tinggi kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-12'>
									<div className='form-group'>
										<label>Description</label>
										<Editor
											editorState={this.state.description}
											wrapperClassName='demo-wrapper'
											editorClassName='demo-editor border rounded p-3'
											onEditorStateChange={
												this.onEditorStateChange
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='card'>
						<div className='card-header'>
							<h5>Ukuran Lensa</h5>
						</div>
						<div className='card-body matta-no-arrow'>
							<div className='row'>
								<div className='col'>
									<h5>
										<strong>Minus</strong>
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label>Minimal</label>
									<input
										type='number'
										name='minus_min'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
								<div className='col-md-6'>
									<label>Maksimal</label>
									<input
										type='number'
										name='minus_max'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col'>
									<h5>
										<strong>Plus</strong>
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label>Minimal</label>
									<input
										type='number'
										name='plus_min'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
								<div className='col-md-6'>
									<label>Maksimal</label>
									<input
										type='number'
										name='plus_max'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col'>
									<h5>
										<strong>Addition</strong>
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label>Minimal</label>
									<input
										type='number'
										name='addition_min'
										className='form-control'
										min='-10'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
								<div className='col-md-6'>
									<label>Maksimal</label>
									<input
										type='number'
										name='addition_max'
										className='form-control'
										min='-10'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col'>
									<h5>
										<strong>Silinder</strong>
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label>Minimal</label>
									<input
										type='number'
										name='silinder_min'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
								<div className='col-md-6'>
									<label>Maksimal</label>
									<input
										type='number'
										name='silinder_max'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col'>
									<h5>
										<strong>Axis Silinder</strong>
									</h5>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<label>Minimal</label>
									<input
										type='number'
										name='axis_cyl_min'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
								<div className='col-md-6'>
									<label>Maksimal</label>
									<input
										type='number'
										name='axis_cyl_max'
										className='form-control'
										min='0'
										step='.01'
										max='10'
										onChange={this.ChangeInput}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col'>
							<button
								className='btn btn-primary'
								onClick={this.ConstAndSaveData}
							>
								Tambahkan lensa
							</button>
						</div>
					</div>
				</div>
				<SuccessPOPLensa
					show={this.state.show}
					close={() => this.setState({ show: false })}
					message={this.state.message}
				/>
			</Fragment>
		);
	}
}
