import React, {Component, Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';
import {Edit2, Save, Upload} from 'react-feather';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AuthService from '../api_getter/auth_service';
import SuccessPOP from '../product_matta_preview/pop_success';
import cookie from 'react-cookies';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const animatedComponents = makeAnimated();

const Idenstitas_lensa = (props) => {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Identitas Lensa</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={props.edit}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body matta-no-arrow'>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Nama Lensa</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lens_name}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Harga Modal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>Rp. {props.data.price_capital}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Merek</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lens_brand}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Harga Awal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>Rp. {props.data.price}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Garansi</label>
                    </div>
                    <div className='col-md-4'>
                        <label>
                            {props.data.guarantee === 1 ? 'Ya' : 'Tidak'}
                        </label>
                    </div>
                    <div className='col-md-2'>
                        <label>Diskon</label>
                    </div>
                    <div className='col-md-4'>
                        <label>Rp. {props.data.discount}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Foto</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.media_url}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Harga Akhir</label>
                    </div>
                    <div className='col-md-4'>
                        <label>
                            Rp. {props.data.price - props.data.discount}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Identitas_lensa_Edit = (props) => {
    const [id_brand, Setid_brand] = useState(props.data.id_brand);
    const [lens_id, Setlens_id] = useState(props.data.lens_id);
    const [lens_name, Setlens_name] = useState(props.data.lens_name);
    const [lens_brand, Setlens_brand] = useState(props.data.lens_brand);
    const [guarantee, Setguarantee] = useState(props.data.guarantee);
    const [price_capital, Setprice_capital] = useState(
        props.data.price_capital,
    );
    const [price, Setprice] = useState(props.data.price);
    const [discount, Setdiscount] = useState(props.data.discount);
    const [price_sale, Setprice_sale] = useState(props.data.price_sale);
    const [mediaurl, Setmediaurl] = useState(props.data.media_url);
    const [files, Setfiles] = useState(props.data.files);

    const ChangeFoto = (e) => {
        let file = e.target.files[0];
        let name = file.name;
        Setmediaurl(name);
        Setfiles(file);
    };

    const Selectmerek = (e) => {
        Setlens_id(e.value);
        Setlens_brand(e.label);
    };

    const SaveAndQuit = () => {
        let data_id_lensa = {
            id_brand: id_brand,
            lens_id: lens_id,
            lens_name: lens_name,
            lens_brand: lens_brand,
            guarantee: guarantee,
            price_capital: price_capital,
            price: price,
            discount: discount,
            price_sale: price_sale,
            media_url: mediaurl,
            files: files,
        };
        props.update(data_id_lensa);
        props.save();
    };

    const merek_lensa = [
        {value: 1, label: 'Roddenstock'},
        {value: 2, label: 'Essilor'},
        {value: 3, label: 'CalvinKlein'},
        {value: 4, label: 'Hoya'},
        {value: 5, label: 'Oakley'},
    ];

    let idx_merek = null;
    merek_lensa.forEach((element, index) => {
        if (props.data.lens_brand === element.label) {
            idx_merek = index;
        }
    });

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Identitas Lensa</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={SaveAndQuit}
                        >
                            selesai
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body matta-no-arrow'>
                <div className='row'>
                    <div className='col-md-6'>
                        <label>Nama Lensa</label>
                        <input
                            type='text'
                            name='nama_lensa'
                            className='form-control'
                            value={lens_name}
                            onChange={(e) => Setlens_name(e.target.value)}
                        />
                    </div>

                    <div className='col-md-6'>
                        <label for='harga_modal'>Harga Modal</label>
                        <div className='input-group mb-3'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text'>Rp</span>
                            </div>
                            <input
                                type='number'
                                name='harga_modal'
                                className='form-control'
                                onChange={(e) =>
                                    Setprice_capital(e.target.value)
                                }
                                min='0'
                                step='1000'
                                max='999999999'
                                placeholder='modal ...'
                                value={price_capital}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <label>Merek lensa</label>
                        <Select
                            components={animatedComponents}
                            options={merek_lensa}
                            name='merek_lensa'
                            placeholder='Pilih disini'
                            onChange={Selectmerek}
                            defaultValue={merek_lensa[idx_merek]}
                        />
                    </div>

                    <div className='col-md-3'>
                        <label for='harga_awal'>Harga Awal</label>
                        <div className='input-group mb-3'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text'>Rp</span>
                            </div>

                            <input
                                type='number'
                                name='harga_awal'
                                className='form-control'
                                min='0'
                                step='1'
                                max='999999999'
                                placeholder='harga awal ...'
                                value={price}
                                onChange={(e) => Setprice(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <label for='diskon'>Diskon</label>
                        <div className='input-group mb-3'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text'>Rp</span>
                            </div>
                            <input
                                type='number'
                                name='discount'
                                className='form-control'
                                min='0'
                                step='1'
                                max='999999999'
                                placeholder='diskon ...'
                                value={discount}
                                onChange={(e) => Setdiscount(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Garansi</label>
                        <br/>
                        <br/>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='garansi'
                                value='Ya'
                                checked={guarantee === 1 ? 'checked' : ''}
                                onChange={() => Setguarantee(1)}
                            />
                            <label className='form-check-label' for='nosepad1'>
                                Ya
                            </label>
                        </div>
                        <div class='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='garansi'
                                value='Tidak'
                                checked={guarantee === 1 ? '' : 'checked'}
                                onChange={() => Setguarantee(0)}
                            />
                            <label className='form-check-label' for='nosepad2'>
                                Tidak
                            </label>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <label>Upload Foto</label>
                        <br/>
                        <div
                            className='input-group mb-3'
                            style={{lineHeight: 50 + '%'}}
                        >
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Pilih foto lensa'
                                disabled
                                value={mediaurl}
                            />
                            <div className='input-group-append'>
								<span
                                    className='input-group-text'
                                    style={{paddingTop: 13 + 'px'}}
                                >
									<label for='file-input'>
										<i>
											<Upload size='20'/>
										</i>
									</label>
									<input
                                        id='file-input'
                                        type='file'
                                        accept='image/*'
                                        style={{display: 'none'}}
                                        onChange={ChangeFoto}
                                    />
								</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label for='harga_akhir'>Harga Akhir</label>
                        <br/>
                        <br/>
                        <label id='harga_akhir'>
                            <strong>
                                <h3>Rp. {price - discount}</h3>
                            </strong>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Informasi_product = (props) => {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Informasi Lensa</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={props.edit}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body matta-no-arrow'>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Tipe Lensa</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lens_type}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Bahan Lensa</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lens_material}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Warna Lensa</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lens_color}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Stock Lensa</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.stock}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Berat</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.berat}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Panjang</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.panjang || '-'}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Lebar</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.lebar || '-'}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Tinggi</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.tinggi || '-'}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Description</label>
                    </div>
                    <div className='demo-editor-text col-md-10'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.data.description,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
const Informasi_product_edit = (props) => {
    const tipe_lensa = [
        {value: 'Single Vision', label: 'Single Vision'},
        {value: 'Bifokal', label: 'Bifokal'},
        {value: 'Progressive', label: 'Progressive'},
        {value: 'Office', label: 'Office'},
        {value: 'Fatigue Free', label: 'Fatigue Free'},
    ];
    const bahan_lensa = [
        {value: 'Plastik', label: 'Plastik'},
        {value: 'Kaca', label: 'Kaca'},
        {value: 'Blue Light Shield', label: 'Blue Light Shield'},
    ];
    const warna_lensa = [
        {value: 'Photochromic', label: 'Photochromic'},
        {value: 'Polarized', label: 'Polarized'},
        {value: 'Drive Wear', label: 'Drive Wear'},
        {value: 'Tinting', label: 'Tinting'},
    ];
    let idx_tipe = null;
    let idx_bahan = null;
    let idx_warna = null;

    tipe_lensa.forEach((element, index) => {
        if (props.data.lens_type === element.label) {
            idx_tipe = index;
        }
    });
    bahan_lensa.forEach((element, index) => {
        if (props.data.lens_material === element.label) {
            idx_bahan = index;
        }
    });
    warna_lensa.forEach((element, index) => {
        if (props.data.lens_color === element.label) {
            idx_warna = index;
        }
    });

    const [load, setLoad] = useState(true);
    const [stock, Setstock] = useState(props.data.stock);
    const [beratVal, Setberat] = useState(props.data.berat);
    const [panjangVal, Setpanjang] = useState(props.data.panjang);
    const [lebarVal, Setlebar] = useState(props.data.lebar);
    const [tinggiVal, Settinggi] = useState(props.data.tinggi);
    const [lens_type, Setlens_type] = useState(props.data.lens_type);
    const [lens_material, Setlens_material] = useState(
        props.data.lens_material,
    );
    const [lens_color, Setlens_color] = useState(props.data.lens_color);
    const [description, Setdescription] = useState();
    const contentBlock = htmlToDraft(props.data.description);
    if (contentBlock && load) {
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        Setdescription(editorState);
        setLoad(false);
    }

    const onEditorStateChange = (editorState) => {
        Setdescription(editorState);
    };

    const SaveandQuitIdentity = () => {
        let info_lensa = {
            lens_type: lens_type,
            lens_material: lens_material,
            lens_color: lens_color,
            stock: stock,
            description: draftToHtml(
                convertToRaw(description.getCurrentContent()),
            ),
        };
        props.update(info_lensa);
        props.save();
    };

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Informasi Lensa</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={SaveandQuitIdentity}
                        >
                            selesai
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Tipe Lensa</label>
                            <input
                                type='text'
                                name='tipe_lensa'
                                className='form-control'
                                placeholder='Masukkan tipe lensa'
                                onChange={(e) => Setlens_type(e.target.value)}
                                value={lens_type}
                            />
                            <small className='form-text text-muted'>
                                Contoh: Single Vision, Bifokal, dan Lain-lain
                            </small>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Bahan Lensa</label>
                            <input
                                type='text'
                                name='bahan_lensa'
                                className='form-control'
                                placeholder='Masukkan bahan lensa'
                                onChange={(e) =>
                                    Setlens_material(e.target.value)
                                }
                                value={lens_material}
                            />
                            <small className='form-text text-muted'>
                                Contoh: Plastik, Kaca, dan Lain-lain
                            </small>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Warna Lensa</label>
                            <input
                                type='text'
                                name='warna_lensa'
                                className='form-control'
                                placeholder='Masukkan warna lensa'
                                onChange={(e) => Setlens_color(e.target.value)}
                                value={lens_color}
                            />
                            <small className='form-text text-muted'>
                                Contoh: Photochromic, Polarized, dan Lain-lain
                            </small>
                        </div>
                    </div>
                    <div className='col-md-6 matta-no-arrow'>
                        <label for='counter'>Jumlah Stok</label>
                        <div className='input-group mb-3' id='counter'>
                            <div class='input-group-prepend'>
                                <button
                                    className='btn btn-primary'
                                    type='button'
                                    onClick={() => Setstock(stock - 1)}
                                >
                                    {'-'}
                                </button>
                            </div>
                            <input
                                type='number'
                                id='stock'
                                name='stock'
                                className='form-control'
                                style={{textAlign: 'center'}}
                                min='0'
                                step='1'
                                max='999999999'
                                placeholder=''
                                value={stock}
                            />

                            <div class='input-group-append'>
                                <button
                                    className='btn btn-primary'
                                    type='button'
                                    onClick={() => Setstock(stock + 1)}
                                >
                                    {'+'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 matta-no-arrow'>
                        <label>Berat</label>
                        <input
                            type='number'
                            name='berat'
                            className='form-control'
                            min='0'
                            step='10'
                            max='999999999'
                            placeholder='berat'
                            value={beratVal}
                            onChange={(e) => Setberat(e.target.value)}
                        />
                    </div>
                    <div className='col-md-6 matta-no-arrow'>
                        <label>Panjang</label>
                        <input
                            type='number'
                            name='panjang'
                            className='form-control'
                            min='0'
                            step='10'
                            max='999999999'
                            placeholder='panjang'
                            value={panjangVal}
                            onChange={(e) => Setpanjang(e.target.value)}
                        />
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-6 matta-no-arrow'>
                        <label>Lebar</label>
                        <input
                            type='number'
                            name='lebar'
                            className='form-control'
                            min='0'
                            step='10'
                            max='999999999'
                            placeholder='lebar'
                            value={lebarVal}
                            onChange={(e) => Setlebar(e.target.value)}
                        />
                    </div>
                    <div className='col-md-6 matta-no-arrow'>
                        <label>Tinggi</label>
                        <input
                            type='number'
                            name='tinggi'
                            className='form-control'
                            min='0'
                            step='10'
                            max='999999999'
                            placeholder='tinggi'
                            value={tinggiVal}
                            onChange={(e) => Settinggi(e.target.value)}
                        />
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label>Description</label>
                            {description !== undefined && (
                                <Editor
                                    editorState={description}
                                    wrapperClassName='demo-wrapper'
                                    editorClassName='demo-editor border rounded p-3'
                                    onEditorStateChange={onEditorStateChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Ukuran_Lensa = (props) => {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Ukuran Lensa</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={props.edit}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>
                            <strong>Minus</strong>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Minimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.minus_min}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Maksimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.minus_max}</label>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>
                            <strong>Plus</strong>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Minimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.plus_min}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Maksimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.plus_max}</label>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>
                            <strong>Addition</strong>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Minimal Addition</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.addition_min}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Maksimal Addition</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.addition_max}</label>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>
                            <strong>Silinder</strong>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Minimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.silinder_min}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Maksimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.silinder_max}</label>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>
                            <strong>Axis Silinder</strong>
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Minimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.axis_cyl_min}</label>
                    </div>
                    <div className='col-md-2'>
                        <label>Maksimal</label>
                    </div>
                    <div className='col-md-4'>
                        <label>{props.data.axis_cyl_max}</label>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Ukuran_Lensa_Edit = (props) => {
    const [minmin, Setminmin] = useState(props.data.minus_min);
    const [minmax, Setminmax] = useState(props.data.minus_max);
    const [pumin, Setpumin] = useState(props.data.plus_min);
    const [pumax, Setpumax] = useState(props.data.plus_max);
    const [additionmin, Setadditionmin] = useState(props.data.addition_min);
    const [additionmax, Setadditionmax] = useState(props.data.addition_max);
    const [silmin, Setsilmin] = useState(props.data.silinder_min);
    const [silmax, Setsilmax] = useState(props.data.silinder_max);
    const [axiscylmin, Setaxiscylmin] = useState(props.data.axis_cyl_min);
    const [axiscylmax, Setaxiscylmax] = useState(props.data.axis_cyl_max);

    const saveQuitLens = () => {
        let ukuran_lensa = {
            minus_min: minmin,
            minus_max: minmax,
            plus_min: pumin,
            plus_max: pumax,
            silinder_min: silmin,
            silinder_max: silmax,
            addition_min: additionmin,
            addition_max: additionmax,
            axis_cyl_min: axiscylmin,
            axis_cyl_max: axiscylmax,
        };
        props.update(ukuran_lensa);
        props.save();
    };

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Ukuran Lensas</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-outline-info'
                            onClick={saveQuitLens}
                        >
                            selesai
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-body matta-no-arrow'>
                <form>
                    <div className='row'>
                        <div className='col'>
                            <h5>
                                <strong>Minus</strong>
                            </h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Minimal</label>
                            <input
                                type='number'
                                name='minus_min'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={minmin}
                                onChange={(e) => Setminmin(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Maksimal</label>
                            <input
                                type='number'
                                name='minus_max'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={minmax}
                                onChange={(e) => Setminmax(e.target.value)}
                            />
                        </div>
                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col'>
                            <h5>
                                <strong>Plus</strong>
                            </h5>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Minimal</label>
                            <input
                                type='number'
                                name='plus_min'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={pumin}
                                onChange={(e) => Setpumin(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Maksimal</label>
                            <input
                                type='number'
                                name='plus_max'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={pumax}
                                onChange={(e) => Setpumax(e.target.value)}
                            />
                        </div>
                    </div>
                    <br/>

                    <div className='row'>
                        <div className='col'>
                            <h5>
                                <strong>Addition</strong>
                            </h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Minimal Addition</label>
                            <input
                                type='number'
                                name='plus_addition_min'
                                className='form-control'
                                min='-10'
                                step='.01'
                                max='10'
                                value={additionmin}
                                onChange={(e) => Setadditionmin(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Maksimal Addition</label>
                            <input
                                type='number'
                                name='plus_addition_max'
                                className='form-control'
                                min='-10'
                                step='.01'
                                max='10'
                                value={additionmax}
                                onChange={(e) => Setadditionmax(e.target.value)}
                            />
                        </div>
                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col'>
                            <h5>
                                <strong>Silinder</strong>
                            </h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Minimal</label>
                            <input
                                type='number'
                                name='silinder_min'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={silmin}
                                onChange={(e) => Setsilmin(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Maksimal</label>
                            <input
                                type='number'
                                name='silinder_max'
                                className='form-control'
                                min='0'
                                step='.01'
                                max='10'
                                value={silmax}
                                onChange={(e) => Setsilmax(e.target.value)}
                            />
                        </div>
                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col'>
                            <h5>
                                <strong>Axis Silinder</strong>
                            </h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Minimal</label>
                            <input
                                type='number'
                                name='silinder_axis_min'
                                className='form-control'
                                min='0'
                                step='1'
                                max='360'
                                value={axiscylmin}
                                onChange={(e) => Setaxiscylmin(e.target.value)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Maksimal</label>
                            <input
                                type='number'
                                name='silinder_axis_max'
                                className='form-control'
                                min='0'
                                step='1'
                                max='360'
                                value={axiscylmax}
                                onChange={(e) => Setaxiscylmax(e.target.value)}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const Allin = (props) => {
    const [EditIdentitas, SetEditIdentitas] = useState(false);
    const [EditInformasi, SetEditInformasi] = useState(false);
    const [EditUkuran, SetEditUkuran] = useState(false);

    const [dataid, Setdataid] = useState(props.id_lensa);
    const [datainfo, Setdatainfo] = useState(props.info_lensa);
    const [dataukur, Setdataukur] = useState(props.ukuran_lensa);

    const editIdentitas = () => {
        SetEditIdentitas(true);
    };
    const saveIdentitas = () => {
        SetEditIdentitas(false);
    };

    const editInformasi = () => {
        SetEditInformasi(true);
    };
    const saveInformasi = () => {
        SetEditInformasi(false);
    };

    const editUkuran = () => {
        SetEditUkuran(true);
    };
    const saveUkuran = () => {
        SetEditUkuran(false);
    };

    const updateDataId = (newdata) => {
        Setdataid(newdata);
    };
    const updateDataInfo = (newdata) => {
        Setdatainfo(newdata);
    };
    const updateDataUkur = (newdata) => {
        Setdataukur(newdata);
    };

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');

    if (typeof dataid.files !== 'object') {
        let url = dataid.files;
        const filename = url.split('/');
        const strfilename = filename[filename.length - 1];
        const toDataURL = (url) =>
            fetch(url)
                .then((response) => response.blob())
                .then(
                    (blob) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        }),
                );

        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        }

        toDataURL(url).then((dataUrl) => {
            var fileData = dataURLtoFile(dataUrl, strfilename);
            dataid.files = fileData;
        });
    }

    const Simpan = () => {
        let data_tosave = {
            lens_id: dataid.lens_id,
            id_brand: dataid.id_brand,
            lens_name: dataid.lens_name,
            lens_brand: dataid.lens_brand,
            guarantee: dataid.guarantee,
            price_capital: dataid.price_capital,
            price: dataid.price,
            discount: dataid.discount,
            price_sale: dataid.price_sale,
            files: dataid.files,
            lens_type: datainfo.lens_type,
            lens_material: datainfo.lens_material,
            lens_color: datainfo.lens_color,
            stock: datainfo.stock,
            description: datainfo.description,
            minus_min: dataukur.minus_min,
            minus_max: dataukur.minus_max,
            plus_min: dataukur.plus_min,
            plus_max: dataukur.plus_max,
            silinder_min: dataukur.silinder_min,
            silinder_max: dataukur.silinder_max,
            addition_max: dataukur.addition_max,
            addition_min: dataukur.addition_min,
            axis_cyl_max: dataukur.axis_cyl_max,
            axis_cyl_min: dataukur.axis_cyl_min,
        };

        AuthService.editLensa(data_tosave).then((data) => {
            if (data.results) {
                setShow(true);
                setMessage(data.results);
            } else {
                setShow(true);
                setMessage(data.error.message);
            }
        });
    };

    const CloseModal = () => {
        setShow(true);
        props.redir();
    };

    return (
        <Fragment>
            <Breadcrumb title='Detail Lensa' parent='Produk / Lensa'/>
            <div className='container-fluid'>
                {EditIdentitas ? (
                    <Identitas_lensa_Edit
                        save={saveIdentitas}
                        data={dataid}
                        update={updateDataId}
                    />
                ) : (
                    <Idenstitas_lensa edit={editIdentitas} data={dataid}/>
                )}

                {EditInformasi ? (
                    <Informasi_product_edit
                        save={saveInformasi}
                        data={datainfo}
                        update={updateDataInfo}
                    />
                ) : (
                    <Informasi_product edit={editInformasi} data={datainfo}/>
                )}

                {EditUkuran ? (
                    <Ukuran_Lensa_Edit
                        save={saveUkuran}
                        data={dataukur}
                        update={updateDataUkur}
                    />
                ) : (
                    <Ukuran_Lensa edit={editUkuran} data={dataukur}/>
                )}
                <div className='d-flex bd-highlight mb-3'>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button
                            type='button'
                            className='btn btn-info'
                            onClick={Simpan}
                        >
                            Simpan perubahan
                        </button>
                    </div>
                </div>
            </div>
            <SuccessPOP show={show} close={CloseModal} message={message}/>
        </Fragment>
    );
};

export default class DetailLens extends Component {
    RedirToListLensa = () => {
        this.props.history.push(`${process.env.PUBLIC_URL}/lens`);
    };

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    render() {
        // const data = cookie.load("lens_detail");
        const data = JSON.parse(localStorage.getItem('lens_detail'));
        const filename = data.media_url.split('/');
        const strfilename = filename[filename.length - 1];
        const id_lensa = {
            id_brand: data.id_brand,
            lens_id: data.lens_id,
            lens_name: data.lens_name,
            lens_brand: data.lens_brand,
            guarantee: data.guarantee,
            price_capital: data.price_capital,
            price: data.price,
            discount: data.discount,
            price_sale: data.price_sale,
            media_url: strfilename,
            files: data.media_url,
        };
        const info_lensa = {
            lens_type: data.lens_type,
            lens_material: data.lens_material,
            lens_color: data.lens_color,
            stock: data.stock,
            description: data.description,
        };
        const ukuran_lensa = {
            minus_min: data.minus_min,
            minus_max: data.minus_max,
            plus_min: data.plus_min,
            plus_max: data.plus_max,
            silinder_min: data.silinder_min,
            silinder_max: data.silinder_max,
            addition_max: data.addition_max,
            addition_min: data.addition_min,
            axis_cyl_max: data.axis_cyl_max,
            axis_cyl_min: data.axis_cyl_min,
        };
        return (
            <Allin
                id_lensa={id_lensa}
                info_lensa={info_lensa}
                ukuran_lensa={ukuran_lensa}
                redir={this.RedirToListLensa}
            />
        );
    }
}
