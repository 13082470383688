import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import AuthService from '../api_getter/auth_service';
import PopAdmin from './pop_admin';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default class AddAdmin extends Component {
  constructor(props){
    super(props);

    this.state={
      admin_name:'',
      admin_fullname:'',
      email:'email',
      password:'',
      phone:null,
      admin_role:'',
      message:'',
      message_type:'',
      show_pop:false
    }
  }
  selectInput = (event) =>{
    this.setState({
      [event.target.name]:event.target.value
  });
  }
  selectOption = (value, actionMeta) =>{
    this.setState({
      [actionMeta.name]:value.value
    })
  }

  ConstAndSave = () =>{
    let data_tosend ={
      'admin_name':this.state.admin_name,
      'admin_fullname':this.state.admin_fullname,
      'email':this.state.email,
      'password':this.state.password,
      'phone':this.state.phone,
      'admin_role':this.state.admin_role
    }

    AuthService.addAdmin(data_tosend)
    .then((data)=>{
      if(data.results){
        this.setState({
          message:data.results,message_type:'alert-success',show_pop:true
        })
      }
      else{
        this.setState({
          message:data.error.message,message_type:'alert-danger',show_pop:true
        })
      }
    })
  }

  render() {
    const department = [
      { value: 'superadmin', label: 'Super Admin' },
      { value: 'admin_user', label: 'Manager Pengguna' },
      { value: 'admin_product', label: 'Manager Produk' },
      { value: 'admin_trx', label: 'Manager Transaksi' },
    ]
    return (
      <Fragment>
          <Breadcrumb title="Tambah Admin Baru" parent="Pengguna" />
          <div className='container-fluid'>

              <div className='card'>
                <div className='card-header'>
                    <h5>Data admin</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Nama Admin</label><br/>
                            <input type='text' name='admin_fullname' placeholder='masukkan nama admin' className='form-control' 
                            onChange={this.selectInput}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label>Email</label><br/>
                            <input type='email' name='email' placeholder='masukkan email' className='form-control'
                            onChange={this.selectInput} />
                        </div>

                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Username</label><br/>
                            <input type='text' name='admin_name' placeholder='masukkan user name' className='form-control'
                            onChange={this.selectInput} />
                        </div>
                        <div className='col-md-6'>
                            <label>Password</label><br/>
                            <input type='password' name='password' placeholder='masukkan password' className='form-control'
                            onChange={this.selectInput} />
                        </div>

                    </div>
                    <br/>
                    <div className='row'>
                        <div className='col-md-6 matta-no-arrow'>
                            <label>No. HP</label><br/>
                            <input type='number' name='phone' placeholder='masukkan nomor hp' className='form-control'
                            onChange={this.selectInput} />
                        </div>
                        <div className='col-md-6'>
                        </div>

                    </div>

                </div>

              </div>

              <div className='card'>
                <div className='card-header'>
                    <h5>Role Admin</h5>
                </div>

                <div className='card-body'>
                    <div className='row'>
                    <div className="col-md-6">
                        <label>Pilih Role</label>
                        <Select
                          components={animatedComponents}
                          options={department}
                          onChange={this.selectOption}
                          name="admin_role"
                          placeholder="Pilih disini"
                          />     
                      </div>
                    </div>
                   

                </div>
              </div>
            <button type='button' className='btn btn-primary'
            onClick={this.ConstAndSave}
            > Daftarkan Admin Baru</button>
          </div>
          <PopAdmin
          show={this.state.show_pop}
          close={()=>this.setState({show_pop:false})}
          message={this.state.message}
          message_type={this.state.message_type}
          />

      </Fragment>
    )
  }
}
