import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AuthService from '../../api_getter/auth_service';
import { MapPin, Edit } from 'react-feather';
import InputResi from './inputresi';
import Tracking from './tracking';

export default class ShippingChild extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logged_in: false,
			data_loaded: false,
			data_trx: {},
			data_row: {},
			message: '',
			message_type: '',
			show_input_resi: false,
			show_tracking: false,
			show_label: false,
			code: 0,
			showGlobal: false,
			trial_connect: 0,
		};
		this.data_all_ship = [];
		this.data_column = [
			{ dataField: 'shipping_id', hidden: true },
			{ dataField: 'invoice', text: 'Invoice' },
			{ dataField: 'created_date', text: 'Tanggal' },
			{ dataField: 'shipping_process', text: 'Status' },
			{ dataField: 'receipt', text: 'No Resi' },
			{
				dataField: 'actions',
				text: 'Aksi',
				formatter: this.ActionFormater,
			},
		];
	}
	shouldComponentUpdate(nextProps) {
		let PropsA = this.props.id;
		let PropsB = nextProps.event;

		if (PropsA === PropsB) {
			if (
				this.data_all_ship.length === 0 &&
				this.state.trial_connect <= 0
			) {
				this.setState({ trial_connect: this.state.trial_connect + 1 });
				this.loadDataTranship(PropsB);
			}
			return true;
		}
		return this.data_all_ship.length !== 0;
	}
	componentDidMount() {
		if (this.props.id === this.props.event) {
			this.loadDataTranship(this.props.id);
		}
	}

	ActionFormater = (cell, row) => {
		return (
			<div>
				{this.props.id === 'sending' && (
					<div>
						<a
							href='javascript:void(0)'
							onClick={() =>
								this.setState({
									show_tracking: true,
									data_row: row,
								})
							}
						>
							<MapPin color='grey' size='20' />
						</a>
					</div>
				)}
				&ensp;&ensp;
				{this.props.id === 'pending' && (
					<a
						href='javascript:void(0)'
						onClick={() =>
							this.setState({
								show_input_resi: true,
								data_row: row,
							})
						}
					>
						<Edit color='green' size='20' />
					</a>
				)}
			</div>
		);
	};
	reloadData = () => {
		this.loadDataTranship(this.props.id);
	};
	loadDataTranship = (sub) => {
		AuthService.getTranship(sub).then((data) => {
			if (data.results) {
				this.data_all_ship = data.results;
				this.setState({ data_loaded: true });
			} else {
				this.setState({
					message: data.error.message,
					message_type: 'alert-danger',
				});
			}
		});
	};
	inputresi_close = () => {
		this.setState({ show_input_resi: false });
	};

	tracking_close = () => {
		this.setState({ show_tracking: false });
	};

	label_close = () => {
		this.setState({ show_label: false });
	};

	render() {
		const { SearchBar, ClearSearchButton } = Search;
		const pagination = paginationFactory({
			page: 1,
			sizePerPage: 5,
			firstPageText: 'First',
			lastPageText: 'Last',
			nextPageText: 'Next',
			prePageText: 'Previous',
			showTotal: true,
			alwaysShowAllBtns: true,
			onPageChange: function (page, sizePerPage) {},
			onSizePerPageChange: function (page, sizePerPage) {},
		});
		return (
			<Fragment>
				<ToolkitProvider
					bootstrap4
					keyField='shipping_id'
					data={this.data_all_ship}
					columns={this.data_column}
					search
				>
					{(props) => (
						<div>
							<SearchBar {...props.searchProps} />
							<ClearSearchButton {...props.searchProps} />

							<BootstrapTable
								id='shipping_table'
								striped
								hover
								condensed
								pagination={pagination}
								{...props.baseProps}
								classes='table table-borderless'
							/>
						</div>
					)}
				</ToolkitProvider>
				<InputResi
					show={this.state.show_input_resi}
					kurir={this.props.shiperout}
					close={this.inputresi_close}
					data={this.state.data_row}
					reload={this.reloadData}
				/>
				<Tracking
					show={this.state.show_tracking}
					close={this.tracking_close}
					data={this.state.data_row}
				/>
			</Fragment>
		);
	}
}
