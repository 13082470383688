import DeleteNotification from './delete-notification';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React, { Component, Fragment } from 'react';
import AuthService from '../../api_getter/auth_service';
import Breadcrumb from '../../common/breadcrumb';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cookie from 'react-cookies';
import { ExternalLink, Edit2, Trash2 } from 'react-feather';

export default class AllNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data_loaded: false,
            data_all_loyalty: [],
            message: '',
            message_type: '',
            showEdit: false,
            showHapus: false,
            data_row: {},
            page: 1,
            limit: 10,
            total_data: 0,
            q: '',
            searchTimeout: undefined
        };
        this.data_column = [
            { dataField: 'id', hidden: 'true' },
            { dataField: 'name', text: 'Nama Notifikasi' },
            {
                dataField: 'campaign',
                text: 'Nama Campaign',
                formatter: this.Campaign,
            },
            {
                dataField: 'status',
                text: 'Status Notifikasi',
                formatter: this.Status,
            },
            {
                dataField: 'actions',
                text: 'Aksi',
                formatter: this.ActionFormater,
            },
        ];
        this.Style = {
            image: {
                background: '#fefefe',
                height: 25,
                width: 25,
            },
        };
    }

    Campaign = (cell, row) => {
        return <div>{row.product[0]?.name}</div>;
    };

    Status = (cell, row) => {
        //1= success, 0 = draft, 2 = in-progress, 3=failed
        if (row.status.value === 0)
            return (
                <small className="border rounded m-2 p-1 fs-6 border-primary text-primary">
                    Success
                </small>
            );
        // if (row.status.value === 2)
        //     return (
        //         <small className="border rounded m-2 p-1 fs-6 border-success text-success">
        //             In-progress
        //         </small>
        //     );
        if (row.status.value === 2)
            return (
                <small className="border rounded m-2 p-1 fs-6 border-error text-error">
                    Failed
                </small>
            );
        if (row.status.value === 1)
            return (
                <small className="border rounded m-2 p-1 fs-6 border-secondary text-secondary">
                    Draft
                </small>
            );
        return '';
    };

    componentDidMount() {
        this.loadAllNotification();
    }

    loadAllNotification = (reset) => {
        if (reset) {
            this.setState({page:1})
        }
        this.state.data_loaded = false;
        AuthService.getAllNotification({
            page: this.state.page,
            limit: this.state.limit,
            q: this.state.q
        }).then((res) => {
            if (res.success) {
                this.setState({
                    data_all_loyalty: res.data.data,
                    data_loaded: true,
                    total_data: res.data.total,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    goToDetail = (row) => {
        localStorage.setItem('list_loyalty', JSON.stringify(row));
        cookie.save('list_loyalty', row, { path: '/' });
        this.props.history.push(`${process.env.PUBLIC_URL}/loyalty`);
    };

    ActionFormater = (cell, row) => {
        return (
            <div>
                <Link to={`/notification/edit/${row.id}`}>
                    <Edit2 color="green" size="20" />
                </Link>
                {/*&ensp;&ensp;*/}
                {/*<a*/}
                {/*    href="javascript:void(0)"*/}
                {/*    onClick={() => this.setState({showHapus: true, data_row: row})}*/}
                {/*>*/}
                {/*    <Trash2 color="red" size="20"/>*/}
                {/*</a>*/}
            </div>
        );
    };

    closeEditStatus = () => {
        this.setState({ showEdit: false });
    };
    handlePage = (limit, page) => {
        this.setState({
            page: page,
            limit: limit,
        });
        setTimeout(() => {
            this.loadAllNotification();
        }, 3e2);
    };

    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const vm = this;
        const options = {
            page: this.state.page,
            sizePerPage: this.state.limit,
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            showTotal: true,
            totalSize: this.state.total_data,
            alwaysShowAllBtns: true,
            onPageChange: function (page, limit) {
                vm.handlePage(limit, page);
            },
            onSizePerPageChange: function (limit, page) {
                vm.handlePage(limit, page);
            },
        };
        const pagination = paginationFactory(options);
        const handleChanged = (e) => {
            console.log(e.target);
            this.setState({ q: e.target.value });
            clearTimeout(this.state.searchTimeout);
            this.setState({ searchTimeout: undefined });
            this.setState({
                searchTimeout: setTimeout(() => {
                    this.loadAllNotification(true);
                }, 3e2),
            });
        };
        const clearSearch = (e) => {
            this.setState({ q: '' });
            clearTimeout(this.state.searchTimeout);
            this.setState({ searchTimeout: undefined });
            this.setState({
                searchTimeout: setTimeout(() => {
                    this.loadAllNotification(true);
                }, 3e2),
            });
        };

        return (
            <Fragment>
                <Breadcrumb title="Notifikasi" parent="Campaign" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <div>
                                <h5>Daftar Notifikasi</h5>
                                <p className="mt-2">
                                    Pengaturan notifikasi berdasarkan campaign
                                    yang aktif{' '}
                                </p>
                            </div>
                            <div>
                                <Link to="/notification/add">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Tambah Notifikasi
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.q}
                                    onChange={handleChanged}
                                    placeholder="Search"
                                    style={{ width: 'auto' }}
                                />
                                <button
                                    className="btn btn-default"
                                    onClick={clearSearch}
                                >
                                    CLEAR
                                </button>
                            </div>
                            <ToolkitProvider
                                bootstrap4
                                keyField="id"
                                data={this.state.data_all_loyalty}
                                columns={this.data_column}
                            >
                                {(props) => (
                                    <div>
                                        <BootstrapTable
                                            id="user_table"
                                            striped
                                            hover
                                            condensed
                                            pagination={pagination}
                                            remote
                                            onTableChange={() => {}}
                                            {...props.baseProps}
                                            classes="table table-borderless"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>

                <DeleteNotification
                    show={this.state.showHapus}
                    close={() => this.setState({ showHapus: false })}
                    id={this.state.data_row.id}
                    product={this.state.data_row?.name}
                    reload={() => {
                        this.loadAllNotification(true);
                    }}
                />
            </Fragment>
        );
    }
}
