import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../../api_getter/auth_service';

export default class Confirm extends Component {

  changeShiper = () =>{
      let shiper = this.props.id;
      let status = this.props.change
      
      AuthService.updateShiper(shiper,status)
      .then(
        (data) => {
          if (data.results){
            this.props.close()
            this.props.reload()
          }
          else{
            console.log(data)
          } 
        }
      )
  }
    
  render() {
      let change = this.props.change ? 'mengaktifkan ':'menonaktifkan '
      let id = this.props.id
    return (
      <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          centered
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Peringatan</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          <div className='d-flex justify-content-center'>
            <p>Apakah anda yakin {change} {id} ?</p>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-outline-danger' onClick={this.props.close}>
                TIDAK
            </button>
            <button className='btn btn-outline-success' onClick={this.changeShiper}>
                IYA
            </button>
          </Modal.Footer>
        </Modal>
    )
  }
}