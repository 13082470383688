import PopAdmin from '../../../components/loyalty/pop_admin';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import React, {Component, Fragment} from 'react';
import AuthService from '../../api_getter/auth_service';
import Breadcrumb from '../../common/breadcrumb';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cookie from 'react-cookies';
import {ExternalLink, Edit2, Trash2, Upload} from 'react-feather';

export default class AllLoyalty extends Component {
    constructor(props) {
        super(props);

        this.gambarRef = React.createRef();
        this.inputRef = React.createRef();
        this.state = {
            payload: {},
            products: [],
            lens_id: [],
            logged_in: false,
            data_loaded: false,
            data_user: {},
            data_row: {},
            message: '',
            message_type: '',
            showEdit: false,
            showHapus: false,
        };
        this.data_all_product = [];
        this.data_column = [
            {dataField: 'kacamata_id', text: 'ID', hidden: 'true'},
            {dataField: 'name', text: 'Nama'},
            {dataField: 'brand_name', text: 'Merek'},
            {dataField: 'stock', text: 'Stok'},
            {dataField: 'status', text: 'Status'},
            {
                dataField: 'actions',
                text: 'Aksi',
                formatter: this.ActionFormater,
            },
        ];
        this.Style = {
            image: {
                background: '#fefefe',
                height: 25,
                width: 25,
            },
        };
        this.products = [];
        this.generateCode();
        this.getProduct();
    }

    componentDidMount() {
        this.loadAllProduct();
    }

    handleQuit = () => {
        if (this.state.success)
            this.props.history.push(`${process.env.PUBLIC_URL}/all-campaign`);
    };

    loadAllProduct = () => {
        AuthService.getAllLoyalty().then((data) => {
            if (data.results) {
                this.data_all_product = data.results;
                this.setState({data_loaded: true});
            } else {
                this.setState({
                    message: data.message,
                    message_type: 'alert-danger',
                });
            }
        });

        AuthService.getAllLens().then((res) => {
            if (res) {
                let payload = [];
                res.results.map((item) => {
                    payload.push({
                        value: item.lens_id,
                        label: item.lens_name,
                    });
                });
                this.setState({
                    list_lens: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };
    goToDetail = (row) => {
        localStorage.setItem('list_loyalty', JSON.stringify(row));
        cookie.save('list_loyalty', row, {path: '/'});
        this.props.history.push(`${process.env.PUBLIC_URL}/loyalty`);
    };

    ActionFormater = (cell, row) => {
        return (
            <div>
                <a href="javascript:void(0)" onClick={() => this.goToDetail(row)}>
                    <ExternalLink size="20"/>
                </a>
                &ensp;&ensp;
                <a
                    href="javascript:void(0)"
                    onClick={() => this.setState({showEdit: true, data_row: row})}
                >
                    <Edit2 color="green" size="20"/>
                </a>
                &ensp;&ensp;
                <a
                    href="javascript:void(0)"
                    onClick={() => this.setState({showHapus: true, data_row: row})}
                >
                    <Trash2 color="red" size="20"/>
                </a>
            </div>
        );
    };

    openFile = () => {
        this.inputRef.current.click();
    };

    backgroundFile = () => {
        if (this.state.payload.file)
            return URL.createObjectURL(this.state.payload.file);
        return '';
    };

    deleteImage = () => {
        const payload = this.state.payload;
        delete payload.file;
        this.setState({payload});
    };

    handleInputChanged = (e) => {
        const input = this.inputRef.current;
        const files = e.target.files;
        const payload = this.state.payload;
        payload.file = files[0];
        this.setState({payload});
        input.type = 'text';
        setTimeout(() => {
            input.type = 'file';
        }, 3e2);
    };

    newProduct = () => {
        this.props.history.push('/new-product');
    };

    closeEditStatus = () => {
        this.setState({showEdit: false});
    };

    onInput = (e) => {
        const target = e.target;
        const payload = this.state.payload;
        payload[target.name] = target.value;
        this.setState({payload});
    };

    generateCode = () => {
        AuthService.generateCampaignCode().then((res) => {
            const payload = this.state.payload;
            payload.code = res.data.code;
            this.setState({payload});
        });
    };

    getProduct = () => {
        AuthService.getCampaignProduct().then((res) => {
            const products = res.data.map((v) => ({
                value: v.id,
                label: v.name,
            }));

            this.setState({products});
        });
    };

    handleSelectProduct = (e) => {
        const payload = this.state.payload;
        const ids = e.map((v) => v.value);
        payload.product_id = ids;
        this.setState({payload});
    };

    handleSelectLens = (e) => {
        // const payload = event.map((id) => id.value);
        const payload = this.state.payload;
        const ids = e.map((v) => v.value);
        payload.lens_id = ids;
        this.setState({
            payload
        });
    };

    handleSave = async () => {
        const {data: image} = await AuthService.uploadImageCampaign(
            this.state.payload.file
        );
        const state = this.state.payload;
        const payload = {
            name: state.name,
            code: state.code,
            start_date: state.start_date,
            end_date: state.end_date,
            start_time: state.start_time,
            end_time: state.end_time,
            product_id: state.product_id,
            lens_id: state.lens_id,
            type_promo: state.type_promo, //1 = percentage, 2 = rupiah
            value_type_promo: state[`value_promo_${state.type_promo}`],
            type_limit_promo: state.type_limit_promo, //1 = per product, 2 = max promo code redemeed
            value_type_limit_promo: state[`value_limit_${state.type_limit_promo}`],
            image_url: image.full_image_url,
            status: state.status,
        };
        console.log(payload)
        AuthService.addCampaign(payload)
            .then((res) => {
                let message
                if (res.success) {
                    if (parseInt(payload.status) === 1) {
                        message = "Campaign telah diaktifkan"
                    } else if (parseInt(payload.status) === 2) {
                        message = "Campaign disimpan sebagai draft"
                    } else if (parseInt(payload.status) === 0) {
                        message = "Campaign telah dinonaktifkan"
                    }
                    this.setState({
                        success: true,
                        message: message,
                        message_type: 'alert-success',
                        show_pop: true,
                    });
                } else {
                    throw new Error(res.message);
                }
            })
            .catch((res) => {
                let message = "Data harus dilengkapi";
                if (res.response?.data?.message) message = res.response.data.message;
                this.setState({
                    success: false,
                    message: message,
                    message_type: 'alert-danger',
                    show_pop: true,
                });
            });
    };

    render() {
        const animatedComponents = makeAnimated();
        const {SearchBar, ClearSearchButton} = Search;
        const listLens = this.state.list_lens;
        const pagination = paginationFactory({
            page: 1,
            sizePerPage: 5,
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            showTotal: true,
            alwaysShowAllBtns: true,
            onPageChange: function (page, sizePerPage) {
            },
            onSizePerPageChange: function (page, sizePerPage) {
            },
        });

        const listProduct = this.state.products;

        const ImageNav = (props) => {
            if (!this.state.payload.file) return '';
            return (
                <div style={{color: '#FF5C00'}}>
          <span
              className="mr-2"
              onClick={this.openFile}
              style={{cursor: 'pointer'}}
          >
            Edit
          </span>
                    <span onClick={this.deleteImage} style={{cursor: 'pointer'}}>
            Hapus
          </span>
                </div>
            );
        };

        const InputStatus = () => {
            const status = [
                {label: 'Aktif', value: 1},
                {label: 'Draft', value: 2},
                {label: 'Tidak Aktif', value: 0},
            ];
            return status.map((v, i) => {
                return (
                    <Form.Check
                        inline
                        key={v.value}
                        id={`status-${i}`}
                        type="radio"
                        name="status"
                        value={v.value}
                        label={v.label}
                        onChange={this.onInput}
                        checked={this.state.payload.status == v.value}
                    />
                );
            });
        };

        return (
            <Fragment>
                <Breadcrumb title="NEW CAMPAIGN" parent="Loyalty"/>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Informasi campaign</h5>
                        </div>
                        <div className="card-body">
                            <div className="row align-items-stretch">
                                <div className="col-md-6">
                                    <div>
                                        <label>Nama Campaign</label>
                                        <br/>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="masukkan nama level"
                                            className="form-control"
                                            onChange={this.onInput}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Campaign code (auto generated)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={this.state.payload.code}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Mulai campaign</label>
                                        <br/>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    className="form-control"
                                                    onChange={this.onInput}
                                                />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <input
                                                    type="time"
                                                    name="start_time"
                                                    className="form-control"
                                                    onChange={this.onInput}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Selesai campaign</label>
                                        <br/>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    className="form-control"
                                                    onChange={this.onInput}
                                                />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <input
                                                    type="time"
                                                    name="end_time"
                                                    className="form-control"
                                                    onChange={this.onInput}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Cari Produk</label>
                                        <br/>
                                        <Select
                                            components={animatedComponents}
                                            isMulti
                                            options={listProduct}
                                            onChange={this.handleSelectProduct}
                                            name="frame_id"
                                            placeholder="Pilih disini"
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Cari Lensa</label>
                                        <br/>
                                        <Select
                                            components={animatedComponents}
                                            isMulti
                                            options={listLens}
                                            onChange={this.handleSelectLens}
                                            name='lens_id'
                                            placeholder='Pilih disini'
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Buat kode promo</label>
                                        <br/>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="input-group align-items-center mb-3">
                                                    <input
                                                        className="mr-2"
                                                        type="radio"
                                                        name="type_promo"
                                                        value={1}
                                                        onChange={this.onInput}
                                                        checked={this.state.payload.type_promo == 1}
                                                    />
                                                    <input
                                                        type="number"
                                                        name="value_promo_1"
                                                        className="form-control"
                                                        onChange={this.onInput}
                                                        min="0"
                                                        step="1"
                                                        placeholder="Diskon"
                                                        max="100"
                                                        disabled={this.state.payload.type_promo != 1}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="input-group align-items-center mb-3">
                                                    <input
                                                        className="mr-2"
                                                        type="radio"
                                                        name="type_promo"
                                                        value={2}
                                                        onChange={this.onInput}
                                                        checked={this.state.payload.type_promo == 2}
                                                    />
                                                    <input
                                                        type="number"
                                                        name="value_promo_2"
                                                        className="form-control"
                                                        onChange={this.onInput}
                                                        min="0"
                                                        step="1000"
                                                        placeholder="Harga tetap"
                                                        disabled={this.state.payload.type_promo != 2}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">Rp</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Limitasi kode promo</label>
                                        <br/>
                                        <div className="input-group align-items-center mb-3">
                                            <input
                                                className="mr-2"
                                                type="radio"
                                                name="type_limit_promo"
                                                value={1}
                                                onChange={this.onInput}
                                                checked={this.state.payload.type_limit_promo == 1}
                                            />
                                            <input
                                                type="number"
                                                name="value_limit_1"
                                                className="form-control"
                                                onChange={this.onInput}
                                                min="0"
                                                step="1"
                                                placeholder="Per Produk"
                                                max="100"
                                                disabled={this.state.payload.type_limit_promo != 1}
                                            />
                                        </div>
                                        <div className="input-group align-items-center mb-3">
                                            <input
                                                className="mr-2"
                                                type="radio"
                                                name="type_limit_promo"
                                                value={2}
                                                onChange={this.onInput}
                                                checked={this.state.payload.type_limit_promo == 2}
                                            />
                                            <input
                                                type="number"
                                                name="value_limit_2"
                                                className="form-control"
                                                onChange={this.onInput}
                                                min="0"
                                                step="1"
                                                placeholder="Max promo code redeemed"
                                                max="100"
                                                disabled={this.state.payload.type_limit_promo != 2}
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Status</label>
                                        <br/>
                                        <InputStatus/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between">
                                        <label>Upload Gambar Campaign</label>
                                        <ImageNav/>
                                    </div>
                                    <div
                                        className="border rounded w-100 d-flex justify-content-center align-items-center"
                                        ref={this.gambarRef}
                                        onClick={this.openFile}
                                        style={{
                                            backgroundImage: `url(${this.backgroundFile()})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '400px',
                                        }}
                                    >
                                        {!this.state.payload.file
                                            ? (
                                                <>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '2px'
                                                    }}>
                                                        <label htmlFor="file-input"><i><Upload/></i></label>
                                                        <br/><small>Ukuran file maksimal 20MB</small>
                                                    </div>
                                                </>
                                            )
                                            : !this.state.payload.name || (
                                            <span
                                                style={{
                                                    color: 'white',
                                                    fontWeight: '900',
                                                    textShadow: '0 0 5px black',
                                                    fontSize: '24px',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                            #{this.state.payload.name}
                          </span>
                                        )}
                                    </div>
                                    <input
                                        className="d-none"
                                        type="file"
                                        ref={this.inputRef}
                                        accept="image/*"
                                        onChange={this.handleInputChanged}
                                    />
                                </div>
                            </div>
                            <br/>
                            <button
                                type="button"
                                className="btn btn-primary mr-4"
                                onClick={this.handleSave}
                            >
                                Simpan
                            </button>
                            <Link to="/all-campaign">
                                <button type="button" className="btn btn-secondary">
                                    Batal
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <PopAdmin
                    show={this.state.show_pop}
                    close={() => this.setState({show_pop: false})}
                    message={this.state.message}
                    message_type={this.state.message_type}
                    handleQuit={this.handleQuit}
                />
            </Fragment>
        );
    }
}
