import React, { Component, Fragment } from 'react';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Breadcrumb from '../common/breadcrumb';
import {ExternalLink} from 'react-feather';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import PopGlobal from '../error/response_global';

export default class List_admin extends Component {
    constructor(props){
        super(props);

        this.state={
            logged_in:false,
            data_loaded:false,
            data_user:{},
            data_row:{},
            message:'',
            message_type:'',
            code:0,
            show:false
        }
        this.data_all_user = []
        this.data_column=[
        {dataField:'admin_id',hidden:true},
        {dataField:'admin_fullname',text:'Nama Lengkap'},
        {dataField:'email',text:'Email'},
        {dataField:'status',text:'Status',formatter:this.statusFormater},
        {dataField:'admin_role',text:'Role'},
        {dataField:'actions',text:'Aksi',formatter:this.ActionFormater}
        ]
        this.Style = {
          image: {
            background: '#fefefe',
            height:25,
            width:25
          }
        }
    }
    componentDidMount(){
        this.LoadUserData();
    }

    LoadUserData = () => {
      AuthService.getListAdmin().then(
        (data) => {
          if (data.results){
            
            this.data_all_user = data.results;
            this.setState({data_loaded:true});
          }
          else{
            this.setState({
              message:data.error.message,
              message_type:'alert-danger',
              code:data.error.code,
              show:true
            })
          }
      
         
        } 
      )
    }

    goToDashboard =()=>{
      
      if (this.state.code===307){
          this.setState({
          message:'',
          message_type:'',
          data:{},
          data_loaded:false,
          show:false 
          })
          this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`)
      }
      else{
        this.setState({
          message:'',
          message_type:'',
          data:{},
          data_loaded:false,
          show:false 
        })
      }

  }

    statusFormater = (cell,row) =>{
      let status=''
      if (row.status_admin===1){
        status='Active'
      }
      else if(row.status_admin===0){
        status='Inactive'
      }
      return(
          status
        
      )
    }

    goToDetail = (row) => {
      cookie.save('admin_data_row', row, { path: '/' });
      this.props.history.push(`${process.env.PUBLIC_URL}/users/detail-admin`);
    }

    ActionFormater = (cell,row) => {
      return(
        <div>
          {/* <Link to={{
                    pathname:`/users/detail-admin`,
                    state:{row}
                    }}>
            <ExternalLink />
          </Link>
          */}
          <a href="javascript:void(0)" onClick={()=>this.goToDetail(row)}>
            <ExternalLink size='20'/>
          </a>
        </div>
      )
    }

    newAdmin = () =>
    {
        this.props.history.push("/users/create-admin")    
    }

  render() {
    const { SearchBar, ClearSearchButton } = Search;

    

    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {

      },
      onSizePerPageChange: function (page, sizePerPage) {
      }
    });

    return (
      <Fragment>
        <Breadcrumb title="Admin" parent="Pengguna" />
        <div className="container-fluid">
            <div className='card'>
              <div className='card-header'>
                <h5>Daftar Admin</h5>
                <div className="float-sm-right">
                    <button type="button" className="btn btn-primary" onClick={this.newAdmin}>Tambah Admin</button>
                </div>
              </div>

              <div className='card-body'>  
              <ToolkitProvider
              bootstrap4
              keyField='admin_id'
              data={this.data_all_user}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      
                      
                      <BootstrapTable
                      id = 'admin_table'
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              
            </div>
            </div>
            </div>
            <PopGlobal show={this.state.show} close={this.goToDashboard} message={this.state.message}
            message_type={this.state.message_type}
            />
      </Fragment>
    )
  }
}
