import React, {Component, Fragment} from 'react';
import AuthService from '../api_getter/auth_service';
import Breadcrumb from '../common/breadcrumb';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PopAdmin from './pop_admin';

export default class AllLoyalty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            minimum_point: null,
            maximum_point: null,
            point_ratio: null,
            reward_id: [],
            list_reward: [],
            message: '',
            message_type: '',
            show_pop: false,
        };
    }

    changeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    selectOption = (event) => {
        const payload = event.map((id) => id.value);
        this.setState({
            reward_id: payload,
        });
    };

    loadAllReward = () => {
        AuthService.getAllReward({page: 1, limit: 100}).then((res) => {
            if (res.success) {
                let payload = [];
                res.data.data.map((item) => {
                    payload.push({value: item.id, label: item.title});
                });
                this.setState({
                    list_reward: payload,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    addLevel = () => {
        let payload = {
            name: this.state.name,
            minimum_point: parseInt(this.state.minimum_point),
            maximum_point: parseInt(this.state.maximum_point),
            point_ratio: parseInt(this.state.point_ratio),
            reward_id: this.state.reward_id,
        };
        AuthService.createLoyalty(payload).then((res) => {
            if (res.success) {
                this.setState({
                    message: res.message,
                    message_type: 'alert-success',
                    show_pop: true,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                    show_pop: true,
                });
            }
        });
    };

    componentDidMount() {
        this.loadAllReward();
    }

    render() {
        const animatedComponents = makeAnimated();
        const listReward = this.state.list_reward;
        return (
            <Fragment>
                <Breadcrumb title='TAMBAH LEVEL' parent='Loyalty'/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5>Level Info</h5>
                        </div>
                        <div className='card-body'>
                            <div className='row align-items-stretch'>
                                <div className='col-md-6'>
                                    <div>
                                        <label>Pilih Level</label>
                                        <br/>
                                        <input
                                            type='text'
                                            name='name'
                                            placeholder='Nama Level'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Batasan Poin</label>
                                        <div className='d-flex'>
                                            <input
                                                type='text'
                                                name='minimum_point'
                                                placeholder='Minimal Poin'
                                                className='form-control mr-1'
                                                onChange={this.changeInput}
                                            />
                                            <input
                                                type='text'
                                                name='maximum_point'
                                                placeholder='Maksimal Poin'
                                                className='form-control ml-1'
                                                onChange={this.changeInput}
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <label>Point Ratio</label>
                                        <input
                                            type='text'
                                            name='point_ratio'
                                            placeholder='Masukkan Ratio'
                                            className='form-control'
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <label>Reward</label>
                                    <br/>
                                    <Select
                                        components={animatedComponents}
                                        isMulti
                                        options={listReward}
                                        onChange={this.selectOption}
                                        name='reward_id'
                                        placeholder='Pilih Reward Bisa Lebih Dari 1'
                                    />
                                </div>
                            </div>
                            <br/>
                            <button
                                type='button'
                                className='btn btn-primary mt-'
                                onClick={this.addLevel}
                            >
                                Tambahkan Level
                            </button>
                        </div>
                    </div>
                </div>
                <PopAdmin
                    show={this.state.show_pop}
                    close={() => this.setState({show_pop: false})}
                    message={this.state.message}
                    message_type={this.state.message_type}
                />
            </Fragment>
        );
    }
}
