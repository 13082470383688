import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';


class SuccessPOPLensa extends Component {
  quit = () =>{
    this.props.close()
    this.props.history.push(`${process.env.PUBLIC_URL}/lens`);
  }
  render() {
    return (
      <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h4>{this.props.message}</h4>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={this.quit}>
                OKE
            </button>
          </Modal.Footer>
        </Modal>
    )
  }
}

export default withRouter(SuccessPOPLensa);
