import React, { Component, Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Clipboard, MapPin, Edit, Trash2 } from "react-feather";
import Switch from "react-switch";
import AuthService from "../../api_getter/auth_service";
import Confirm from "./confirm_pop";
// import InputResi from './inputresi';
// import Tracking from './tracking';
import PopGlobal from "../../error/response_global";
import TabShipping from "./all_shipping";

export default class AllShipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_in: false,
      data_loaded: false,
      data_trx: {},
      data_row: {},
      message: "",
      message_type: "",
      JNE: false,
      TIKI: false,
      POS: false,
      JNT: false,
      confirm_change: null,
      confirm_id: "",
      confirm_show: false,
      show_input_resi: false,
      show_tracking: false,
      code: 0,
      showGlobal: false,
    };
    this.Style = {
      image: {
        background: "#f8f8f9",
        height: 50,
        width: 50,
      },
    };

    // this.data_all_ship = []
    // this.data_column=[
    // {dataField:'shipping_id',hidden:true},
    // {dataField:'invoice',text:'Invoice'},
    // {dataField:'created_date',text:'Tanggal'},
    // {dataField:'shipping_process',text:'Status'},
    // {dataField:'receipt',text:'No Resi'},
    // {dataField:'actions',text:'Actions',formatter:this.ActionFormater}
    // ]
    this.shiper = [];
    this.shiperout = [];
  }
  // ActionFormater = (cell,row) => {
  //     return(
  //         <div >
  //         <a href='javascript:void(0)' onClick={() => this.setState({show_tracking:true,data_row:row})}><MapPin color='grey' size='20'/></a>
  //         &ensp;&ensp;
  //         <a href='javascript:void(0)' onClick={() => this.setState({show_input_resi:true,data_row:row})}><Edit color='green' size='20'/></a>
  //         &ensp;&ensp;
  //         {/* <a href='javascript:void(0)' ><Trash2 color='red' size='20'/></a> */}
  //       </div>
  //     )
  //   }

  componentDidMount() {
    this.loadData();
    //this.loadDataTranship()
  }

  loadData = () => {
    AuthService.getShipping().then((data) => {
      if (data.results) {
        this.shiper = data.results;
        this.formatData();
      } else {
        this.setState({
          message: data.error.message,
          message_type: "alert-danger",
          code: data.error.code,
          showGlobal: true,
        });
      }
    });
  };

  // loadDataTranship = () =>{
  //   AuthService.getTranship()
  //   .then(
  //     (data) => {
  //       if (data.results){
  //         this.data_all_ship=data.results;
  //         this.setState({data_loaded:true})

  //       }
  //       else{
  //         this.setState({message:data.error.message,message_type:'alert-danger'})

  //       }

  //     }
  //   )
  // }

  goToDashboard = () => {
    if (this.state.code === 307) {
      this.setState({
        message: "",
        message_type: "",
        data: {},
        data_loaded: false,
        showGlobal: false,
      });
      this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } else {
      this.setState({
        message: "",
        message_type: "",
        data: {},
        data_loaded: false,
        showGlobal: false,
      });
    }
  };

  formatData = () => {
    const data = this.shiper;
    let active = null;
    data.forEach((element) => {
      active = element.active_flag === 1 ? true : false;
      this.setState({
        [element.ship_name]: active,
      });
      let obj = {
        value: element.ship_name.toLowerCase(),
        label: element.ship_name,
      };
      this.shiperout.push(obj);
    });
  };

  changeShiper = (c, e, id) => {
    this.setState({
      confirm_show: true,
      confirm_id: id,
      confirm_change: c,
    });
  };
  confirm_close = () => {
    this.setState({ confirm_show: false });
  };

  // inputresi_close = () =>{
  //   this.setState({show_input_resi:false})
  // }

  // tracking_close = () =>{
  //   this.setState({show_tracking:false})
  // }

  render() {
    // const { SearchBar, ClearSearchButton } = Search;
    // const pagination = paginationFactory({
    //   page: 1,
    //   sizePerPage: 5,
    //   withFirstAndLast: false,
    //   nextPageText: 'Next',
    //   prePageText: 'Previous',
    //   showTotal: true,
    //   alwaysShowAllBtns: true,
    //   onPageChange: function (page, sizePerPage) {

    //   },
    //   onSizePerPageChange: function (page, sizePerPage) {
    //   }
    // });

    return (
      <Fragment>
        <Breadcrumb title="Pengiriman" parent="Transaksi" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Pengaturan Pengiriman</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex">
                    <img
                      src={process.env.PUBLIC_URL + "/ic-JNE.png"}
                      className="rounded-circle"
                      style={this.Style.image}
                      alt="loading"
                    />
                    <div className="p-2">JNE</div>
                    <div className="ml-auto p-2">
                      <Switch
                        checked={this.state.JNE}
                        onChange={this.changeShiper}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={15}
                        uncheckedIcon={true}
                        checkedIcon={true}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={10}
                        width={24}
                        className="react-switch"
                        id="JNE"
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex">
                    <img
                      src={process.env.PUBLIC_URL + "/ic-tiki.png"}
                      className="rounded-circle"
                      style={this.Style.image}
                      alt="loading"
                    />
                    <div className="p-2">TIKI</div>
                    <div className="ml-auto p-2">
                      <Switch
                        checked={this.state.TIKI}
                        onChange={this.changeShiper}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={15}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={10}
                        width={24}
                        className="react-switch"
                        id="TIKI"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ width: 100 + "%" }} />
              <div className="row">
                <div className="col">
                  <div className="d-flex">
                    <img
                      src={process.env.PUBLIC_URL + "/ic-jnt.png"}
                      className="rounded-circle"
                      style={this.Style.image}
                      alt="loading"
                    />
                    <div className="p-2">{"J&T"}</div>
                    <div className="ml-auto p-2">
                      <Switch
                        checked={this.state.JNT}
                        onChange={this.changeShiper}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={15}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={10}
                        width={24}
                        className="react-switch"
                        id="JNT"
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex">
                    <img
                      src={process.env.PUBLIC_URL + "/ic-pos.png"}
                      className="rounded-circle"
                      style={this.Style.image}
                      alt="loading"
                    />
                    <div className="p-2">POS</div>
                    <div className="ml-auto p-2">
                      <Switch
                        checked={this.state.POS}
                        onChange={this.changeShiper}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={15}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={10}
                        width={24}
                        className="react-switch"
                        id="POS"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ width: 100 + "%" }} />
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5>Status Pengiriman</h5>
            </div>
            <div className="card-body">
              <TabShipping shiperout={this.shiperout} />
              {/* <ToolkitProvider
              bootstrap4
              keyField='shipping_id'
              data={this.data_all_ship}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      
                      
                      <BootstrapTable
                      id = 'shipping_table'
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider> */}
            </div>
          </div>
        </div>
        <Confirm
          show={this.state.confirm_show}
          id={this.state.confirm_id}
          change={this.state.confirm_change}
          close={this.confirm_close}
          reload={this.loadData}
        />
        {/* <InputResi
              show={this.state.show_input_resi}
              kurir={this.shiperout}
              close={this.inputresi_close}
              data={this.state.data_row}
              reload={this.loadDataTranship}
            />
            <Tracking 
              show={this.state.show_tracking}
              close={this.tracking_close}
              data={this.state.data_row}            
            /> */}
        <PopGlobal
          show={this.state.showGlobal}
          close={this.goToDashboard}
          message={this.state.message}
          message_type={this.state.message_type}
        />
      </Fragment>
    );
  }
}
