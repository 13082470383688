import React, { Component,Fragment } from 'react'
import {Link} from 'react-router-dom'
import man from '../../../assets/images/dashboard/man.png'
import cookie from 'react-cookies'
import Avatar from 'react-avatar';
import LogOut from '../../auth/logout';
export class User_menu extends Component {
    constructor(props){
        super(props);

        this.state={
            show:false
        }
    }

    handleLogout() {
        this.state.show = true
    } 

    render() {
        let user = cookie.load('user_data');
        let name = user.results.admin_name;
        let full_name = user.results.admin_fullname;
        let role = user.results.admin_role;
        return (
            <Fragment>
                  
                    <li className="onhover-dropdown">
                        <div className="media align-items-center">
                            {/* <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" /> */}
                            <Avatar name={name} round={true} size='50px'/>
                            <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                            {/* <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`} ><i data-feather="user"></i>Edit Profile</Link></li>
                            <li><a href="javascript:void(0)"><i data-feather="mail"></i>Inbox</a></li>
                            <li><a href="javascript:void(0)"><i data-feather="lock"></i>Lock Screen</a></li>
                            <li><a href="javascript:void(0)"><i data-feather="settings"></i>Settings</a></li> */}
                            <li>{full_name}</li>
                            <li><a href="#" onClick={this.handleLogout}><i data-feather="log-out"></i>Logout</a></li>
                        </ul>
                    </li>
                    <LogOut show={this.state.show} close={()=>this.setState({show:false})}/>
            </Fragment>
        )
    }
}

export default User_menu
