import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';

//images
import logo from '../../../assets/images/dashboard/ic-logo.png';
import sampleBarcode from '../../../assets/images/sample-barcode.png';
import {NumberWithCommas} from '../../../constants/helpers';

export default class Tracking extends Component {
	constructor(props) {
		super(props);

		this.state = {
			deliv_status: false,
			manifest: [],
			message: '',
			message_type: '',
			load_track: false,
			data_ready: false,
		};
	}
	closeModal = () => {
		this.setState({
			deliv_status: false,
			manifest: [],
			message: '',
			message_type: '',
			load_track: false,
			data_ready: false,
		});
		this.props.close();
	};

	render() {
		let detail = null;
		if (this.props.show) {
			detail = JSON.parse(localStorage.getItem('detail_label'));
		}
		return (
			<div>
				{this.props.show ? (
					<Modal
						show={this.props.show}
						onHide={this.closeModal}
						backdrop='static'
						keyboard={false}
						centered
					>
						<div
							className='p-2'
							ref={(el) => (this.componentRef = el)}
						>
							<table className='table table-bordered text-center m-0'>
								<tr>
									<td colspan='2'>
										<div className='d-flex justify-content-between align-items-center'>
											<img src={logo} alt='' />
											<img
												src={
													process.env.PUBLIC_URL +
													`/ic-${detail.service.service_code}.png`
												}
												alt='loading'
												width={100}
											/>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan='2'>
										<Barcode
											value={`AWB - ${detail.awb}`}
											width={1}
										/>
									</td>
								</tr>
								<tr>
									<td colspan='2'>
										<div className='font-weight-bold'>
											{detail?.service.shipping_options?.toUpperCase() || '-'}:
											Rp. {NumberWithCommas(detail.service.cod_fee_amount)}
										</div>
									</td>
								</tr>
								<tr>
									<td colspan='2'>
										<div className='font-weight-bold'>
											Jenis Layanan:{' '}
											{detail.service.service_name}
										</div>
									</td>
								</tr>
								<tr>
									<td colspan='2' style={{ width: '50%' }}>
										<Barcode
											value={detail.order_id}
											width={1}
										/>
									</td>
								</tr>
								{/* <tr>
									<td
										className='text-left'
										style={{ width: '50%' }}
									>
										<div>
											Asuransi: Rp.{' '}
											{detail.service.insurance_amount}
										</div>
									</td>
								</tr>
								<tr>
									<td
										className='text-left'
										style={{ width: '50%' }}
									>
										<div>Berat 500g</div>
									</td>
								</tr>
								<tr>
									<td
										className='text-left'
										style={{ width: '50%' }}
									>
										<div>Quantitiy: 1</div>
									</td>
								</tr> */}
								{/* <tr>
									<td
										colspan='2'
										className='font-weight-bold text-left'
									>
										<div>Tipe Pengiriman:</div>
										<div>{detail?.service?.shipping_options?.toUpperCase() || '-'}</div>
									</td>
								</tr> */}
								<tr>
									<td className='align-top font-weight-bold text-left'>
										<div>Pengirim:</div>
										<div>{detail.origin.name}</div>
										<br />
										<div>
											{detail.origin.phone} |{' '}
											{detail.origin.address},{' '}
											{detail.origin.city},{' '}
											{detail.origin.zip_code}
										</div>
									</td>
									<td className='align-top font-weight-bold text-left'>
										<div>Penerima:</div>
										<div>{detail.destination.name}</div>
										<br />
										<div>
											{detail.destination.phone} |{' '}
											{detail.destination.address},{' '}
											{detail.destination.city},{' '}
											{detail.destination.zip_code}
										</div>
									</td>
								</tr>
								<tr>
									<td colspan='2' className='text-left'>
										<div>Catatan: -</div>
									</td>
								</tr>
								<tr>
									<td
										colspan='2'
										className='text-left font-italic'
									>
										<div>
											*Pengirim wajib meminta bukti serah
											terima paket ke kurir
										</div>
										<div>
											*Jika paket ini retur, pengirim
											tetap mengirimkan biaya
											keberangkatan dan biaya retur sesuai
											ekspedisi
										</div>
									</td>
								</tr>
							</table>
						</div>
						<Modal.Footer>
							<button
								className='btn btn-outline-info'
								onClick={this.closeModal}
							>
								close
							</button>
							<ReactToPrint
								trigger={() => (
									<button className='btn btn-outline-primary'>
										<printer /> &ensp;Cetak
									</button>
								)}
								content={() => this.componentRef}
							/>
						</Modal.Footer>
					</Modal>
				) : null}
			</div>
		);
	}
}
