import React, { Component, Fragment } from "react";
import AuthService from "../api_getter/auth_service";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Breadcrumb from "../common/breadcrumb";
import { ExternalLink, Edit2, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import EditFrame from "./edit_kacamata";
import HapusFrame from "./hapus_product";
import cookie from "react-cookies";

export default class Products_matta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_in: false,
      data_loaded: false,
      data_user: {},
      data_row: {},
      message: "",
      message_type: "",
      showEdit: false,
      showHapus: false,
    };
    this.data_all_product = [];
    this.data_column = [
      { dataField: "kacamata_id", text: "ID", hidden: "true" },
      { dataField: "name", text: "Nama" },
      { dataField: "brand_name", text: "Merek" },
      { dataField: "stock", text: "Stok" },
      { dataField: "status", text: "Status" },
      { dataField: "actions", text: "Aksi", formatter: this.ActionFormater },
    ];
    this.Style = {
      image: {
        background: "#fefefe",
        height: 25,
        width: 25,
      },
    };
  }
  componentDidMount() {
    this.loadAllProduct();
  }

  loadAllProduct = () => {
    AuthService.getAllProduct().then((data) => {
      if (data.results) {
        this.data_all_product = data.results;
        this.setState({ data_loaded: true });
      } else {
        this.setState({ message: data.message, message_type: "alert-danger" });
      }
    });
  };
  goToDetail = (row) => {
    localStorage.setItem("product_detail", JSON.stringify(row));
    cookie.save("product_detail", row, { path: "/" });
    this.props.history.push(`${process.env.PUBLIC_URL}/product-detail`);
  };

  ActionFormater = (cell, row) => {
    return (
      <div>
        <a href="javascript:void(0)" onClick={() => this.goToDetail(row)}>
          <ExternalLink size="20" />
        </a>
        &ensp;&ensp;
        <a
          href="javascript:void(0)"
          onClick={() => this.setState({ showEdit: true, data_row: row })}
        >
          <Edit2 color="green" size="20" />
        </a>
        &ensp;&ensp;
        <a
          href="javascript:void(0)"
          onClick={() => this.setState({ showHapus: true, data_row: row })}
        >
          <Trash2 color="red" size="20" />
        </a>
      </div>
    );
  };

  newProduct = () => {
    this.props.history.push("/new-product");
  };

  closeEditStatus = () => {
    this.setState({ showEdit: false });
  };

  render() {
    const { SearchBar, ClearSearchButton } = Search;

    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {},
      onSizePerPageChange: function (page, sizePerPage) {},
    });

    return (
      <Fragment>
        <Breadcrumb title="Frame" parent="Produk" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Daftar Frame</h5>
              <div className="float-sm-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.newProduct}
                >
                  Tambah Product
                </button>
              </div>
            </div>
            <div className="card-body">
              <ToolkitProvider
                bootstrap4
                keyField="user_id"
                data={this.data_all_product}
                columns={this.data_column}
                search
              >
                {(props) => (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />

                    <BootstrapTable
                      id="user_table"
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes="table table-borderless"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>

        <EditFrame
          show={this.state.showEdit}
          status={this.state.data_row.status}
          id={this.state.data_row.kacamata_id}
          close={this.closeEditStatus}
          reload={this.loadAllProduct}
        />
        <HapusFrame
          show={this.state.showHapus}
          close={() => this.setState({ showHapus: false })}
          id={this.state.data_row.kacamata_id}
          product={this.state.data_row.name}
          reload={this.loadAllProduct}
        />
      </Fragment>
    );
  }
}
