import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

export default class PopLensa extends Component {

    constructor(props){
        super(props);

        this.state={
            error:"",
            data_row:{},
            data_loaded:false
        }

        this.data = []
        this.data_column=[
            {dataField:'lens_id',hidden:'true'},
            {dataField:'lens_name',text:'Lensa'},
        ]
        
        this.selectRow = {
            mode:'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: {backgroundColor:"#c8e6c9"},
            onSelect: (row, isSelect, rowIndex, e) => {
              this.setState({data_row:row})
            }
        }
    }
    
    componentDidMount(){
        //this.loadData()
    }
    componentDidUpdate(){
      if(this.props.show && (this.state.data_loaded===false)){
        this.loadData()
      }
    }

    loadData = () =>{
        AuthService.getAllLens().then(
            (data) => {
                if (data.results){
            
                    this.data=data.results
                    this.setState({data_loaded:true})
                  }
                  else{
                    this.setState({error:data.error.message})
                  }
            }
        )
    }
    pilih = () =>{
      this.props.select(this.state.data_row.lens_id,this.state.data_row.lens_name,this.state.data_row.lens_brand,
        this.state.data_row.price_sale)
      this.quit();
    }

    quit = () =>{
      this.setState({error:"",
      data_row:{},
      data_loaded:false});

      this.props.close()
    }
    
    
  render() {
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <Modal
          show={this.props.show}
          onHide={this.quit}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pilih Frame</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
                <div className="col">
                <img src={this.state.data_row.media_url} className='img-fluid' alt='pilih salah satu item'/>

                </div>
                <div className="col">
                <ToolkitProvider
              bootstrap4
              keyField='lens_id'
              data={this.data}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      
                      <BootstrapTable
                      id = 'list_frame'
                      condensed
                      hover
                      // rowEvents={this.rowEvents}
                      selectRow={this.selectRow}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>

                </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={this.quit}>
                Close
            </button>
            <button type="button" className="btn btn-primary" onClick={this.pilih}>
                Pilih
            </button>
          </Modal.Footer>
        </Modal>
    )
  }
}
