import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import AuthService from '../../api_getter/auth_service';
import PopAdmin from '../../../components/loyalty/pop_admin';

export default class DeleteCampaign extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: '',
			message_type: '',
			success: false,
		};
	}

	Delete = () => {
		AuthService.deleteCampaign(this.props.id).then((res) => {
			if (res.success) {
				this.setState({
					message: res.message,
					message_type: 'alert-success',
					show_pop: true,
				});
			} else {
				this.setState({
					message: res.message,
					message_type: 'alert-danger',
					show_pop: true,
				});
			}
		});
	};

	quit = () => {
		this.setState({
			message: '',
			message_type: '',
			success: false,
		});
		this.props.reload();
		this.props.close();
	};

	render() {
		return (
			<div>
				<Modal
					show={this.props.show}
					onHide={this.quit}
					backdrop='static'
					keyboard={false}
					centered
					size='lg'
				>
					<Modal.Header closeButton>
						<Modal.Title>Hapus Campaign</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className='d-flex justify-content-center'>
							{this.state.message ? (
								<div
									className={
										'alert ' + this.state.message_type
									}
								>
									{this.state.message}
								</div>
							) : (
								<h5>
									Apakah anda yakin untuk menghapus{' '}
									{this.props.product} ?{' '}
								</h5>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className='d-flex justify-content-end'>
							<button
								className='btn btn-secondary'
								onClick={this.quit}
							>
								{this.state.success ? 'Tutup' : 'Tidak'}
							</button>
							&ensp;
							{this.state.success === false && (
								<button
									className='btn btn-primary'
									onClick={this.Delete}
								>
									Iya
								</button>
							)}
						</div>
					</Modal.Footer>
					<PopAdmin
						show={this.state.show_pop}
						close={this.quit}
						message={this.state.message}
						message_type={this.state.message_type}
						handleQuit={() => {}}
					/>
				</Modal>
			</div>
		);
	}
}
