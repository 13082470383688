import PopAdmin from '../../../components/loyalty/pop_admin';
import Form from 'react-bootstrap/Form';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import CKEditor from 'react-ckeditor-component';
import React, {Component, Fragment} from 'react';
import AuthService from '../../api_getter/auth_service';
import Breadcrumb from '../../common/breadcrumb';

export default class AllLoyalty extends Component {
    constructor(props) {
        super(props);

        this.gambarRef = React.createRef();
        this.inputRef = React.createRef();
        this.state = {
            payload: {},
            campaignOptions: [],
            detailCampaign: {},
            feedback: {},
        };
        this.getCampaignOptions();
        if (props.edit) {
            this.getDetail();
        } else {
            // this.generateCode();
        }
    }

    getDetail = () => {
        const id = this.props.match.params.id;
        AuthService.DetailNotification(id).then((res) => {
            const payload = this.state.payload;
            const {data} = res;
            payload.name = data.name;
            payload.code = data.code;
            payload.detail = data.detail;
            payload.url_page_destination = data.url_page_destination;
            payload.image_url = data.image_url;
            payload.campaign_id = data.campaign_id;
            payload.status = data.status.value;
            this.setState({payload});
        });
    };

    generateCode = () => {
        AuthService.generateNotificationCode().then((res) => {
            const payload = this.state.payload;
            payload.code = res.data.code;
            this.setState({payload});
        });
    };

    getCampaignOptions = () => {
        AuthService.getAllCampaign({page: 1, limit: 1000}).then((res) => {
            const campaignOptions = res.data.data.map((v) => ({
                value: v.id,
                label: v.name,
            }));
            this.setState({campaignOptions});
        });
    };

    openFile = () => {
        this.inputRef.current.click();
    };

    backgroundFile = () => {
        if (this.state.payload.file)
            return URL.createObjectURL(this.state.payload.file);
        if (this.state.payload.image_url)
            return `${this.state.payload.image_url}`;
        return '';
    };

    changeInput = (event) => {
        // console.log(event)
        const payload = this.state.payload;
        const target = event.target;
        payload[target.name] = target.value;
        this.setState({payload});
    };

    handleCampaign = async ({value}) => {
        const payload = this.state.payload;
        const detailResCampaign = await AuthService.detailCampaign(value)
        const data = detailResCampaign?.data
        payload.campaign_id = data.id;
        payload.code = data.code;
        payload.image_url = data.image_url;
        this.setState({payload});
    };

    editorChanged = (evt) => {
        const value = evt.editor.getData();
        const payload = this.state.payload;
        payload.detail = value;
        this.setState({
            payload,
        });
    };

    handleInputChanged = (e) => {
        const input = this.inputRef.current;
        const files = e.target.files;
        const payload = this.state.payload;
        payload.file = files[0];
        this.setState({payload});
        input.type = 'text';
        setTimeout(() => {
            input.type = 'file';
        }, 3e2);
    };

    deleteImage = () => {
        const payload = this.state.payload;
        delete payload.file;
        this.setState({payload});
    };

    handleSave = async () => {
        const onError = (error) => {
            let message = error.message;
            if (error.response?.data?.message) message = error.response.data.message;
            if (error.response?.data) message = 'Data tidak valid'
            const feedback = {
                success: false,
                message: message,
                message_type: 'alert-danger',
                show_pop: true,
            };
            this.setState({feedback});
        };

        let isError = false;

        const payload = this.state.payload;
        if (payload.file instanceof File) {
            const fileRes = await AuthService.AddImageNotification(
                payload.file
            ).catch((e) => {
                onError(e);
                isError = true;
            });
            payload.image_url = fileRes.data.image_url;
        }

        if (isError) return;

        const data = {
            name: payload.name,
            code: payload.code,
            detail: payload.detail,
            url_page_destination: payload.url_page_destination,
            image_url: payload.image_url,
            campaign_id: payload.campaign_id,
            status: parseInt(payload.status),
        };

        if (this.props.edit) {
            const id = this.props.match.params.id;
            AuthService.EditNotification(id, data)
                .then((res) => {
                    let message
                    if (res.success) {
                        if (parseInt(payload.status) === 0) {
                            message = "Notification telah diaktifkan"
                        } else if (parseInt(payload.status) === 1) {
                            message = "Notification disimpan sebagai draft"
                        } else if (parseInt(payload.status) === 2) {
                            message = "Notification telah dinonaktifkan"
                        }
                        const feedback = {
                            success: true,
                            message: message,
                            message_type: 'alert-success',
                            show_pop: true,
                        }
                        this.setState({
                            feedback
                        });
                    } else {
                        throw new Error(res.message);
                    }
                })
                .catch((e) => {
                    onError(e);
                });
        } else {
            AuthService.AddNotification(data)
                .then((res) => {
                    let message
                    if (res.success) {
                        if (parseInt(payload.status) === 0) {
                            message = "Notification telah diaktifkan"
                        } else if (parseInt(payload.status) === 1) {
                            message = "Notification disimpan sebagai draft"
                        } else if (parseInt(payload.status) === 2) {
                            message = "Notification telah dinonaktifkan"
                        }
                        const feedback = {
                            success: true,
                            message: message,
                            message_type: 'alert-success',
                            show_pop: true,
                        }
                        this.setState({
                            feedback
                        });
                    } else {
                        throw new Error(res.message);
                    }
                })
                .catch((e) => {
                    onError(e);
                });
        }
    };

    closePopup = () => {
        const feedback = this.state.feedback;
        feedback.show_pop = false;
        this.setState({feedback});
    };

    handleQuit = () => {
        if (this.state.feedback.success)
            this.props.history.push(`${process.env.PUBLIC_URL}/notification`);
    };

    render = () => {
        const campaingValue = () => {
            return this.state.campaignOptions.find(
                (v) => v.value == this.state.payload.campaign_id
            );
        };

        const ImageNav = (props) => {
            if (!this.state.payload.file) return '';
            return (
                <div style={{color: '#FF5C00'}}>
          <span
              className="mr-2"
              onClick={this.openFile}
              style={{cursor: 'pointer'}}
          >
            Edit
          </span>
                    <span onClick={this.deleteImage} style={{cursor: 'pointer'}}>
            Hapus
          </span>
                </div>
            );
        };

        const InputStatus = () => {
            const status = [
                {label: 'Aktif', value: 0},
                {label: 'Draft', value: 1},
                {label: 'Tidak Aktif', value: 2},
            ];
            return status.map((v, i) => {
                return (
                    <Form.Check
                        inline
                        key={v.value}
                        id={`status-${i}`}
                        type="radio"
                        name="status"
                        value={v.value}
                        label={v.label}
                        onChange={this.changeInput}
                        checked={this.state.payload.status == v.value}
                    />
                );
            });
        };

        const feedback = this.state.feedback;

        return (
            <Fragment>
                <Breadcrumb
                    title={this.props.edit ? 'Edit Notifikasi' : 'Tambah Notifikasi'}
                    parent="Notifikasi"
                />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                {this.props.edit ? 'Notifikasi Detail' : 'Notifikasi Baru'}
                            </h5>
                        </div>

                        <div className="card-body">
                            <div className="row align-items-stretch">
                                <div className="col-md-6 col-12">
                                    <div className="mb-4">
                                        <label>Nama notifikasi</label>
                                        <br/>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Masukkan nama notifikasi"
                                            className="form-control"
                                            value={this.state.payload.name}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>Notifikasi code</label>
                                        <br/>
                                        <input
                                            type="text"
                                            name="code"
                                            placeholder="Masukkan nama notifikasi"
                                            className="form-control"
                                            disabled
                                            value={this.state.payload.code}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>Campaign</label>
                                        <br/>
                                        <Select
                                            isMulti={false}
                                            options={this.state.campaignOptions}
                                            onChange={this.handleCampaign}
                                            name="campaign_id"
                                            value={campaingValue()}
                                            placeholder="Pilih campaign"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between">
                                            <label>Preview</label>
                                            <ImageNav/>
                                        </div>
                                        <div
                                            className="border rounded w-100 d-flex justify-content-center align-items-center"
                                            style={{
                                                backgroundImage: `url(${this.backgroundFile()})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                height: '200px',
                                            }}
                                        >
                                        </div>
                                        <input
                                            className="d-none"
                                            type="file"
                                            ref={this.inputRef}
                                            accept="image/*"
                                            onChange={this.handleInputChanged}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>Page destination</label>
                                        <br/>
                                        <input
                                            type="text"
                                            name="url_page_destination"
                                            placeholder="Masukkan url"
                                            className="form-control"
                                            value={this.state.payload.url_page_destination}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>Status</label>
                                        <br/>
                                        <InputStatus/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div>
                                        <label>Detail Notifikasi</label>
                                        <br/>
                                        <CKEditor
                                            name="detail"
                                            content={this.state.payload.detail}
                                            events={{
                                                // blur: this.onBlur,
                                                // afterPaste: this.afterPaste,
                                                change: this.editorChanged,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn btn-primary mr-4"
                                        onClick={this.handleSave}
                                    >
                                        Simpan
                                    </button>
                                    <Link to="/all-campaign">
                                        <button type="button" className="btn btn-secondary">
                                            Batal
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PopAdmin
                    show={feedback.show_pop}
                    close={this.closePopup}
                    message={feedback.message}
                    message_type={feedback.message_type}
                    handleQuit={this.handleQuit}
                />
            </Fragment>
        );
    };
}
