import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Plus, Upload, Trash2 } from 'react-feather';
import PrevFoto from '../product_matta_preview/prev_foto';
import Prev3D from '../product_matta_preview/prev_3d';
import AuthService from '../api_getter/auth_service';
import SuccessPOP from '../product_matta_preview/pop_success';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ColorPicker from '../product_matta_preview/color_picker';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const animatedComponents = makeAnimated();

const ChildProduct = (props) => {
	const [show, setShow] = useState(false);
	const closeModal = () => setShow(false);
	const showModal = () => setShow(true);

	const [show3d, setShow3d] = useState(false);
	const closeModal3D = () => setShow3d(false);
	const showModal3D = () => setShow3d(true);

	const [ColorPop, setColorPop] = useState(false);
	const showColor = () => setColorPop(true);
	const closeColor = () => setColorPop(false);

	const param = {
		id_id: props.numid,
		nama_warna: '',
		nama_foto: '',
		id_foto: '',
		nama_3d: '',
		id_3d: '',
		stock: 0,
		color_code: '#007bff',
	};

	const { ...params } = param;
	const [parameter, setParam] = useState(params);
	const changePhoto = (id_photo, name_photo) =>
		setParam({ ...parameter, id_foto: id_photo, nama_foto: name_photo });
	const change3D = (id_3d, name_3d) =>
		setParam({ ...parameter, id_3d: id_3d, nama_3d: name_3d });
	const changeColor = (colorHex) =>
		setParam({ ...parameter, color_code: colorHex });
	useEffect(() => {
		props.callback(parameter, parameter.id_id);
	});

	return (
		<div className='row' id={props.numid}>
			<div className='col-md-3'>
				<label for='nama_warna'>Nama warna</label>

				<div class='input-group mb-3'>
					<div
						class='input-group-prepend'
						style={{ paddingTop: 10 + 'px', marginRight: 5 + 'px' }}
					>
						<svg width='20' height='20'>
							<rect
								width='20'
								height='20'
								fill={parameter.color_code}
								onClick={showColor}
							/>
						</svg>
					</div>
					<input
						type='text'
						name='nama_warna'
						id='nama_warna'
						className='form-control'
						placeholder='Tambah warna baru'
						value={parameter.nama_warna}
						onChange={(e) => {
							setParam({
								...parameter,
								nama_warna: e.target.value,
							});
						}}
					/>
				</div>
			</div>

			<div className='col-md-3'>
				<label>Foto Model</label>
				<br />
				<div class='input-group mb-3'>
					<input
						type='text'
						name='foto_model'
						class='form-control'
						disabled
						value={parameter.nama_foto}
					/>
					<div class='input-group-append'>
						<button
							class='btn btn-primary'
							type='button'
							onClick={showModal}
						>
							Pilih
						</button>
					</div>
				</div>
			</div>

			<div className='col-md-3'>
				<label>3d Model</label>
				<br />
				<div class='input-group mb-3'>
					<input
						type='text'
						name='model_3d'
						class='form-control'
						disabled
						value={parameter.nama_3d}
					/>
					<div class='input-group-append'>
						<button
							class='btn btn-primary'
							type='button'
							onClick={showModal3D}
						>
							Pilih
						</button>
					</div>
				</div>
			</div>

			<div className='col-md-3 '>
				<label for='counter'>Jumlah Stok</label>
				<div className='input-group mb-3' id='counter'>
					<div class='input-group-prepend'>
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => {
								setParam({
									...parameter,
									stock: parameter.stock - 1,
								});
							}}
						>
							{'-'}
						</button>
					</div>
					<input
						type='number'
						id='stock'
						name='stock'
						className='form-control'
						style={{ textAlign: 'center' }}
						min='0'
						step='1'
						max='999999999'
						placeholder=''
						value={parameter.stock}
						onChange={(e) => {
							setParam({
								...parameter,
								stock: parseInt(e.target.value),
							});
						}}
					/>

					<div class='input-group-append'>
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => {
								setParam({
									...parameter,
									stock: parameter.stock + 1,
								});
							}}
						>
							{'+'}
						</button>
					</div>
				</div>
			</div>

			<PrevFoto
				show={show}
				close={closeModal}
				changePhoto={changePhoto}
			/>
			<Prev3D show={show3d} close={closeModal3D} change3D={change3D} />
			<ColorPicker
				show={ColorPop}
				close={closeColor}
				changeColor={changeColor}
			/>
		</div>
	);
};

export default class New_product extends Component {
	constructor(props) {
		super(props);

		this.state = {
			numChild: 0,
			delChildKey: null,
			kategori: [],
			cocok_untuk: [],
			originalitas: '',
			bentuk: '',
			material: '',
			berat: '',
			panjang: '',
			lebar: '',
			tinggi: '',
			nosepad: '',
			casing: '',
			lap: '',
			bluesafe: '',
			bersih: '',
			lensa_prog: '',
			nama_product: '',
			harga_modal: 0,
			nama_merk: '',
			tipe_produk: '',
			harga_awal: 0,
			diskon: 0,
			ukuran_product: '',
			merek: [],
			message: '',
			show: false,
			success_message: '',
			merek_frame: [],
			description: EditorState.createEmpty(),
		};

		this.varians = [];
		this.spec = {};
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			description: editorState,
		});
	};

	closeModal = () => {
		this.setState({ show: false });
		this.props.history.push(`${process.env.PUBLIC_URL}/products`);
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		AuthService.pageNewProduct().then((data) => {
			if (data.results) {
				this.setState({ merek: data.results });
				let mereks = this.constructOption();
				this.setState({ merek_frame: mereks });
			} else {
				this.setState({ message: data.error.message });
			}
		});
	};

	loadAllProduct = () => {
		AuthService.getAllProduct().then((data) => {
			let result = data.results.slice(-1);
			let payload = {
				type: 'frame',
				product_id: result[0].kacamata_id,
				weight: this.state.berat,
				width: this.state.lebar,
				height: this.state.tinggi,
				length: this.state.panjang,
			};
			AuthService.createDataMaster(payload).then(() => {
				this.setState({ show: true });
			});
		});
	};

	sendData = () => {
		this.constJson();
		let kacamata = {
			nama_product: this.state.nama_product,
			harga_modal: this.state.harga_modal,
			nama_merk: this.state.nama_merk,
			tipe_produk: this.state.tipe_produk,
			harga_awal: this.state.harga_awal,
			diskon: this.state.diskon,
			harga_akhir: this.state.harga_awal - this.state.diskon,
			ukuran_product: this.state.ukuran_product,
			description: draftToHtml(
				convertToRaw(this.state.description.getCurrentContent()),
			),
			varian: this.varians,
			spec: this.spec,
		};

		AuthService.sendNewProduct(kacamata).then((data) => {
			if (data.results) {
				this.loadAllProduct();
				this.setState({ success_message: data.results });
			} else {
				this.setState({ message: data.error.message });
			}
		});
	};

	constJson = () => {
		let specs = {
			kategori: this.state.kategori,
			cocok_untuk: this.state.cocok_untuk,
			originalitas: this.state.originalitas,
			bentuk: this.state.bentuk,
			material: this.state.material,
			berat: this.state.berat,
			nosepad: this.state.nosepad,
			casing: this.state.casing,
			lap: this.state.lap,
			bluesafe: this.state.bluesafe,
			bersih: this.state.bersih,
			lensa_prog: this.state.lensa_prog,
		};

		this.spec = specs;
	};

	addChild = () => {
		this.setState({
			numChild: this.state.numChild + 1,
		});
	};

	delChild = (i) => {
		this.setState({
			numChild: this.state.numChild - 1,
		});
		this.varians.pop();
	};

	tampungHook = (param, key) => {
		this.varians[key] = param;
	};
	selectRadio = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	selectInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	selectOption = (value, actionMeta) => {
		this.setState({
			[actionMeta.name]: value,
		});
	};
	selectOptionSingle = (value, actionMeta) => {
		this.setState({
			[actionMeta.name]: value.value,
		});
	};

	selectMerek = (value, actionMeta) => {
		this.setState({
			nama_merk: value.value,
		});
	};

	constructOption = () => {
		let opt = [];
		this.state.merek.forEach((element, index) => {
			let obj = {
				value: element.brand_id,
				label: element.brand_name,
			};
			opt.push(obj);
		});

		return opt;
	};

	render() {
		let children = [];

		for (var i = 0; i < this.state.numChild; i += 1) {
			children.push(
				<ChildProduct key={i} numid={i} callback={this.tampungHook} />,
			);
		}

		const kategori = [
			{ value: 'Fullframe', label: 'Fullframe' },
			{ value: 'Kacamata Wanita', label: 'Kacamata Wanita' },
			{ value: 'Kacamata Pria', label: 'Kacamata Pria' },
			{ value: 'Kacamata Anak', label: 'Kacamata Anak' },
			{ value: 'Kacamata Unisex', label: 'Kacamata Unisex' },
			{ value: 'Usia 10thn keatas', label: 'Usia 10thn keatas' },
			{ value: 'Usia 12thn keatas', label: 'Usia 12thn keatas' },
			{ value: 'Usia 15thn keatas', label: 'Usia 15thn keatas' },
			{ value: 'Usia 20thn keatas', label: 'Usia 20thn keatas' },
			{ value: 'Usia 10 s.d 30 thn', label: 'Usia 10 s.d 30 thn' },
			{ value: 'Usia 20 s.d 45 thn', label: 'Usia 20 s.d 45 thn' },
			{ value: 'Usia 20 s.d 40 thn', label: 'Usia 20 s.d 40 thn' },
			{ value: 'Usia 35thn keatas', label: 'Usia 35thn keatas' },
		];
		const cocok_untuk = [
			{ value: 'Casual', label: 'Casual' },
			{ value: 'Fasion', label: 'Fasion' },
			{ value: 'Work', label: 'Work' },
			{ value: 'Formal', label: 'Formal' },
			{ value: 'Sport', label: 'Sport' },
		];

		const originalitas = [
			{ value: 'Housebrand Ori', label: 'Housebrand Ori' },
			{ value: 'Ori Branded', label: 'Ori Branded' },
		];

		const bentuk = [
			{ value: 'Setengah bulat', label: 'Setengah bulat' },
			{ value: 'Bulat', label: 'Bulat' },
			{ value: 'Oval', label: 'Oval' },
			{ value: 'Kotak Besar', label: 'Kotak Besar' },
			{ value: 'Kotak', label: 'Kotak' },
			{ value: 'Polygon', label: 'Polygon' },
			{ value: 'Sporty', label: 'Sporty' },
		];

		const material = [
			{ value: 'Plastik dan Metal', label: 'Plastik dan Metal' },
			{ value: 'Bahan TR dan Plastik', label: 'Bahan TR dan Plastik' },
		];

		const berat = [
			{ value: '1 Kg', label: '1 Kg' },
			{ value: '2 Kg', label: '2 Kg' },
			{ value: '3 Kg', label: '3 Kg' },
		];

		return (
			<Fragment>
				<Breadcrumb
					title='Tambah Frame Baru'
					parent='Produk / Frame '
				/>
				<div className='container-fluid'>
					<div className='card'>
						<div className='card-header'>
							<h5>Identitas Frame</h5>
						</div>

						<div className='card-body matta-no-arrow'>
							<div className='row'>
								<div className='col-md-6'>
									<label for='nama_product'>Nama Frame</label>
									<input
										type='text'
										name='nama_product'
										className='form-control'
										placeholder='Masukan nama produk'
										onChange={this.selectInput}
									/>
								</div>

								<div className='col-md-6'>
									<label for='harga_modal'>Harga Modal</label>
									<div className='input-group mb-3'>
										<div class='input-group-prepend'>
											<span class='input-group-text'>
												Rp
											</span>
										</div>
										<input
											type='number'
											name='harga_modal'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='1000'
											max='999999999'
											placeholder='modal ...'
										/>
									</div>
								</div>
							</div>

							<br />

							<div className='row'>
								<div className='col-md-3'>
									<label for='nama_merk'>Nama merek</label>
									<Select
										components={animatedComponents}
										options={this.state.merek_frame}
										name='nama_merk'
										placeholder='Pilih merek'
										onChange={this.selectMerek}
									/>
								</div>

								<div className='col-md-3'>
									<label for='tipe_produk'>Tipe</label>
									<input
										type='text'
										name='tipe_produk'
										className='form-control'
										placeholder='Masukan tipe produk'
										onChange={this.selectInput}
									/>
								</div>

								<div className='col-md-3'>
									<label for='harga_awal'>Harga Awal</label>
									<div className='input-group mb-3'>
										<div class='input-group-prepend'>
											<span class='input-group-text'>
												Rp
											</span>
										</div>

										<input
											type='number'
											name='harga_awal'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='1'
											max='999999999'
											placeholder='harga awal ...'
										/>
									</div>
								</div>

								<div className='col-md-3'>
									<label for='diskon'>Diskon</label>
									<div className='input-group mb-3'>
										<div class='input-group-prepend'>
											<span class='input-group-text'>
												Rp
											</span>
										</div>
										<input
											type='number'
											name='diskon'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='1'
											max='999999999'
											placeholder='diskon ...'
										/>
									</div>
								</div>
							</div>
							<br />

							<div className='row'>
								<div className='col-md-6'>
									<label for='ukuran_product'>
										Ukuran Frame
									</label>
									<input
										type='text'
										name='ukuran_product'
										className='form-control'
										placeholder='Masukan ukuran produk'
										onChange={this.selectInput}
									/>
								</div>
								<div className='col-md-6'>
									<label for='harga_akhir'>Harga Akhir</label>
									<br />
									<label id='harga_akhir'>
										<strong>
											<h3>
												{this.state.harga_awal -
													this.state.diskon}
											</h3>
										</strong>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div className='card'>
						<div className='card-header'>
							<h5>Dokumentasi Frame</h5>
						</div>

						<div className='card-body matta-no-arrow'>
							{children}
							<div className='row'>
								{this.state.numChild >= 1 && (
									<div className='col-md-6 custom_inner_matta_2'>
										<a
											href='javascript:void(0)'
											onClick={this.delChild}
										>
											<Trash2 />
											<p>Hapus warna</p>
										</a>
									</div>
								)}

								<div className='col-md-6 custom_inner_matta'>
									<a
										href='javascript:void(0)'
										onClick={this.addChild}
									>
										<Plus />
										<p>Tambah warna baru</p>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className='card'>
						<div className='card-header'>
							<h5>Detail Informasi Frame</h5>
						</div>

						<div className='card-body'>
							<div className='row'>
								<div className='col-md-6'>
									<label>Kategori</label>
									<Select
										components={animatedComponents}
										isMulti
										options={kategori}
										onChange={this.selectOption}
										name='kategori'
										placeholder='Pilih disini'
									/>
								</div>
								<div className='col-md-6'>
									<label>Cocok untuk</label>
									<Select
										components={animatedComponents}
										isMulti
										options={cocok_untuk}
										onChange={this.selectOption}
										name='cocok_untuk'
										placeholder='Pilih disini'
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<label>Originalitas</label>
									<Select
										components={animatedComponents}
										options={originalitas}
										onChange={this.selectOptionSingle}
										name='originalitas'
										placeholder='Pilih disini'
									/>
								</div>
								<div className='col-md-6'>
									<label>Bentuk</label>
									<Select
										components={animatedComponents}
										options={bentuk}
										onChange={this.selectOptionSingle}
										name='bentuk'
										placeholder='Pilih disini'
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<label>Material</label>
									<Select
										components={animatedComponents}
										options={material}
										onChange={this.selectOptionSingle}
										name='material'
										placeholder='Pilih disini'
									/>
								</div>
								<div className='col-md-6 matta-no-arrow'>
									<label>Berat</label>

									<div class='input-group mb-3'>
										<input
											type='number'
											name='berat'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='berat kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												grams
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 matta-no-arrow'>
									<label>Panjang</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='panjang'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Panjang kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
								<div className='col-md-6 matta-no-arrow'>
									<label>Lebar</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='lebar'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Lebar kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 matta-no-arrow'>
									<label>Tinggi</label>
									<div class='input-group mb-3'>
										<input
											type='number'
											name='tinggi'
											className='form-control'
											onChange={this.selectInput}
											min='0'
											step='10'
											max='999999999'
											placeholder='Tinggi kacamata'
										/>

										<div class='input-group-append'>
											<span class='input-group-text'>
												cm
											</span>
										</div>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<label>Nosepad</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='nosepad'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='nosepad1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='nosepad'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='nosepad2'
										>
											Tidak
										</label>
									</div>
								</div>

								<div className='col-md-6'>
									<label>Gratis lensa blue safe</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='bluesafe'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='inlineRadio1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='bluesafe'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='inlineRadio2'
										>
											Tidak
										</label>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<label>Gratis Casing</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='casing'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='nosepad1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='casing'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='nosepad2'
										>
											Tidak
										</label>
									</div>
								</div>

								<div className='col-md-6'>
									<label>Gratis pembersih lensa</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='bersih'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='inlineRadio1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='bersih'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='inlineRadio2'
										>
											Tidak
										</label>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-6'>
									<label>Gratis lap kacamata</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='lap'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='nosepad1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='lap'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='nosepad2'
										>
											Tidak
										</label>
									</div>
								</div>

								<div className='col-md-6'>
									<label>
										Memenuhi kriteria lensa progressif
									</label>
									<br />
									<div className='form-check form-check-inline'>
										<input
											className='form-check-input'
											type='radio'
											name='lensa_prog'
											value='Ya'
											onChange={this.selectRadio}
										/>
										<label
											className='form-check-label'
											for='inlineRadio1'
										>
											Ya
										</label>
									</div>
									<div class='form-check form-check-inline'>
										<input
											class='form-check-input'
											type='radio'
											name='lensa_prog'
											value='Tidak'
											onChange={this.selectRadio}
										/>
										<label
											class='form-check-label'
											for='inlineRadio2'
										>
											Tidak
										</label>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-md-12'>
									<div className='form-group'>
										<label>Description</label>
										<Editor
											editorState={this.state.description}
											wrapperClassName='demo-wrapper'
											editorClassName='demo-editor border rounded p-3'
											onEditorStateChange={
												this.onEditorStateChange
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='card'>
						<div className='card-body'>
							<button
								type='button'
								className='btn btn-primary'
								onClick={this.sendData}
							>
								Tambahkan Produk baru
							</button>
							{this.state.message && (
								<div class='alert alert-danger' role='alert'>
									{this.state.message}
								</div>
							)}
						</div>
					</div>
					<SuccessPOP
						show={this.state.show}
						close={this.closeModal}
						message={this.state.success_message}
					/>
				</div>
			</Fragment>
		);
	}
}
