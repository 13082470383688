import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import cookie from 'react-cookies';
import { Redirect } from 'react-router';
import AuthService from '.././api_getter/auth_service';


class LogoutPop extends Component {
  constructor(props){
      super(props);

      this.state={
          message:'',
          message_type:''
      }
  }
  
  goToLogin =()=>{
    this.setState({
        message:'',
        message_type:'',
    })
    this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`)
}

  LogOut = () =>{
    AuthService.logout().then(
        (data)=>{
            if (data.results) {
                cookie.save('user_data', {}, { path: '/' })
                cookie.save('logged_in', JSON.stringify({status:false}), { path: '/' })
                this.goToLogin()
              }
              else{
                this.setState({
                    message:data.error.message,
                    message_type:'alert-danger'
                })
              }
        }
    )
  }
  quit=()=>{
    this.setState({
      message:'',
      message_type:'',
    })
    this.props.close()
  }
  
  render() {
    return (
    <Modal
        show={this.props.show}
        onHide={this.props.close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg">

        <Modal.Header closeButton>
          <Modal.Title>Log Out</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
            {this.state.message?(
                <div className={"alert "+ this.state.message_type} role="alert">
                    {this.state.message}
                </div>
            ):(<h5>Are you sure to log out ?</h5>)}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={this.quit}>
              Cancel
          </button>
          {this.state.message===''&&(<button type="button" className="btn btn-primary" onClick={this.LogOut}>
              Log out
          </button>)}
        </Modal.Footer>
    </Modal>
    )
  }
}

export default withRouter(LogoutPop);
