import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../api_getter/auth_service';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PopAdmin from './pop_admin';

const animatedComponents = makeAnimated();

export default class EditLensaStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_pop: false,
            action: null,
            edit: false,
            message: '',
            message_type: '',
            success: false,
            load: true,
            id: 0,
            name: '',
            minimum_point: null,
            maximum_point: null,
            point_ratio: null,
            selected: [],
        };
    }

    changeInput = (event) => {
        this.setState({
            edit: true,
            [event.target.name]: event.target.value,
        });
    };

    selectOption = (event) => {
        const payload = event.map((data) => {
            return {value: data.value, label: data.label};
        });
        this.setState({
            edit: true,
            selected: payload,
        });
    };

    ChangeAction = () => {
        AuthService.editLensaStatus(this.props.id, this.state.action).then(
            (data) => {
                if (data.results) {
                    this.setState({
                        message: data.results,
                        message_type: 'alert-success',
                        edit: false,
                    });
                    this.props.reload();
                } else {
                    this.setState({
                        message: data.error.message,
                        message_type: 'alert-danger',
                    });
                }
            },
        );
    };

    quit = () => {
        this.setState({
            show_pop: false,
            action: null,
            edit: false,
            message: '',
            message_type: '',
            success: false,
            load: true,
            id: 0,
            name: '',
            minimum_point: null,
            maximum_point: null,
            point_ratio: null,
            selected: [],
        });
        this.props.reload();
        this.props.close();
    };

    updateLoyalty = () => {
        let dummySelected = this.state.selected.map((data) => data.value);
        let payload = {
            name: this.state.name,
            minimum_point: parseInt(this.state.minimum_point),
            maximum_point: parseInt(this.state.maximum_point),
            point_ratio: parseInt(this.state.point_ratio),
            reward_id: dummySelected,
        };
        AuthService.updateLoyalty(this.state.id, payload).then((res) => {
            if (res.success) {
                this.setState({
                    message: "Berhasil update level member",
                    message_type: 'alert-success',
                    show_pop: true,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                    show_pop: true,
                });
            }
        });
    };

    render() {
        let detail = [];
        let selectedOption = [];
        if (this.props.show) {
            detail = JSON.parse(localStorage.getItem('detail_loyalty'));
            selectedOption = detail.reward.map((data) => {
                return {value: data.id, label: data.title};
            });
            if (this.state.load) {
                this.setState({
                    id: detail.id,
                    name: detail.name,
                    minimum_point: detail.minimum_point,
                    maximum_point: detail.maximum_point,
                    point_ratio: detail.point_ratio,
                    selected: selectedOption,
                    load: false,
                });
            }
        }
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.quit}
                    backdrop='static'
                    keyboard={false}
                    centered
                    size='lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Loyalty</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row align-items-stretch'>
                            <div className='col-md-6'>
                                <div>
                                    <label>Pilih Level</label>
                                    <br/>
                                    <input
                                        type='text'
                                        name='name'
                                        placeholder='Nama Level'
                                        className='form-control'
                                        value={this.state.name}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>
                                <div>
                                    <label>Batasan Poin</label>
                                    <div className='d-flex'>
                                        <input
                                            type='text'
                                            name='minimum_point'
                                            placeholder='Minimal Poin'
                                            className='form-control mr-1'
                                            value={this.state.minimum_point}
                                            onChange={this.changeInput}
                                        />
                                        <input
                                            type='text'
                                            name='maximum_point'
                                            placeholder='Maksimal Poin'
                                            className='form-control ml-1'
                                            value={this.state.maximum_point}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <label>Point Ratio</label>
                                    <input
                                        type='text'
                                        name='point_ratio'
                                        placeholder='Masukkan Ratio'
                                        className='form-control'
                                        value={this.state.point_ratio}
                                        onChange={this.changeInput}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label>Reward</label>
                                <br/>
                                <Select
                                    components={animatedComponents}
                                    isMulti
                                    options={this.props.listReward}
                                    onChange={this.selectOption}
                                    name='selected'
                                    placeholder='Pilih Reward Bisa Lebih Dari 1'
                                    value={this.state.selected}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className='btn btn-secondary'
                            onClick={this.quit}
                        >
                            Tutup
                        </button>
                        {this.state.edit && (
                            <button
                                className='btn btn-primary'
                                onClick={this.updateLoyalty}
                            >
                                Simpan
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>
                <PopAdmin
                    show={this.state.show_pop}
                    close={this.quit}
                    message={this.state.message}
                    message_type={this.state.message_type}
                />
            </div>
        );
    }
}
