import React, { Component, Fragment } from 'react';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import PopGlobal from '../error/response_global';
import {Trash2} from 'react-feather';
import HapusMedia from './hapus_media';

export default class List_media extends Component {
    constructor(props){
        super(props);

        this.state={
            logged_in:false,
            data_loaded:false,
            data_user:{},
            data_row:{},
            message:'',
            message_type:'',
            code:0,
            show:false,
            show_delete:false
        }
        this.data_all_user = []
        this.data_column=[
        {dataField:'id_model',hidden:'true'},
        {dataField:'model_name',text:'Nama Media'},
        {dataField:'location',text:'Lokasi'},
        {dataField:'created_date',text:'Tanggal'},
        {dataField:'actions',text:'Aksi',formatter:this.ActionFormater}
        ]
        this.Style = {
          image: {
            background: '#fefefe',
            height:25,
            width:25
          }
        }
    }
    ActionFormater = (cell,row) => {
      return(
        <div >
          <a href='javascript:void(0)'
          onClick={()=>this.setState({show_delete:true,data_row:row})}>
          <Trash2 color='red' size='20'/></a>
        </div>
      )
    }

    componentDidMount(){
        this.LoadMedia();
    }

    LoadMedia = () => {
      AuthService.mediaList().then(
        (data) => {
          if (data.results){
            
            this.data_all_user = data.results;
            this.setState({data_loaded:true});
          }
          else{
            this.setState({
              message:data.message,
              message_type:'alert-danger',
              code:data.error.code,
              show:true})
          }
      
         
        } 
      )
    }
    goToDashboard =()=>{
      
      if (this.state.code===307){
          this.setState({
          message:'',
          message_type:'',
          data:{},
          data_loaded:false,
          show:false 
          })
          this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`)
      }
      else{
        this.setState({
          message:'',
          message_type:'',
          data:{},
          data_loaded:false,
          show:false 
        })
      }

  }
  shouldComponentUpdate(nextProps){
    let PropsA = this.props.data_state;
    let PropsB = nextProps.data_state;

    if (PropsA === 'uploading' && PropsB==='success'){
      this.LoadMedia();
      return true
    }

    return this.data_all_user.length !== 0
  }

  render() {
    
    const { SearchBar, ClearSearchButton } = Search;

    

    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {

      },
      onSizePerPageChange: function (page, sizePerPage) {
      }
    });

    return (
      <Fragment>
        <div className="container-fluid">
            <div >
              <div >
              </div>
              <div>
                <div className='table-responsive'>
              <ToolkitProvider
              bootstrap4
              keyField='id_model'
              data={this.data_all_user}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      
                      
                      <BootstrapTable
                      id = 'media_file'
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              
            </div>
            </div>
            </div>
        </div> 
        <PopGlobal show={this.state.show} close={this.goToDashboard} message={this.state.message}
            message_type={this.state.message_type}
        />
        <HapusMedia show={this.state.show_delete} close={()=>this.setState({show_delete:false})} 
        media_id={this.state.data_row.id_model} media_type={'1'} product={this.state.data_row.model_name} 
        reload={this.LoadMedia}
        />
      </Fragment>
    )
  }
}
