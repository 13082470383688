import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cookie from 'react-cookies'
import AuthService from '../api_getter/auth_service';


export class ForgotPassword extends Component {
    constructor(props){
        super(props);

        this.state={
            email:'',
            message:'',
            message_type:''
        }
    }
    
    Back_to_login = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`);
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    ForgetPassword = (event) =>{
        event.preventDefault();
        
        AuthService.forget_password(this.state.email).then( (data) => {
            if (data.results){
                cookie.save('forget_data', JSON.stringify(data.results), { path: '/' });
                this.props.history.push(`${process.env.PUBLIC_URL}/auth/otpforgot`);
            }
            else {
                this.setState({message:data.error.message});
            };
        });
    }
    
    render() {
        let login_status = cookie.load('logged_in')
        if (login_status !== undefined){
            if (login_status.status===true){
                return <Redirect to="/dashboard"/>
            }
        }
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-7 p-0 mx-auto">
                                    <div className="card tab2-card">
                                        <div className="shadow-lg rounded">
                                        <div className="card-body">
                                        
                                    <form className="form-horizontal auth-form" onSubmit={this.ForgetPassword}>
                                        
                                        <div className="form-group text-center">
                                            <h5>Lupa Password</h5><br/>
                                        </div>
                                        {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                            </div>
                                        </div>
                                        )}
                                        <div className="form-group">
                                            &ensp;<label>Masukkan email anda</label>
                                            <input name="email" className="form-control" type='email' placeholder="Email" onChange={this.handleChange}/>
                                        </div>

                                        <div className="form-button">
                                            <button className="btn btn-primary" type='submit'>Lanjut</button>
                                        </div>
                              
                                    </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ForgotPassword