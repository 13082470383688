import React, {Component, Fragment} from 'react';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import Breadcrumb from '../common/breadcrumb';
import {ExternalLink} from 'react-feather';
import {Link} from 'react-router-dom';
import cookie from 'react-cookies';
import PopGlobal from '../error/response_global';
import moment from 'moment';

export default class List_users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logged_in: false,
            data_loaded: false,
            data_user: {},
            data_row: {},
            message: '',
            message_type: '',
            code: 0,
            show: false,
        };
        this.data_all_user = [];
        this.data_column = [
            {dataField: 'user_id', text: 'ID', sort: true},
            {dataField: 'full_name', text: 'Nama Lengkap'},
            {dataField: 'phone', text: 'Nomor Telepon'},
            {
                dataField: 'date_of_birth',
                text: 'Tanggal Lahir',
                formatter: this.FormatDate,
            },
            {dataField: 'email', text: 'Email'},
            {dataField: 'status_user', text: 'Status'},
            {
                dataField: 'actions',
                text: 'Aksi',
                formatter: this.ActionFormater,
            },
        ];
        this.Style = {
            image: {
                background: '#fefefe',
                height: 25,
                width: 25,
            },
        };
    }

    componentDidMount() {
        this.LoadUserData();
    }

    LoadUserData = () => {
        AuthService.userManData().then((data) => {
            if (data.results) {
                this.data_all_user = data.results;
                this.setState({data_loaded: true});
            } else {
                this.setState({
                    message: data.error.message,
                    message_type: 'alert-danger',
                    code: data.error.code,
                    show: true,
                });
            }
        });
    };

    goToDashboard = () => {
        if (this.state.code === 307) {
            this.setState({
                message: '',
                message_type: '',
                data: {},
                data_loaded: false,
                show: false,
            });
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        } else {
            this.setState({
                message: '',
                message_type: '',
                data: {},
                data_loaded: false,
                show: false,
            });
        }
    };

    goToDetail = (row) => {
        cookie.save('user_data_row', row, {path: '/'});
        this.props.history.push(`${process.env.PUBLIC_URL}/users/details`);
    };

    FormatDate = (cell) => {
        return moment(parseInt(cell)).format('DD MMMM YYYY');
    };

    ActionFormater = (cell, row) => {
        return (
            <div>
                <a
                    href='javascript:void(0)'
                    onClick={() => this.goToDetail(row)}
                >
                    <ExternalLink size='20'/>
                </a>
            </div>
        );
    };

    PhotoFormater = (cell, row) => {
        return (
            <div>
                <img
                    src={row.media_url}
                    className='rounded-circle'
                    style={this.Style.image}
                    alt='loading'
                />
            </div>
        );
    };

    render() {
        const {SearchBar, ClearSearchButton} = Search;

        const pagination = paginationFactory({
            page: 1,
            sizePerPage: 5,
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            showTotal: true,
            alwaysShowAllBtns: true,
        });

        return (
            <Fragment>
                <Breadcrumb title='Pengguna Aplikasi' parent='Pengguna'/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5>Daftar Pengguna</h5>
                        </div>
                        <div className='card-body'>
                            <ToolkitProvider
                                bootstrap4
                                keyField='user_id'
                                data={this.data_all_user}
                                columns={this.data_column}
                                search
                            >
                                {(props) => (
                                    <div>
                                        <SearchBar {...props.searchProps} />
                                        <ClearSearchButton
                                            {...props.searchProps}
                                        />

                                        <BootstrapTable
                                            id='user_table'
                                            striped
                                            hover
                                            condensed
                                            pagination={pagination}
                                            {...props.baseProps}
                                            classes='table table-borderless'
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
                <PopGlobal
                    show={this.state.show}
                    close={this.goToDashboard}
                    message={this.state.message}
                    message_type={this.state.message_type}
                />
            </Fragment>
        );
    }
}
