import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AuthService from "../../api_getter/auth_service";
import { css } from "@emotion/core";
import Loader from "react-spinners/ClockLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class Tracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliv_status: false,
      manifest: [],
      message: "",
      message_type: "",
      load_track: false,
      data_ready: false,
    };
  }

  renderTrack = () => {
    return Object.entries(this.state.manifest).map(([key, value], i) => {
      return (
        <div key={key} className="card">
          <ul className="list-group list-group-flush">
            <li>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">{value.location}</div>
                  <div className="col-6">{value.date}</div>
                </div>
                <br />
                <div className="row">
                  <div className="col">{value.desc}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    });
  };
  Tracker = (shipid, resi, courier) => {
    //pengecekan
    if (resi === null) {
      this.setState({
        message: "nomor resi kosong",
        message_type: "alert-danger",
        load_track: true,
        data_ready: true,
      });
    } else {
      this.setState({ load_track: true });
      let data = {
        resi: resi,
        courier: courier.split(' ')[0].toLowerCase(),
      };
      AuthService.getTrackNew(JSON.stringify(data)).then((response) => {
        let data = response.data.data;
        if (data.error) {
            this.setState({
              data_ready: true,
              message_type: "alert-danger",
              message: data.error.message,
            });
        } else {
            this.setState({
              data_ready: true,
              deliv_status: data.status,
              manifest: data.history,
            });
        }
      });
    }
  };

  closeModal = () => {
    this.setState({
      deliv_status: false,
      manifest: [],
      message: "",
      message_type: "",
      load_track: false,
      data_ready: false,
    });
    this.props.close();
  };
  render() {
    let data = this.props.data;
    let invoice = data.invoice;
    let created_date = data.created_date;
    let resi = data.receipt;
    let shipid = data.shipping_id;
    let courier = data.shipper_name;
    return (
      <Modal
        show={this.props.show}
        onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tracking Pengiriman</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-3">
              <label>Tanggal Pemesanan:</label>
            </div>
            <div className="col-md">
              <label>{created_date}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>Invoice Pemesanan:</label>
            </div>
            <div className="col-md-3">
              <label>{invoice}</label>
            </div>
            <div className="col-md-2">
              <label>Nomor Resi :</label>
            </div>
            <div className="col-md-3">
              <label>{resi}</label>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row justify-content-center">
            <button
              className="btn btn-success"
              onClick={() => this.Tracker(shipid, resi, courier)}
            >
              Lihat track
            </button>
          </div>
          <hr style={{ width: 100 + "%" }} />
          {this.state.load_track && (
            <div className="d-flex flex-row justify-content-center">
              {this.state.data_ready === false && (
                <Loader css={override} size={100} color={"#007bff"} />
              )}

              {this.state.data_ready && <div>{this.renderTrack()}</div>}

              {this.state.message && (
                <div
                  className={"alert " + this.state.message_type}
                  role="alert"
                >
                  {this.state.message}
                </div>
              )}
            </div>
          )}
          <div className="row"></div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-info" onClick={this.closeModal}>
            close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
