import React, { Component } from 'react';
import AuthService from '../api_getter/auth_service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalDetail from './modal_detail';

export default class Test_user extends Component {
	constructor(props) {
		super(props);

		this.state = {
			blabla: '',
			data_row: {},
			data_loaded: false,
			showDetail: false,
		};

		this.Style = {
			image: {
				background: '#fefefe',
				height: 100,
				width: 170,
			},
		};
		this.data_test_user = [];
		this.data_column = [
			{
				dataField: 'test_id',
				text: 'ID',
				hidden: true,
			},
			{ dataField: 'created_date', text: 'Tanggal Test' },
			{
				dataField: 'left_eye',
				text: 'Mata Kiri',
				formatter: this.LeftEyeFormatter,
			},
			{
				dataField: 'right_eye',
				text: 'Mata Kanan',
				formatter: this.RightEyeFormatter,
			},
			{
				dataField: 'pupil_dist',
				text: 'Jarak Pupil',
				formatter: this.PupilFormater,
			},
			{
				dataField: 'actions',
				text: 'Foto Mata',
				formatter: this.CaptureFormater,
				events: {
					onClick: (e, column, columnIndex, row, rowIndex) => {
						this.setState({
							showDetail: true,
							data_row: row,
						});
					},
				},
			},
			{
				dataField: 'computer_class',
				text: 'Pengguna Komputer',
				formatter: this.ComputerFormater,
			},
		];
	}

	componentDidMount() {
		this.load_test_data(this.props.user_id);
	}

	load_test_data = (userid) => {
		AuthService.userTestMata(userid).then((data) => {
			if (data.results) {
				this.data_test_user = data.results;
				this.setState({ data_loaded: true });
			} else {
				console.log(data.error.message);
			}
		});
	};
	PupilFormater = (cell, row) => {
		return <label>{parseInt(row.pupil_dist) + ' cm'}</label>;
	};
	CaptureFormater = (cell, row) => {
		return (
			<div>
				<img
					src={row.capture_url}
					className='img-fluid'
					style={this.Style.image}
					alt='No Image'
				/>
			</div>
		);
	};
	LeftEyeFormatter = (cell, row) => {
		const add = parseInt(row.user_age);
		return (
			<label>
				{row.left_eye}{this.handleAge(add)}
			</label>
		);
	};
	RightEyeFormatter = (cell, row) => {
		const add = parseInt(row.user_age);
		return (
			<label>
				{row.right_eye}{this.handleAge(add)}
			</label>
		);
	};
	ComputerFormater = (cell, row) => {
		let text = '';
		if (row.computer_class === 1) {
			text = 'kurang dari 2 jam';
		} else if (row.computer_class === 2) {
			text = '2 - 5 jam';
		} else {
			text = ' lebih dari 5 jam';
		}
		return <label>{text}</label>;
	};
	handleAge = (age) => {
		if (age < 40) {
			return null;
		}
		if (age >= 40 && age <= 41) {
			return '| (add) 1.00';
		}
		if (age >= 42 && age <= 44) {
			return '| (add) 1.25';
		}
		if (age >= 45 && age <= 46) {
			return '| (add) 1.5';
		}
		if (age >= 47 && age <= 48) {
			return '| (add) 1.75';
		}
		if (age >= 49 && age <= 51) {
			return '| (add) 2.00';
		}
		if (age >= 52 && age <= 54) {
			return '| (add) 2.25';
		}
		if (age >= 55 && age <= 56) {
			return '| (add) 2.50';
		}
		if (age >= 57 && age <= 58) {
			return '| (add) 2.75';
		}
		if (age > 58) {
			return '| (add) 3';
		}
	};
	render() {
		const pagination = paginationFactory({
			page: 1,
			sizePerPage: 5,
			firstPageText: 'First',
			lastPageText: 'Last',
			nextPageText: 'Next',
			prePageText: 'Previous',
			showTotal: true,
			alwaysShowAllBtns: true,
			onPageChange: function (page, sizePerPage) {},
			onSizePerPageChange: function (page, sizePerPage) {},
		});
		return (
			<>
				<ToolkitProvider
					bootstrap4
					keyField='test_id'
					data={this.data_test_user}
					columns={this.data_column}
					search
				>
					{(props) => (
						<div>
							<BootstrapTable
								id='test_table'
								striped
								hover
								condensed
								pagination={pagination}
								{...props.baseProps}
								classes='table table-borderless'
							/>
						</div>
					)}
				</ToolkitProvider>
				<ModalDetail
					show={this.state.showDetail}
					close={() => this.setState({ showDetail: false })}
					data={this.state.data_row}
				/>
			</>
		);
	}
}
