import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';

export default class ExitNoSave extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
            success:false
        }
    }

    quit = () =>{
        this.setState({
            message:'',
            message_type:'',
            success:false
        })
        this.props.close()
    }

    saveChange = ()=>{
        this.setState({
            message:'',
            message_type:'',
            success:false
        })
        this.props.close()
        this.props.savechange()
    }

  render() {
    return (
        <Modal
        show={this.props.show}
        onHide={this.quit}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Keluar form</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex justify-content-center'>
                    <h5>Perubahan belum tersimpan, apakah anda yakin ingin meninggalkan laman ini ?</h5>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-end'>
                <button className='btn btn-secondary' onClick={this.quit}>Tidak</button>
                &ensp;
                <button className='btn btn-primary' onClick={this.saveChange}>Iya</button>)
                </div>
            </Modal.Footer>
        </Modal>
    )
  }
}
