import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter } from 'react-router-dom';
import AuthService from '../api_getter/auth_service';
import ForceLogin from './force_login';
import cookie from 'react-cookies'

export class LoginTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_data:'',
            password:'',
            loggin_status:false,
            error:'',
            force:false
            
        }

    }

    handleLogin=(event)=>{
        
        event.preventDefault();
        
        AuthService.admin_login(this.state.user_data,this.state.password)
        .then( (data) => {
            
            if (data.results){
                this.props.Login_success()
            }
            else {
                if(data.error.code === 208){
                    this.setState({force:true})
                }
                else{
                    this.setState({error:data.error.message});
                }
                

            };
        });
    }
    handleChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        });
    }
    gotoForgot = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/forgot`)
    }

    SendForce = () =>{
        AuthService.force_login(this.state.user_data,this.state.password)
        .then( (data) =>{
            if(data.results){
                cookie.save('data_force', JSON.stringify(data), { path: '/' })
                this.props.history.push(`${process.env.PUBLIC_URL}/auth/otpforce`)
            }
            else{
                this.setState({error:data.error.message});
            }
        })
    }


    render() {
        
        return (
            <div>
                <Fragment>
                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon" >
                            <Tab className="nav-link"><User />Login</Tab>
                        </TabList>

                        <TabPanel>
                            <form className="form-horizontal auth-form" onSubmit={this.handleLogin}>
                                {this.state.error && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error}
                                    </div>
                                </div>
                                )}
                                <div className="form-group">
                                    <input required="" name="user_data" type="text" className="form-control" placeholder="Username" onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <input required="" name="password" type="password" className="form-control" placeholder="Password" onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    &ensp;<a href='javascript:void(0)' onClick={this.gotoForgot}>Lupa Password</a>
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit">Login</button>
                                </div>
                              
                            </form>
                        </TabPanel>
                    </Tabs>
                    <ForceLogin show={this.state.force} close={()=>this.setState({force:false})}
                    SendForce={this.SendForce}
                    />
                </Fragment>
            </div>
        )
    }
}

export default withRouter(LoginTabset)

