import React, { Component, Fragment } from 'react'
import AuthService from '../../api_getter/auth_service';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Breadcrump from '../../common/breadcrumb';
import {ExternalLink,Edit2,Trash2} from 'react-feather';
import { Link } from 'react-router-dom';
import EditStatusLogoLens from './edit_status_logo';
import HapusLogoLens from './hapus_logo';
import cookie from 'react-cookies';

export default class List_brand_lensa extends Component {
constructor(props){
    super(props);

    this.state={
        data_loaded:false,
        data_row:{},
        message:'',
        message_type:'',
        showDetail:false,
        showEdit:false,
        showHapus:false,
    }
    this.data_all_lensa = []
    this.data_column=[
    {dataField:'id_brand_lens',hidden:'true'},
    {dataField:'logo',text:'Logo',formatter:this.LogoFormater},
    {dataField:'nama_brand',text:'Merek'},
    {dataField:'status',text:'Status',formatter:this.StatusFormater},
    {dataField:'actions',text:'Aksi',formatter:this.ActionFormater}
    ]
    this.Style = {
      image: {
        background: '#fefefe',
        height:25,
        width:25
      }
    }
} 

LogoFormater = (cell,row) => {
    return(
      <div>
        <img src={row.media_url} className='img-fluid' style={this.Style.image} alt='no logo'/>
      </div>
    )
  }
StatusFormater = (cell,row) => {
    let status = row.active === 1?'Active':'Inactive';
    return(
      status
    )
  }

goToDetail = (row) => {
    cookie.save('lens_brand_detail', row, { path: '/' });
    this.props.history.push(`${process.env.PUBLIC_URL}/details-lens-brands`);
}
ActionFormater = (cell,row) => {
    return(
      <div >
        {/* <Link to={{
                    pathname:`/details-lens-brands`,
                    state:{row}
                    }}>
            <ExternalLink color='grey' size='20'/>
        </Link> */}
        <a href="javascript:void(0)" onClick={()=>this.goToDetail(row)}>
            <ExternalLink size='20'/>
        </a>
        &ensp;&ensp;
        <a href='javascript:void(0)' onClick={()=>this.setState({showEdit:true,data_row:row})}>
          <Edit2 color='green' size='20'/></a>
        &ensp;&ensp;
        <a href='javascript:void(0)'
        onClick={()=>this.setState({showHapus:true,data_row:row})}
        ><Trash2 color='red' size='20'/></a>
      </div>
    )
  }
loadAllBrand = () =>{
    AuthService.getAllBrandLens().then(
      (data) => {
        if (data.results){
          
          this.data_all_lensa = data.results;
          this.setState({data_loaded:true});
        }
        else{
          this.setState({message:data.error.message,message_type:'alert-danger'})
        }
    
       
      } 
    )
  }
newBrand = () =>
  {
      this.props.history.push("/new-lens-brands")    
  }

componentDidMount(){
    this.loadAllBrand()
    }
    
render() {
    const { SearchBar, ClearSearchButton } = Search;
    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {

      },
      onSizePerPageChange: function (page, sizePerPage) {
      }
    });
    return (
      <Fragment>
          <Breadcrump title='Merek Lensa' parent="Products" />
          <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h5>Semua Merek</h5>
                    <div className="float-sm-right">
                    <button type="button" className="btn btn-primary" onClick={this.newBrand}>Tambah Merek Baru</button>
                </div>
                </div>
                <div className='card-body'>
                <ToolkitProvider
              bootstrap4
              keyField='brand_id'
              data={this.data_all_lensa}
              columns={this.data_column}
              search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      
                      
                      <BootstrapTable
                      id = 'brand_lens'
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes='table table-borderless'
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
                </div>
            </div>
          </div>
        <EditStatusLogoLens show={this.state.showEdit} status={this.state.data_row.active} id={this.state.data_row.id_brand_lens}
            close={()=>this.setState({showEdit:false})} reload={this.loadAllBrand}/>
        <HapusLogoLens show={this.state.showHapus} close={()=>this.setState({showHapus:false})} 
            id = {this.state.data_row.id_brand_lens} product={this.state.data_row.nama_brand} 
            reload={this.loadAllBrand}/>
      </Fragment>
    )
  }
}
