import React,{useState} from 'react';
import { SketchPicker } from 'react-color';
import {Modal} from 'react-bootstrap';

const ColorPicker = (props) =>{
  const [color, setcolor] = useState('#fff')
  const selectColor = (color) =>{setcolor(color.hex)}
  const quitandsave = () =>{
    props.changeColor(color)
    props.close()
  }
  return(
    <Modal
          show={props.show}
          onHide={props.close}
          backdrop="static"
          keyboard={false}
          centered
          size="sm"
    >
    <Modal.Header closeButton>
        <Modal.Title>Pilih Warna</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <SketchPicker
      color={color}
      onChange={selectColor}
      />
    </Modal.Body>
    <Modal.Footer>
    <button className='btn btn-primary' onClick={quitandsave}>Save</button>
    </Modal.Footer>
    </Modal>
    
  )
}
export default ColorPicker;