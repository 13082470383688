import DeleteCampaign from './delete-campaign';
import {Link} from 'react-router-dom';
import React, {Component, Fragment} from 'react';
import AuthService from '../../api_getter/auth_service';
import Breadcrumb from '../../common/breadcrumb';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cookie from 'react-cookies';
import {ExternalLink, Edit2, Trash2} from 'react-feather';

export default class AllLoyalty extends Component {
    constructor(props) {
        super(props);
        this.timeout = undefined

        this.state = {
            data_loaded: false,
            data_all_campaign: [],
            data_row: [],
            message: '',
            message_type: '',
            showEdit: false,
            showHapus: false,
            page: 1,
            limit: 10,
            total_data: 0,
            q: '',
            searchTimeout: undefined
        };
        this.data_column = [
            {dataField: 'id', text: 'ID', hidden: 'true'},
            {dataField: 'name', text: 'Nama'},
            {dataField: 'code', text: 'ID'},
            {
                text: 'Durasi',
                formatter: this.Duration,
            },
            {
                text: 'Kuota',
                formatter: this.Quota,
            },
            {
                text: 'Status',
                formatter: this.Status,
            },
            {
                dataField: 'actions',
                text: 'Aksi',
                formatter: this.ActionFormater,
            },
        ];
        this.Style = {
            image: {
                background: '#fefefe',
                height: 25,
                width: 25,
            },
        };
    }

    Duration = (cell, row) => {
        return (
            <div>
                From {row.start_date} {row.start_time}
                <br/>
                To {row.end_date} {row.end_time}
            </div>
        );
    };

    Quota = (cell, row) => {
        return <div>{row.redeemed} / {row.value_type_limit_promo} used</div>;
    };

    Status = (cell, row) => {
        // return <div>{row.status.value}</div>;
        return (
            <div>
                {row.status.value === 1 ? (
                    <div className="rounded text-center px-2 py-1 border border-success">
                        Aktif
                    </div>
                ) : (
                    <div className="rounded text-center px-2 py-1 border border-success">
                        Draft
                    </div>
                )}
            </div>
        );
    };

    componentDidMount() {
        this.loadAllCampaign();
    }

    loadAllCampaign = (reset) => {
        if (reset) {
            this.setState({page: 1})
        }
        AuthService.getAllCampaign({
            q: this.state.q,
            page: this.state.page,
            limit: this.state.limit,
        }).then((res) => {
            if (res.success) {
                this.setState({
                    data_all_campaign: res.data.data,
                    data_loaded: true,
                    total_data: res.data.total,
                });
            } else {
                this.setState({
                    message: res.message,
                    message_type: 'alert-danger',
                });
            }
        });
    };

    handleEdit = (data) => {
        localStorage.setItem('detail_campaign', JSON.stringify(data));
        this.setState({showEdit: true, data_row: data});
    };

    ActionFormater = (cell, row) => {
        return (
            <div>
                <Link to={`/view-campaign/${row.id}`}>
                    <ExternalLink size="20"/>
                </Link>
                &ensp;&ensp;
                <Link to={`/edit-campaign/${row.id}`}>
                    <Edit2 color="green" size="20"/>
                </Link>
                &ensp;&ensp;
                <a
                    href="javascript:void(0)"
                    onClick={() => this.setState({showHapus: true, data_row: row})}
                >
                    <Trash2 color="red" size="20"/>
                </a>
            </div>
        );
    };

    newProduct = () => {
        this.props.history.push('/add-campaign');
    };

    closeEditStatus = () => {
        this.setState({showEdit: false});
    };

    render() {
        const {SearchBar, ClearSearchButton} = Search;
        const handlePage = (limit, page) => {
            this.setState({
                page: page,
                limit: limit,
            });
            setTimeout(() => {
                this.loadAllCampaign();
            }, 3e2)
        };
        const options = {
            page: this.state.page,
            sizePerPage: this.state.limit,
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: this.state.total_data,
            onPageChange: function (page, limit) {
                handlePage(limit, page);
            },
            onSizePerPageChange: function (limit, page) {
                handlePage(limit, page);
            },
        };
        const pagination = paginationFactory(options);
        const handleChanged = (e) => {
            console.log(e.target);
            this.setState({ q: e.target.value });
            clearTimeout(this.state.searchTimeout)
            this.setState({searchTimeout: undefined}) 
            this.setState({searchTimeout: setTimeout(() => {
                this.loadAllCampaign(true);
            }, 3e2)}) 
        };
        const clearSearch = (e) => {
            this.setState({ q: '' });
            clearTimeout(this.state.searchTimeout)
            this.setState({searchTimeout: undefined}) 
            this.setState({searchTimeout: setTimeout(() => {
                this.loadAllCampaign(true);
            }, 3e2)}) 
        };

        return (
            <Fragment>
                <Breadcrumb title="CAMPAIGN MANAGEMENT" parent="Campaign"/>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5>Daftar Campaign</h5>
                            <div>
                                <Link to="/add-campaign">
                                    <button type="button" className="btn btn-primary">
                                        Tambah Campaign
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                        <div className="d-flex">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.q}
                                    onChange={handleChanged}
                                    placeholder="Search"
                                    style={{width: 'auto'}}
                                />
                                <button
                                    className="btn btn-default"
                                    onClick={clearSearch}
                                >
                                    CLEAR
                                </button>
                            </div>

                            <ToolkitProvider
                                bootstrap4
                                keyField="user_id"
                                data={this.state.data_all_campaign}
                                columns={this.data_column}
                            >
                                {(props) => (
                                    <div>
                                        <BootstrapTable
                                            id="user_table"
                                            striped
                                            hover
                                            condensed
                                            pagination={pagination}
                                            remote
                                            onTableChange={() => {
                                            }}
                                            {...props.baseProps}
                                            classes="table table-borderless"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
                <DeleteCampaign
                    show={this.state.showHapus}
                    close={() => this.setState({showHapus: false})}
                    id={this.state.data_row.id}
                    product={this.state.data_row.name}
                    reload={() => {
                        this.loadAllCampaign(true);
                    }}
                />
            </Fragment>
        );
    }
}
