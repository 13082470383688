import React, { Component, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Download } from 'react-feather';
import ReactToPrint from 'react-to-print';
import AuthService from '../../api_getter/auth_service';

const ChildInvoices = (props) => {
  const data = props.data;
  const varianFrame = JSON.parse(JSON.parse(data.frame_varians)) || null;
  const varianLens = JSON.parse(JSON.parse(data.lens_varians)) || null;
  return (
    <tbody>
      {varianFrame != null && (
        <tr>
          <td>{data.product_name}</td>
          <td>{data.count}</td>
          <td>{'Rp. ' + data.price_sale}</td>
          <td>{'Rp. ' + data.price_total}</td>
        </tr>
      )}
      {varianFrame != null && (
        <tr>
          <td>Product Color</td>
          <td className="d-flex align-items-center" colSpan="3">
            <div
              style={{
                background: varianFrame.color_code,
                width: '15px',
                height: '15px',
                marginRight: '5px',
              }}
            />
            {varianFrame.nama_warna || varianFrame.color_code}
          </td>
        </tr>
      )}
      {varianLens != null && (
        <tr>
          <td>{data.lens_name}</td>
          <td>{data.count_lens}</td>
          <td>{'Rp. ' + data.price_sale_lens}</td>
          <td>{'Rp. ' + data.price_total_lens}</td>
        </tr>
      )}
      {varianLens != null && (
        <tr>
          <td>
            <div className="d-flex">
              <div className="w-100">Kiri</div>
              <div className="w-100">Kanan</div>
            </div>
            <div className="d-flex">
              {varianLens.kiri.plus != null && (
                <div className="w-100">
                  Plus :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.plus}
                  </span>
                </div>
              )}
              {varianLens.kanan.plus != null && (
                <div className="w-100">
                  Plus :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.plus}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.minus != null && (
                <div className="w-100">
                  Minus :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.minus}
                  </span>
                </div>
              )}
              {varianLens.kanan.minus != null && (
                <div className="w-100">
                  Minus :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.minus}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.silinder != null && (
                <div className="w-100">
                  Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.silinder}
                  </span>
                </div>
              )}
              {varianLens.kanan.silinder != null && (
                <div className="w-100">
                  Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.silinder}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.axis_cyl != null && (
                <div className="w-100">
                  Axis Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.axis_cyl}
                  </span>
                </div>
              )}
              {varianLens.kanan.axis_cyl != null && (
                <div className="w-100">
                  Axis Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.axis_cyl}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.axis != null && (
                <div className="w-100">
                  Axis Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.axis}
                  </span>
                </div>
              )}
              {varianLens.kanan.axis != null && (
                <div className="w-100">
                  Axis Silinder :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.axis}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.addition_left != null && (
                <div className="w-100">
                  Addition :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.addition_left}
                  </span>
                </div>
              )}
              {varianLens.kanan.addition_right != null && (
                <div className="w-100">
                  Addition :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.addition_right}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex">
              {varianLens.kiri.addition_plus != null && (
                <div className="w-100">
                  Addition :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kiri.addition_plus}
                  </span>
                </div>
              )}
              {varianLens.kanan.addition_plus != null && (
                <div className="w-100">
                  Addition :{' '}
                  <span className="font-weight-bold">
                    {varianLens.kanan.addition_plus}
                  </span>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

class DetailTrx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices_render: [],
    };
  }

  render() {
    let data = this.props.data;
    const meta = JSON.parse(data.meta);
    let invoices = [];
    const rewards = data?.detail_reward;
    // const detail_reward = data?.detail_reward?.length ? data?.detail_reward[0] : {}
    let shipping_new =
      data.detail_shipping_new &&
      data.detail_shipping_new[0] &&
      data.detail_shipping_new[0].to !== undefined
        ? data.detail_shipping_new[0].to
        : {};
    let shipping =
      data.detail_shipping !== undefined ? data.detail_shipping[0] : {};
    let invoices_render = [];
    let subtotal_harga = 0;
    if (data.detail_invoices !== undefined) {
      invoices = data.detail_invoices;
      invoices.forEach((element, index) => {
        invoices_render.push(
          <ChildInvoices key={index} data={element} numid={index} />
        );
        subtotal_harga +=
          (element.price_total || 0) + (element.price_total_lens || 0);
      });
    }

    function isDiscountPrice(reward) {
      return !!reward?.discount_price;
    }

    function grandTotal() {
      const dataCalc =
        shipping.price +
        subtotal_harga +
        meta.cod_fee_amount +
        meta.insurance_amount;
      let total = dataCalc;
      let discount = 0;
      // navigator.clipboard.writeText(JSON.stringify(data))
      // console.log(data);

      (rewards || []).forEach((reward) => {
        let meta = reward?.meta ? JSON.parse(reward?.meta) || {} : {};
        if (reward?.type_promo === 1) {
          total =
            total -
            (subtotal_harga * parseFloat(reward?.value_type_promo)) / 100;
          discount =
            (subtotal_harga * parseFloat(reward?.value_type_promo)) / 100;
        } else if (reward?.type_promo === 2) {
          total = total - parseFloat(reward?.value_type_promo);
          discount = reward?.value_type_promo;
        }

        if (parseFloat(reward?.discount_percentage) >= 100) {
          if (meta?.cod_fee_amount) {
            total =
              parseFloat(meta?.cod_fee_amount) +
              parseFloat(meta?.insurance_amount) +
              parseFloat(shipping.price);
          } else {
            total =
              parseFloat(meta?.insurance_amount) + parseFloat(shipping.price);
          }
          discount = subtotal_harga;
        } else if (reward.type == 'reward') {
          const invoices = data?.detail_invoices;
          discount = 0;
          if (reward.frame_id) {
            const p = invoices.find(
              (v) => parseInt(v.products_id) === parseInt(reward.frame_id)
            );
            if (p) {
              const d =
                parseFloat(reward.discount_price ?? 0) +
                parseFloat((reward.discount_percentage * p.price_total) / 100);
              const d2 =
                meta?.is_max_perecentage == 0 &&
                d > meta?.discount_percentage_max_value
                  ? parseInt(meta?.discount_percentage_max_value)
                  : d;
              discount += d2;
            }
          }
          if (reward.lens_id) {
            const p = invoices.find(
              (v) => parseInt(v.lens_id) === parseInt(reward.lens_id)
            );
            if (p) {
              const d =
                parseFloat(reward.discount_price ?? 0) +
                parseFloat(
                  (reward.discount_percentage * p.price_total_lens) / 100
                );
              const d2 =
                meta?.is_max_perecentage == 0 &&
                d > meta?.discount_percentage_max_value
                  ? parseInt(meta?.discount_percentage_max_value)
                  : d;
              discount += d2;
            }
          }
          // console.log(discount)
          total -= discount;
        }
      });
      if (total < 1) {
        return 0;
      }
      const trxMeta = JSON.parse(data.meta) ?? {};
      if (trxMeta?.discount_amount) discount = trxMeta.discount_amount;

      return {
        total: dataCalc - discount,
        discount,
      };
    }

    function OrderId(props) {
      const data = props.data;
      const meta = JSON.parse(data.meta);
      const invoices = data.detail_invoices;

      if (meta.shipping_options === 'cod')
        return (
          <tr>
            <th>Order ID</th>
            <td>
              {invoices.map((v) => {
                return <div>{v.order_id}</div>;
              })}
            </td>
          </tr>
        );
      return <></>;
    }

    return (
      <div className="container-fluid">
        <table style={{ width: 100 + '%' }}>
          <tr>
            <th>Tanggal + tt</th>
            <td>{data.date_updated}</td>
            <th>Tujuan Pengiriman</th>
          </tr>
          <tr>
            <th>No. Invoice</th>
            <td>
              <div>{data.invoice_number}</div>
            </td>
            <td>
              {data.full_name}
              <br />
              {shipping_new.address ? shipping_new.address + ', ' : ''}
              {shipping_new.subdistrict ? shipping_new.subdistrict : ''}
              {(!shipping_new.address && !shipping_new.subdistrict) || <br />}
              {shipping_new.city ? shipping_new.city + ', ' : ''}
              {shipping_new.province ? shipping_new.province + ' ' : ''}
              {(!shipping_new.city && !shipping_new.province) || <br />}
              {shipping_new.postal_code ? shipping_new.postal_code : ''}
            </td>
          </tr>
          <OrderId data={data} />
        </table>
        <br />
        <table style={{ width: 100 + '%' }}>
          <div
            style={{
              border: 1 + 'px solid',
              borderColor: 'grey',
              color: 'grey',
              padding: 5 + 'px',
              borderRadius: 5 + 'px',
            }}
          >
            <thead>
              <th style={{ paddingRight: 150 }}>Nama Produk</th>
              <th style={{ paddingRight: 20 }}>Jumlah</th>
              <th style={{ paddingRight: 200 }}>Harga</th>
              <th>Subtotal</th>
            </thead>
            {invoices_render}
            <tr>
              <td>Note</td>
              <td className="d-flex align-items-center" colSpan="3">
                {data.notes}
              </td>
            </tr>
            <hr style={{ width: 100 + '%' }} />
            <thead>
              <th style={{ paddingLeft: 250 }}>Total Harga</th>
              <th style={{ paddingLeft: 235 }}>{'Rp. ' + subtotal_harga}</th>
            </thead>
          </div>
        </table>
        <br />
        <table style={{ width: 100 + '%', tableLayout: 'fixed' }}>
          <div
            style={{
              border: 1 + 'px solid',
              borderColor: 'grey',
              color: 'grey',
              padding: 5 + 'px',
              borderRadius: 5 + 'px',
            }}
          >
            <thead className="text-left">
              <th style={{ width: 40 + '%' }}>{shipping.shipper_name}</th>
              <th style={{ width: 40 + '%' }}>Total Harga</th>
              <th style={{ width: 20 + '%', paddingLeft: 59 }}>
                {'Rp. ' + shipping.price}
              </th>
            </thead>
          </div>
        </table>
        <br />
        {meta !== null && (
          <div>
            <table style={{ width: 100 + '%', tableLayout: 'fixed' }}>
              <div
                style={{
                  border: 1 + 'px solid',
                  borderColor: 'grey',
                  color: 'grey',
                  padding: 5 + 'px',
                  borderRadius: 5 + 'px',
                }}
              >
                <thead className="text-left">
                  <th style={{ paddingRight: 180 }}>Biaya Cod</th>
                  <th style={{ paddingRight: 232 }}>Total Harga</th>
                  <th>{'Rp. ' + meta.cod_fee_amount}</th>
                </thead>
              </div>
            </table>
            <br />
            {/* <div>{JSON.stringify(grandTotal())}</div> */}
            {!!grandTotal().discount && (
              <>
                <table
                  style={{
                    width: 100 + '%',
                    tableLayout: 'fixed',
                  }}
                >
                  <div
                    style={{
                      border: 1 + 'px solid',
                      borderColor: 'grey',
                      color: 'grey',
                      padding: 5 + 'px',
                      borderRadius: 5 + 'px',
                    }}
                  >
                    <thead className="text-left">
                      <th style={{ paddingRight: 135 }}>
                        Potongan Kupon -{' '}
                        {data?.detail_reward
                          ?.map((v) => v.promo_code)
                          .join(', ') ||
                          data?.detail_reward?.map((v) => v.code).join(', ')}
                      </th>
                      <th style={{ paddingRight: 235 }}>Total Harga</th>
                      {<th>{'Rp. -' + this.props.detail.discount}</th>}
                    </thead>
                  </div>
                </table>
                <br />
              </>
            )}
            <table style={{ width: 100 + '%', tableLayout: 'fixed' }}>
              <div
                style={{
                  border: 1 + 'px solid',
                  borderColor: 'grey',
                  color: 'grey',
                  padding: 5 + 'px',
                  borderRadius: 5 + 'px',
                }}
              >
                <thead className="text-left">
                  <th style={{ paddingRight: 148 }}>Biaya Asuransi</th>
                  <th style={{ paddingRight: 232 }}>Total Harga</th>
                  <th>{'Rp. ' + this.props.detail.insurance_cost}</th>
                </thead>
              </div>
            </table>
            <br />
          </div>
        )}
        <table style={{ width: 100 + '%', tableLayout: 'fixed' }}>
          <div
            style={{
              border: 1 + 'px solid',
              borderColor: 'grey',
              color: 'grey',
              padding: 5 + 'px',
              borderRadius: 5 + 'px',
            }}
          >
            <thead className="text-left">
              <th style={{ paddingRight: 217 }}>Total</th>
              <th style={{ paddingRight: 190 }}>Total Pembayaran</th>
              <th>
                {'Rp. ' + this.props.detail.total}
              </th>
            </thead>
          </div>
        </table>
      </div>
    );
  }
}

export default class DetailTrigger extends Component {
  render() {
    const handleSetStatus = (status) => {
      AuthService.setStatus(this.props.data.invoice_number, status).then(
        (response) => {
          if (response.status === 'successfully') {
            window.location.reload();
          }
        }
      );
    };
    const data = this.props.data;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice {data.invoice_number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailTrx
            show={this.props.show}
            close={this.props.close}
            data={this.props.data}
            detail={this.props.detail}
            ref={(el) => (this.componentRef = el)}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          {this.props.data.status === 'pending' ? (
            <Button onClick={() => handleSetStatus('settlement')}>
              Set to Settlement
            </Button>
          ) : this.props.data.status === 'shipping' ? (
            <Button onClick={() => handleSetStatus('success')}>
              Set to Success
            </Button>
          ) : this.props.data.status === 'success' ? (
            <Button onClick={() => handleSetStatus('complete')}>
              Set to Complete
            </Button>
          ) : null}
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-outline-primary">
                <Download /> &ensp;Download
              </button>
            )}
            content={() => this.componentRef}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
