import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {Upload, CheckCircle, XOctagon} from 'react-feather';
import { css } from "@emotion/core";
import Loader from "react-spinners/ClockLoader";
import List_media from "./list-media";
import List_photo from "./list-media-foto";

import AuthService from '../api_getter/auth_service';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export class Media extends Component {
    constructor(props){
        super(props);

        this.state={
            data_state:'empty',
            file_name:'',
            files:null,
            message:'',
            message_type:''
        }
    }

    onChangeInput = (e) =>{
        let file = e.target.files[0] ;
        let name = file.name
        this.setState({data_state:"loaded",file_name:name,files:file})
    }

    resetInput = () =>{
        this.setState({
            data_state:'empty',
            file_name:'',
            files:null,
            message:'',
            message_type:''
        })
    }

    uploadFile = () =>{
        this.setState({data_state:"uploading"})
        this.sendFile()
    }

    sendFile = () =>{
        AuthService.Upload3D(this.state.files)
        .then((data)=>{
            if(data.results){
                this.setState({data_state:"success"})
              }
              else{
                this.setState({
                    data_state:"failed",
                    message:data.error.message
                })
              }
        })
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Media" parent="" />
                <div className="container-fluid bulk-cate">
                    <div className="card ">
                        <div className="card-header">
                            <h5>Unggah file</h5>
                        </div>
                        <div className="card-body">
                            <div className='custom_upl_matta'>
                            {this.state.data_state === "loaded"&&(
                                <div>
                                    <label><h4><strong>{this.state.file_name}</strong></h4></label>
                                    <br/>
                                    <button className="btn btn-primary" onClick={this.uploadFile}>
                                        Upload
                                    </button>
                                    <button className="btn btn-secondary" onClick={this.resetInput}>
                                        Reset
                                    </button>
                                </div>
                            )}
                            
                            {this.state.data_state === "empty"&&(
                                <div>
                                    <label for="file-input"><i><Upload/></i></label>
                                    <input id="file-input" type="file" onChange={this.onChangeInput} accept="image/*,.gltf"/>
                                    <br/><small>Ukuran file maksimal 20MB</small>
                                </div>
                            )}
                            
                            {this.state.data_state === "uploading"&&(
                                
                                <Loader
                                css = {override}
                                size = {100}
                                color = {'#007bff'}    
                                />
                            )}

                            {this.state.data_state === "success"&&(
                                
                                <div>
                                    <CheckCircle color="#18762e" height="50" width="50" />
                                    <br/>
                                    <p>upload berhasil <a href='javascript:void(0)' onClick={this.resetInput}>upload file lain</a>
                                    </p>
                                </div>
                            )}

                            {this.state.data_state === "failed"&&(
                                
                                <div>
                                    <XOctagon color="#18762e" height="50" width="50" />
                                    <br/>
                                    <p>{this.state.message}<a href='javascript:void(0)' onClick={this.resetInput}> coba lagi</a>
                                    </p>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h5>Daftar File 3D</h5>
                        </div>
                        <div className="card-body">
                            <List_media data_state={this.state.data_state}/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>Daftar File Gambar</h5>
                        </div>
                        <div className="card-body">
                            <List_photo data_state={this.state.data_state}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Media
