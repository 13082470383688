import React, { Component } from 'react'
import {Modal} from 'react-bootstrap';
import AuthService from '../api_getter/auth_service';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class ChangeRole extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
            admin_role:'',
            edit:false
        }
    }

    selectOption = (e) =>{
        let edt = e.value !== this.props.admin_role
        this.setState({
          admin_role:e.value,edit:edt
        })
      }

    changeRole = () =>{
        AuthService.changeRoleAdmin(this.props.admin_id,this.state.admin_role)
        .then((data)=>{
            if(data.results){
                this.quit()
                this.props.history.push(`${process.env.PUBLIC_URL}/users/admins`);
            }
            else{
                this.setState({message:data.error.message,message_type:'alert-danger'})
            }
        })
    }
    quit = () =>{
        this.setState({
            message:'',
            message_type:'',
            admin_role:'',
            edit:false
        })
        this.props.close()
    }

  render() {
    const department = [
        { value: 'superadmin', label: 'Super Admin' },
        { value: 'admin_user', label: 'Manager Pengguna' },
        { value: 'admin_product', label: 'Manager Produk' },
        { value: 'admin_trx', label: 'Manager Transaksi' },
      ]
    return (
        <Modal
            show={this.props.show}
            onHide={this.quit}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
            >
            <Modal.Header closeButton>
                <Modal.Title>Ganti Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message?(
                <div className={'alert '+this.state.message_type } role='alert'>
                    {this.state.message}
                </div>
                ):(
                <div className='row'>
                    <div className='col-md-4'>
                    <label>Ganti Role Admin</label>
                    </div>
                    <div className='col-md-8'>
                    <Select
                          components={animatedComponents}
                          options={department}
                          onChange={this.selectOption}
                          name="admin_role"
                          placeholder="Pilih role admin"
                          /> 
                    </div>
                </div>)}
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary'
                onClick={this.quit}
                >Batal</button>

                {this.state.edit&&(<button className='btn btn-primary'
                onClick={this.changeRole}
                >Simpan</button>)}
            </Modal.Footer>

        </Modal>
    )
  }
}
export default withRouter(ChangeRole)