import React, {Component, Fragment} from "react";
import Breadcrumb from "./common/breadcrumb";
import {Box, Users, ShoppingCart, Home, Truck, Gift} from "react-feather";
import CountUp from "react-countup";
import AuthService from "./api_getter/auth_service";
import PopGlobal from "./error/response_global";
import cookie from "react-cookies";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {ExternalLink} from "react-feather";
import {Link} from "react-router-dom";

export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            message_type: "",
            users: 0,
            products: 0,
            pending: 0,
            process: 0,
            success: 0,
            sending: 0,
            show: false,
            code: 0,
        };
        this.data_popular = [];
        this.data_column = [
            {dataField: "id", text: "ID"},
            {dataField: "image", text: "Perview", formatter: this.LogoFormater},
            {dataField: "name", text: "Product Title"},
            {dataField: "brand", text: "Brand"},
            {dataField: "total", text: "Total"},
            //   { dataField: "url", text: "Aksi", formatter: this.ActionFormater },
        ];
        this.Style = {
            image: {
                background: "#fefefe",
                height: 75,
                width: 100,
            },
        };
    }

    componentDidMount() {
        this.LoadDashboard();
        this.LoadUserData();
    }

    LogoFormater = (cell, row) => {
        return (
            <div>
                <img
                    src={row.image}
                    className="img-fluid"
                    style={this.Style.image}
                    alt="no logo"
                />
            </div>
        );
    };

    //   ActionFormater = (cell, row) => {
    //     return (
    //       <div>
    //         <a href="javascript:void(0)" onClick={() => this.goToDetail(row)}>
    //           <ExternalLink size="20" />
    //         </a>
    //       </div>
    //     );
    //   };

    //   goToDetail = (row) => {
    //     this.props.history.push(`${process.env.PUBLIC_URL}/product=detail/${row.id}`);
    //   };

    LoadDashboard = () => {
        AuthService.dashboard().then((data) => {
            if (data.results) {
                this.setState({
                    users: parseInt(data.results.users),
                    products: parseInt(data.results.products),
                    pending: parseInt(data.results.pending),
                    process: parseInt(data.results.process),
                    success: parseInt(data.results.success),
                    sending: parseInt(data.results.sending),
                });
            } else {
                this.setState({
                    show: true,
                    message: data.error.message,
                    message_type: "alert-danger",
                    code: data.error.code,
                });
            }
        });
    };

    LoadUserData = () => {
        AuthService.popular().then((data) => {
            if (data) {
                this.data_popular = data;
            } else {
                this.setState({
                    message: data.error.message,
                    message_type: "alert-danger",
                    code: data.error.code,
                    show: true,
                });
            }
        });
    };

    goToLogin = () => {
        const array = [306, 305, 412];
        if (array.indexOf(this.state.code) >= 0) {
            cookie.save("user_data", {}, {path: "/"});
            cookie.save("logged_in", JSON.stringify({status: false}), {
                path: "/",
            });
        }
        this.setState({
            message: "",
            message_type: "",
            show: false,
        });
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login`);
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Dashboard" parent=""/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 xl-50">
                            <Link to={'/list-transaction?tab=pending'}>
                                <div className="card o-hidden widget-cards">
                                    <div className="bg-warning card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center">
                                                    <ShoppingCart className="font-warning"/>
                                                </div>
                                            </div>
                                            <div className="media-body col-8">
                                                <span className="m-0">Transaksi masuk</span>
                                                <h3 className="mb-0">
                                                    {" "}
                                                    <CountUp className="counter" end={this.state.pending}/>
                                                    <small> Item</small>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <Link to={'/list-transaction?tab=settlement'}>
                                <div className="card o-hidden  widget-cards">
                                    <div className="bg-secondary card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center">
                                                    <Gift className="font-secondary"/>
                                                </div>
                                            </div>
                                            <div className="media-body col-8">
                                                <span className="m-0">Transaksi sedang diproses</span>
                                                <h3 className="mb-0">
                                                    {" "}
                                                    <CountUp className="counter" end={this.state.process}/>
                                                    <small> Item</small>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <Link to={'/list-transaction?tab=shipping'}>
                                <div className="card o-hidden widget-cards">
                                    <div className="bg-primary card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center">
                                                    <Truck className="font-primary"/>
                                                </div>
                                            </div>
                                            <div className="media-body col-8">
                                                <span className="m-0">Transaksi sedang dikirim</span>
                                                <h3 className="mb-0">
                                                    <CountUp className="counter" end={this.state.sending}/>
                                                    <small> Item</small>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <Link to={'/list-transaction?tab=complete'}>
                                <div className="card o-hidden widget-cards">
                                    <div className="bg-danger card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center">
                                                    <Home className="font-danger"/>
                                                </div>
                                            </div>
                                            <div className="media-body col-8">
                                                <span className="m-0">Transaksi Selesai</span>
                                                <h3 className="mb-0">
                                                    {" "}
                                                    <CountUp className="counter" end={this.state.success}/>
                                                    <small> Item</small>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-success card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center">
                                                <Box className="font-success"/>
                                            </div>
                                        </div>
                                        <div className="media-body col-8">
                                            <span className="m-0">Total Produk</span>
                                            <h3 className="mb-0">
                                                <CountUp
                                                    className="counter"
                                                    end={this.state.products}
                                                />
                                                <small> Produk</small>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-info card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center">
                                                <Users className="font-info"/>
                                            </div>
                                        </div>
                                        <div className="media-body col-8">
                                            <span className="m-0">Total user</span>
                                            <h3 className="mb-0">
                                                {" "}
                                                <CountUp className="counter" end={this.state.users}/>
                                                <small> Orang</small>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToolkitProvider
                        bootstrap4
                        keyField="order_id"
                        data={this.data_popular}
                        columns={this.data_column}
                        search
                    >
                        {(props) => (
                            <div>
                                <BootstrapTable
                                    id={this.props.event}
                                    striped
                                    hover
                                    condensed
                                    {...props.baseProps}
                                    classes="table table-borderless"
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
                <PopGlobal
                    show={this.state.show}
                    close={this.goToLogin}
                    message={this.state.message}
                    message_type={this.state.message_type}
                />
            </Fragment>
        );
    }
}

// javascript:void(0)

export default Dashboard;
