import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Tag, Clipboard, RefreshCw, XOctagon } from 'react-feather';
import AuthService from '../../api_getter/auth_service';
import DetailTrigger from './detail_trx';
import PopGlobal from '../../error/response_global';
import { withRouter } from 'react-router';
import ActionTrx from './action_trx';
import Label from './label';
import Loader from "react-spinners/PuffLoader";
import { jsonParseSafe } from '../../../constants/helpers';

class Tab_child extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tes: 'p',
			loading: false,
			logged_in: false,
			data_loaded: false,
			data_trx: {},
			data_row: {},
			message: '',
			message_type: '',
			sub: '',
			trial_connect: 0,
			show: false,
			show_action: false,
			code: 0,
			showGlobal: false,
			action: '',
			detail_data: {}
		};

		this.data_all_trx = [];
		this.data_column = [
			{ dataField: 'order_id', text: 'ID', hidden: true },
			{ dataField: 'invoice_number', text: 'No.Transaksi' },
			{ dataField: 'date_updated', text: 'Tanggal' },
			{
				text: 'Pembayaran',
				formatter: this.typePayment,
			},
			{
				text: 'Bank',
				formatter: this.bankName,
			},
			{
				dataField: 'actions',
				text: 'Aksi',
				formatter: this.ActionFormater,
			},
		];
	}

	typePayment(cell, row) {
		const meta = jsonParseSafe(row.meta);
		return (
			<div>
				<div>
					{meta === null
						? row.payment_type
						: meta.shipping_options === 'cod'
						? 'COD'
						: row.payment_type}
				</div>
			</div>
		);
	}

	bankName(cell, row) {
		const meta = jsonParseSafe(row.meta);
		return (
			<div>
				<div>
					{meta === null
						? row.issuer_name
						: meta.shipping_options === 'cod'
						? 'COD'
						: row.issuer_name}
				</div>
			</div>
		);
	}

	shouldComponentUpdate(nextProps) {
		let PropsA = this.props.id;
		let PropsB = nextProps.event;

		if (PropsA === PropsB) {
			if (
				this.data_all_trx.length === 0 &&
				this.state.trial_connect <= 0
			) {
				this.setState({ trial_connect: this.state.trial_connect + 1 });
				this.loadData(PropsB);
			}
			return true;
		}
		return this.data_all_trx.length !== 0;
	}
	componentDidMount() {
		if (this.props.id === this.props.event) {
			this.loadData(this.props.id);
		}
	}
	showModal = async (row) => {
		this.setState({loading: true})
		const {data} = await AuthService.getDetailTrx(row.order_id).finally(() => {
			this.setState({loading: false})

		})
		const {
      order,
      order: { reward_detail: reward, invoice: products },
    } = data;
    const meta = JSON.parse(order.meta);
    const sub_total = order.invoice.reduce(
      (car, cur) =>
        car + parseInt(cur.price_total || cur.price_total_lens || 0),
      0
    );
    let discount = 0;
    // ngitung diskon pusing
    if (reward) {
      if (reward.type) {
        const rewardMeta = JSON.parse(reward.meta) || {};

        products.forEach((product) => {
          const hasFrame =
            reward.frame_id && product.products_id == reward.frame_id;
          const hasLens = reward.lens_id && product.lens_id == reward.lens_id;
          if (hasFrame) {
            const frame_discount =
              parseFloat(reward.discount_price ?? 0) +
              parseFloat(
                (reward.discount_percentage * product.price_total) / 100
              );
            discount +=
              rewardMeta?.is_max_perecentage == 0 &&
              frame_discount > rewardMeta?.discount_percentage_max_value
                ? parseInt(rewardMeta?.discount_percentage_max_value)
                : frame_discount;
          }

          if (hasLens) {
            const lens_discount =
              parseFloat(reward.discount_price ?? 0) +
              parseFloat(
                (reward.discount_percentage * product.price_total_lens) / 100
              );
            discount +=
              rewardMeta?.is_max_perecentage == 0 &&
              lens_discount > rewardMeta?.discount_percentage_max_value
                ? parseInt(rewardMeta?.discount_percentage_max_value)
                : lens_discount;
          }
        });
      } else {
        discount = products.reduce((car, cur) => {
          const price = cur.price_total || cur.price_total_lens || 0;
          const hasPromo = reward.products.some((v) => {
            return [cur.lens_id, cur.products_id].includes(v.id);
          });
          if (hasPromo) {
            if (reward.type_promo == 1)
              return (price * reward.value_type_promo) / 100 + car;
            if (reward.type_promo == 2) return reward.value_type_promo + car;
          }

          return car;
        }, 0);
      }
    }
    // if ()
    const shipping_cost =
      parseInt(order.shipping_cost) + parseInt(meta.cod_fee_amount);
		const detail_data = {
      status: order.status,
      order_created: order.date_created,
      invoice: order.invoices,
      insurance_cost: meta?.insurance_amount,
      products,
      note: order.notes,
      shipping_options: meta.shipping_options,
      shipping_cost,
      sub_total,
      payment_method: order.issuer_name,
      discount,
      total: sub_total - discount + shipping_cost + meta.insurance_amount,
    }
		this.setState({show: true, detail_data, data_row: row })
	};
	closeModal = () => {
		this.setState({ show: false });
	};
	showActionModal = (row, action) => {
		this.setState({ show_action: true, action: action, data_row: row });
	};
	closeActionModal = () => {
		this.setState({ show_action: false, action: '' });
	};

	handleGetLabel = (data) => {
		AuthService
            .getLabel(data.invoice_number)
            .then((data) => {
                const isSuccess = data?.status;
                const message = data?.message;
                if(isSuccess) {
                    localStorage.setItem('detail_label', JSON.stringify(data?.data));
                    this.setState({ show_label: true });
                    return;
                }

                alert(message);
            })
            .catch(err => {
                const data = err?.response?.data || null;
                if(data) {
                    const message = data?.message || err;
                    localStorage.setItem('detail_label', null);
                    alert(message);
                }
            });
	};

    handleLabelClose = () => {
        this.setState({ show_label: false });
    }

	ActionFormater = (cell, row) => {
		let meta = jsonParseSafe(row.meta);
		if (meta !== null) {
			meta = meta.shipping_options;
		} else {
			meta = row.payment_type;
		}
		return (
			<div>
				{this.props.id === 'settlement' && meta === 'cod' && (
					<a
						href='javascript:void(0)'
						onClick={() => this.handleGetLabel(row)}
					>
						<Tag color='grey' size='20' />
					</a>
				)}
				<a

					href='javascript:void(0)'
					onClick={() => this.showModal(row)}
				>
					<Clipboard color='grey' size='20' />
				</a>{' '}
				&ensp;&ensp;
				{this.props.id === 'pending' && (
					<a
						href='javascript:void(0)'
						onClick={() => this.showActionModal(row, 'cancel')}
					>
						<XOctagon color='red' size='20' />
					</a>
				)}
				{this.props.id === 'cancel' && (
					<a
						href='javascript:void(0)'
						onClick={() => this.showActionModal(row, 'delete')}
					>
						<XOctagon color='red' size='20' />
					</a>
				)}
			</div>
		);
	};

	reloadData = () => {
		this.loadData(this.props.id);
	};

	loadData = (sub) => {
		AuthService.getTransaction(sub).then((data) => {
			if (data.results) {
				this.data_all_trx = data.results.sort((a,b) => a.order_id < b.order_id ? 1 : -1);
				// this.data_all_trx
				this.setState({ data_loaded: true });
			} else {
				this.setState({
					message: data.error.message,
					message_type: 'alert-danger',
					code: data.error.code,
					showGlobal: true,
				});
			}
		});
	};
	goToDashboard = () => {
		if (this.state.code === 307) {
			this.setState({
				message: '',
				message_type: '',
				data: {},
				data_loaded: false,
				showGlobal: false,
			});
			this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
		} else {
			this.setState({
				message: '',
				message_type: '',
				data: {},
				data_loaded: false,
				showGlobal: false,
			});
		}
	};

	p = () => {
		this.setState({loading: !this.state.loading})
	}

	render() {
		const { SearchBar, ClearSearchButton } = Search;
		const pagination = paginationFactory({
			page: 1,
			sizePerPage: 5,
			firstPageText: 'First',
			lastPageText: 'Last',
			nextPageText: 'Next',
			prePageText: 'Previous',
			showTotal: true,
			alwaysShowAllBtns: true,
			onPageChange: function (page, sizePerPage) {},
			onSizePerPageChange: function (page, sizePerPage) {},
		});
		return (
			<Fragment>
				<ToolkitProvider
					bootstrap4
					keyField='order_id'
					data={this.data_all_trx}
					columns={this.data_column}
					search
				>
					{(props) => (
						<div>
							<div className="d-flex justify-content-center">
							{this.state.loading && <Loader />}
							</div>
							<div className='d-flex flex-row-reverse'>
								<button
									type='button'
									className='btn btn-secondary'
									style={{ height: 37 + 'px' }}
									onClick={this.reloadData}
								>
									<RefreshCw color='white' size='20' />
								</button>
								<SearchBar {...props.searchProps} />
							</div>
							<BootstrapTable
								id={this.props.event}
								loading={ this.state.loading } 
								striped
								hover
								condensed
								pagination={pagination}
								{...props.baseProps}
								classes='table table-borderless'
							/>
						</div>
					)}
				</ToolkitProvider>

				<DetailTrigger
					show={this.state.show}
					close={this.closeModal}
					data={this.state.data_row}
					detail={this.state.detail_data}
				/>
				<ActionTrx
					show={this.state.show_action}
					close={this.closeActionModal}
					data={this.state.data_row}
					action={this.state.action}
					reload={this.reloadData}
				/>
				<PopGlobal
					show={this.state.showGlobal}
					close={this.goToDashboard}
					message={this.state.message}
					message_type={this.state.message_type}
				/>
				<Label
					show={this.state.show_label}
					close={this.handleLabelClose}
					data={this.state.data_row}
				/>
			</Fragment>
		);
	}
}

export default withRouter(Tab_child);
