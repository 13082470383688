import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import AuthService from '../../api_getter/auth_service';

export default class HapusPromo extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
            success:false
        }
    }

    DeletePromo = () =>{
        AuthService.deletePromo(this.props.id)
        .then(
            (data) => {
                if(data.results){
                    this.setState({
                    message:data.results,
                    message_type:'alert-success',
                    success:true
                    })
                    this.props.reload() 
                    }
              else{
                this.setState({
                    message:data.error.message,
                    message_type:'alert-danger',
                    success:true
                })
            }
        })
    }

    quit = () =>{
        this.setState({
            message:'',
            message_type:'',
            success:false
        })
        this.props.close()
    }

  render() {
    return (
        <Modal
        show={this.props.show}
        onHide={this.quit}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Hapus promosi</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex justify-content-center'>
                    {this.state.message ? (
                    <div className={'alert '+this.state.message_type}>
                    {this.state.message}
                    </div>
                    ):(
                    <h5>Apakah anda yakin untuk menghapus promosi ini ? </h5>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-end'>
                <button className='btn btn-secondary' onClick={this.quit}>{this.state.success?'Close':'Tidak'}</button>
                &ensp;
                {this.state.success===false &&(<button className='btn btn-primary' onClick={this.DeletePromo}>Iya</button>)}
                </div>
            </Modal.Footer>
        </Modal>
    )
  }
}
