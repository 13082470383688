import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import AuthService from "../api_getter/auth_service";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PopAdmin from "./pop_admin";
import DatePicker from "react-date-picker";
import moment from "moment";

const animatedComponents = makeAnimated();

export default class EditReward extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            title: "",
            discount_percentage: 0,
            discount_price: 0,
            start_date: null,
            end_date: null,
            frame_id: [],
            lens_id: [],
            promo_code: "",
            description: "",
            status: 1,
            list_frame: [],
            list_lens: [],
            message: "",
            message_type: "",
            show_pop: false,
            selected_discount: 1,
            defaultLens: "",
            defaultFrame: "",
            is_max_percentage: 1,
            discount_percentage_max_value: 0,
        };
    }

    selectRadioType = (event) => {
        this.setState({
            type: parseInt(event.target.value),
        });
    };

    selectRadioDiscount = (event) => {
        this.setState({
            selected_discount: parseInt(event.target.value),
        });
    };

    selectRadioStatus = (event) => {
        this.setState({
            status: parseInt(event.target.value),
        });
    };

    changeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    selectFrame = (event) => {
        // const payload = event.map((id) => id.value);

        const defaultFrame = this.getItem(this.props.listProduct, event?.value);
        this.setState({
            frame_id: event?.value,
            defaultFrame,
        });
    };

    selectLens = (event) => {
        // const payload = event.map((id) => id.value);
        const defaultLens = this.getItem(this.props.listLens, event?.value);
        this.setState({
            lens_id: event?.value,
            defaultLens,
        });
    };

    onChangeDateStart = (date) => {
        this.setState({
            start_date: date,
        });
    };

    onChangeDateEnd = (date) => {
        this.setState({
            end_date: date,
        });
    };

    ChangeAction = () => {
        AuthService.updateReward(this.props.id, this.state.action).then((data) => {
            if (data.results) {
                this.setState({
                    message: data.results,
                    message_type: "alert-success",
                    edit: false,
                });
                this.props.reload();
            } else {
                this.setState({
                    message: data.error.message,
                    message_type: "alert-danger",
                });
            }
        });
    };

    quit = () => {
        this.setState({
            type: 1,
            title: "",
            discount_percentage: 0,
            discount_price: 0,
            start_date: new Date(),
            end_date: new Date(),
            frame_id: 0,
            lens_id: 0,
            promo_code: "",
            description: "",
            status: 1,
            list_frame: [],
            list_lens: [],
            message: "",
            message_type: "",
            show_pop: false,
            selected_discount: 1,
        });
        this.props.reload();
        this.props.close();
    };

    updateReward = () => {
        let payload = {
            type: this.state.type,
            title: this.state.title,
            start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
            end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
            frame_id: this.state.frame_id,
            lens_id: this.state.lens_id,
            promo_code: this.state.promo_code,
            description: this.state.description,
            status: this.state.status,
            limit_redeem: this.state.limit_redeem,
            point_use: this.state.point_use,
        };
        if (this.state.type === 1 && this.state.selected_discount === 1) {
            payload.discount_percentage = this.state.discount_percentage
            payload.is_max_percentage = this.state.is_max_percentage === 1 ? 0 : 1
            payload.discount_type = this.state.selected_discount === 1 ? 0 : 1
            if (this.state.is_max_percentage === 1) payload.discount_percentage_max_value = this.state.discount_percentage_max_value
        }
        if (this.state.type === 1 && this.state.selected_discount === 2) {
            payload.discount_price = this.state.discount_price
            payload.discount_type = this.state.selected_discount === 1 ? 0 : 1
        }
        AuthService.updateReward(this.props.id, payload)
            .then((res) => {
                if (res.success) {
                    this.setState({
                        message: "Data Berhasil diupdate",
                        message_type: "alert-success",
                        show_pop: true,
                    });
                } else {
                    throw new Error(res.message);
                }
            })
            .catch((res) => {
                let message = res.message;
                if (res.response?.data) message = res.response.data.message;
                this.setState({
                    success: false,
                    message: message,
                    message_type: "alert-danger",
                    show_pop: true,
                });
            });
    };

    setValue = async () => {
        if (!this.props.id) return;
        const res = await AuthService.detailReward(this.props.id);
        const data = res.data;
        let meta = {}
        const defaultFrame = this.getItem(this.props.listProduct, data.frame_id);
        const defaultLens = this.getItem(this.props.listLens, data.lens_id);
        if (!!data.meta) meta = JSON.parse(data.meta)

        this.setState({
            type: data.type.value,
            title: data.title,
            selected_discount: data.discount_price ? 2 : 1,
            discount_percentage: data.discount_percentage,
            discount_price: data.discount_price,
            start_date: new Date(data.start_date),
            end_date: new Date(data.end_date),
            frame_id: data.frame_id || "",
            lens_id: data.lens_id || "",
            promo_code: data.promo_code,
            limit_redeem: data.limit_redeem,
            point_use: data.point_use,
            description: data.description,
            status: data.status.value,
            defaultFrame,
            defaultLens,
            is_max_percentage: Number(meta?.is_max_perecentage) === 0 ? 1 : 2,
            discount_percentage_max_value: meta?.discount_percentage_max_value,
        });
    };
    selectRadioMaxPercentage = (event) => {
        this.setState({
            is_max_percentage: parseInt(event.target.value),
        });
    };

    getItem = (collection = [], id, key = "value") => {
        const val = collection.find((v) => {
            return parseInt(v[key]) === parseInt(id);
        });
        if (!val) return null;
        return {value: val.value, label: val.label};
    };

    handleQuit = () => {
        if (this.state.success)
            this.props.history.push(`${process.env.PUBLIC_URL}/reward-loyalty`);
    }

    // InputFrame = () => {
    //   return (
    //     <Select
    //       components={animatedComponents}
    //       isMulti={false}
    //       options={this.props.listProduct}
    //       onChange={this.selectFrame}
    //       name="frame_id"
    //       placeholder="Pilih disini"
    //       defaultValue={this.state.defaultFrame}
    //     />
    //   );
    // };

    render() {
        const InputFrame = () => {
            return (
                <Select
                    components={animatedComponents}
                    isClearable={true}
                    options={this.props.listLens}
                    options={this.props.listProduct}
                    onChange={this.selectFrame}
                    name="frame_id"
                    placeholder="Pilih disini"
                    defaultValue={this.state.defaultFrame}
                />
            );
        };

        const InputLens = () => {
            return (
                <Select
                    components={animatedComponents}
                    isClearable={true}
                    options={this.props.listLens}
                    onChange={this.selectLens}
                    name="lens_id"
                    placeholder="Pilih disini"
                    defaultValue={this.state.defaultLens}
                />
            );
        };

        const SaveButton = () => {
            if (!this.props.edit) return "";
            return (
                <button className="btn btn-primary" onClick={this.updateReward}>
                    Simpan
                </button>
            );
        };

        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.quit}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="lg"
                    onShow={this.setValue}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Reward</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row align-items-stretch">
                            <div className="col-md-6">
                                <div>
                                    <label>Tipe Reward</label>
                                    <br/>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="type"
                                            value={1}
                                            onChange={this.selectRadioType}
                                            checked={this.state.type === 1}
                                        />
                                        <label className="form-check-label" htmlFor="type1">
                                            Promo
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="type"
                                            value={2}
                                            onChange={this.selectRadioType}
                                            checked={this.state.type === 2}
                                        />
                                        <label className="form-check-label" htmlFor="type2">
                                            Giveaway
                                        </label>
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <label>Judul</label>
                                    <br/>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Masukkan nama promo/hadiah"
                                        className="form-control"
                                        value={this.state.title}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>
                                {this.state.type === 1 && (
                                    <>
                                        <div className='w-full'>
                                            <label htmlFor='discount_percentage'>
                                                Presentase Diskon
                                            </label>
                                            <div className='input-group align-items-center mb-3'>
                                                <input
                                                    type='radio'
                                                    name='selected_discount'
                                                    value={1}
                                                    onChange={
                                                        this.selectRadioDiscount
                                                    }
                                                    checked={
                                                        this.state
                                                            .selected_discount === 1
                                                    }
                                                />
                                                <div className='input-group-prepend ml-4'>
												<span className='input-group-text'>
													%
												</span>
                                                </div>
                                                <input
                                                    type='number'
                                                    name='discount_percentage'
                                                    className='form-control'
                                                    value={
                                                        this.state
                                                            .discount_percentage
                                                    }
                                                    onChange={this.changeInput}
                                                    min='0'
                                                    step='1'
                                                    max='100'
                                                    placeholder='Masukkan nilai persentase diskon'
                                                    disabled={
                                                        this.state
                                                            .selected_discount !== 1
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {this.state.selected_discount === 1 && (
                                            <>
                                                <div>
                                                    <label>Max Percentage</label>
                                                    <br/>
                                                    <div className='form-check form-check-inline'>
                                                        <input
                                                            id='isMaxPercentage1'
                                                            className='form-check-input'
                                                            type='radio'
                                                            name='is_max_percentage'
                                                            value={1}
                                                            onChange={
                                                                this.selectRadioMaxPercentage
                                                            }
                                                            checked={
                                                                this.state.is_max_percentage === 1
                                                            }
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='isMaxPercentage1'
                                                        >
                                                            Aktif
                                                        </label>
                                                    </div>
                                                    <div className='form-check form-check-inline'>
                                                        <input
                                                            id='isMaxPercentage2'
                                                            className='form-check-input'
                                                            type='radio'
                                                            name='is_max_percentage'
                                                            value={2}
                                                            onChange={
                                                                this.selectRadioMaxPercentage
                                                            }
                                                            checked={
                                                                this.state.is_max_percentage === 2
                                                            }
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='isMaxPercentage2'
                                                        >
                                                            Tidak Aktif
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <br/>
                                        {this.state.is_max_percentage === 1 && (
                                            <>
                                                <div className='w-full'>
                                                    <label htmlFor='discount_percentage_max_value'>
                                                        Max Nominal Diskon
                                                    </label>
                                                    <div className='input-group align-items-center mb-3'>
                                                        <input
                                                            type='number'
                                                            name='discount_percentage_max_value'
                                                            className='form-control'
                                                            value={
                                                                this.state
                                                                    .discount_percentage_max_value
                                                            }
                                                            onChange={this.changeInput}
                                                            min='0'
                                                            step='1'
                                                            max='100'
                                                            placeholder='Masukkan nilai max nominal diskon'
                                                            disabled={
                                                                this.state
                                                                    .selected_discount !== 1
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <br/>
                                        <div className='w-full'>
                                            <label htmlFor='discount_price'>
                                                Potongan Harga
                                            </label>
                                            <div className='input-group align-items-center mb-3'>
                                                <input
                                                    type='radio'
                                                    name='selected_discount'
                                                    value={2}
                                                    onChange={
                                                        this.selectRadioDiscount
                                                    }
                                                    checked={
                                                        this.state
                                                            .selected_discount === 2
                                                    }
                                                />
                                                <div className='input-group-prepend ml-4'>
												<span className='input-group-text'>
													Rp.
												</span>
                                                </div>
                                                <input
                                                    type='number'
                                                    name='discount_price'
                                                    className='form-control'
                                                    value={
                                                        this.state.discount_price
                                                    }
                                                    onChange={this.changeInput}
                                                    min='0'
                                                    step='1'
                                                    placeholder='Masukkan nominal potongan harga'
                                                    disabled={
                                                        this.state
                                                            .selected_discount !== 2
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row">
                                    <div className="col-6">
                                        <label>Tanggal Berlaku</label>
                                        <DatePicker
                                            name="start_date"
                                            clearIcon={null}
                                            value={this.state.start_date}
                                            onChange={this.onChangeDateStart}
                                            className="form-control p-0"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>Hingga</label>
                                        <DatePicker
                                            name="end_date"
                                            clearIcon={null}
                                            value={this.state.end_date}
                                            onChange={this.onChangeDateEnd}
                                            className="form-control p-0"
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <label>Produk Frame</label>
                                    <br/>
                                    <InputFrame/>
                                </div>
                                <br/>
                                <div>
                                    <label>Produk Lensa</label>
                                    <br/>
                                    <InputLens/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label>Kode Promo</label>
                                    <br/>
                                    <input
                                        type="text"
                                        name="promo_code"
                                        placeholder="Masukkan nama promo/hadiah"
                                        className="form-control"
                                        value={this.state.promo_code}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>
                                <div>
                                    <label>Limit Redeem</label>
                                    <br/>
                                    <input
                                        type="number"
                                        name="limit_redeem"
                                        placeholder="Masukkan limit redeem"
                                        className="form-control"
                                        value={this.state.limit_redeem}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>
                                <div>
                                    <label>Point Use</label>
                                    <br/>
                                    <input
                                        type="number"
                                        name="point_use"
                                        placeholder="Masukkan Point Use"
                                        className="form-control"
                                        value={this.state.point_use}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>
                                <div>
                                    <label>Deskripsi</label>
                                    <br/>
                                    <textarea
                                        name="description"
                                        className="form-control"
                                        rows="3"
                                        placeholder="Masukkan deskripsi"
                                        value={this.state.description}
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <br/>

                                <div>
                                    <label>Status</label>
                                    <br/>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            value={1}
                                            onChange={this.selectRadioStatus}
                                            checked={this.state.status === 1}
                                        />
                                        <label className="form-check-label" htmlFor="status1">
                                            Aktif
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            value={0}
                                            onChange={this.selectRadioStatus}
                                            checked={this.state.status === 0}
                                        />
                                        <label className="form-check-label" htmlFor="status2">
                                            Tidak Aktif
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={this.quit}>
                            Tutup
                        </button>

                        <SaveButton/>
                    </Modal.Footer>
                </Modal>
                <PopAdmin
                    show={this.state.show_pop}
                    close={this.quit}
                    message={this.state.message}
                    message_type={this.state.message_type}
                    handleQuit={this.handleQuit}
                />
            </div>
        );
    }
}
