import React, { Component, Fragment, useState } from 'react';
import {Edit2,Save} from 'react-feather';
import Breadcrump from '../../common/breadcrumb';
import AuthService from '../../api_getter/auth_service';
import PopResponse from './pop_response';
import cookie from 'react-cookies';

const BrandDetailEdit =(props)=>{

    const [nama_merek,Setnama_merek]=useState(props.data.brand_name)
    const [media,Setmedia]=useState(props.data.media_url)
    const [files,Setfiles]=useState(props.data.files)

    const ChangeLogo = (e) =>{
        let file = e.target.files[0];
        let name = file.name;
        Setmedia(name);
        Setfiles(file);
    }

    const SaveAndQuit = () =>{
        let data_brand = {
            'brand_id':props.data.brand_id,
            'brand_name':nama_merek,
            'media_url':media,
            'files':files
            }
        props.update(data_brand)
        props.save()
    }

    return(
        <div className='card'>
               
                <div className='card-header'>
                    <div className='d-flex bd-highlight mb-3'>
                        <div className='p-2 bd-highlight'>
                          <h5>Identitas Produk</h5>
                        </div>
                        <div className='ml-auto p-2 bd-highlight'>
                            <button type="button" className='btn btn-outline-info'
                            onClick={SaveAndQuit}
                            >
                            selesai
                            </button>
                        </div>
                    </div>                   
                </div>
              
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <label>Nama merek</label><br/>
                        <input className='form-control' type='text' value={nama_merek} onChange={(e)=>Setnama_merek(e.target.value)}/>
                        </div>
                        <div className='col-md-6'>
                        <label>Upload logo brand</label><br/>
                        <div className="input-group mb-3">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="upload_logo" onChange={ChangeLogo}/>
                                <label className="custom-file-label" for="upload_logo">{media}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
const BrandDetail =(props)=>{
    const data = props.data;

    return(
        <div className='card'>
            <div className='card-header'>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <h5>Identitas Merek</h5>
                    </div>
                    <div className='ml-auto p-2 bd-highlight'>
                        <button type="button" className='btn btn-outline-info' onClick={props.edit}>
                            Edit
                        </button>
                    </div>
                </div>                   
            </div>

            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-3'>
                        <label>Nama merek</label>
                    </div>
                    <div className='col-md'>
                        <label>{data.brand_name}</label>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-md-3'>
                        <label>Logo</label>
                    </div>
                    <div className='col-md'>
                        <label>{data.media_url}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Allin = (props) =>{
    const [initDataBrand,SetinitDataBrand] = useState(props.data_brand);

    const [EditBrand,SeteditBrand]=useState(false);
    const editBrand = () =>{SeteditBrand(true)}
    const saveBrand = () =>{SeteditBrand(false)}

    const updateDataBrand = (newdata) => {SetinitDataBrand(newdata)}

    const [message,Setmessage]=useState('')
    const [message_type,Setmessage_type]=useState('')
    const [show,Setshow]=useState(false)

    const GoExit = () =>{
        Setmessage('');
        Setmessage_type('');
        Setshow(false);
        props.redir()

    }

    const updateMerek =()=>{
        AuthService.updateBrand(initDataBrand.brand_id,initDataBrand.files,initDataBrand.brand_name)
        .then(
            (data)=>{
                if(data.results){
                    Setmessage(data.results);
                    Setmessage_type('alert-success');
                    Setshow(true);
                }
                else{
                    Setmessage(data.error.message);
                    Setmessage_type('alert-danger');
                    Setshow(true);
                }
            }
        )
    }

    return(
        <Fragment>
            <Breadcrump title='Detail Merek Frame' parent="Produk / Merek Frame" />
            <div className='container-fluid'>
                {EditBrand?(
                    <BrandDetailEdit save={saveBrand} data={initDataBrand} update={updateDataBrand}/>
                ):(
                    <BrandDetail edit={editBrand} data={initDataBrand} />
                )}
                <div className='d-flex bd-highlight mb-3'>
                {(props.data_brand !== initDataBrand)&&
                <div className='ml-auto p-2 bd-highlight'>
                <button type='button' className='btn btn-info'
                onClick={updateMerek}
                >Simpan perubahan</button>
                </div>
                }
                </div>
            </div>
            <PopResponse show={show} close={GoExit} message={message} message_type={message_type}/>
        </Fragment>
    )
}

export default class DetailBrandFrame extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
            edit:false
        }
    }
    RedirToListBrandFrame = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/frame-brands`);
      }

  render() {
    const data = cookie.load('frame_brand_detail');
    const filename = data.media_url.split('/')
    const strfilename = filename[filename.length-1] 
    const data_brand = {
        'brand_id':data.brand_id,
        'brand_name':data.brand_name,
        'media_url':strfilename,
        'files':null
    }

    return (
      <Allin
      data_brand={data_brand}
      redir={this.RedirToListBrandFrame}
      />
    )
  }
}
