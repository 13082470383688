import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { ArrowLeft, Sliders } from 'react-feather';
import { Link, Redirect } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cookie from 'react-cookies'


export class Login extends Component {
    
    Login_success = () =>{
        this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
    }
    
    render() {
        let login_status = cookie.load('logged_in')
        if (login_status !== undefined){
            if (login_status.status===true){
                return <Redirect to="/dashboard"/>
            }
        }
        
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-7 p-0 mx-auto">
                                    <div className="card tab2-card">
                                        <div className="shadow-lg rounded">
                                        <div className="card-body">
                                            <LoginTabset Login_success = {this.Login_success}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Login