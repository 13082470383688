import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Download } from 'react-feather';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AuthService from '../../api_getter/auth_service';

const animatedComponents = makeAnimated();
export default class ActionTrx extends Component {
	constructor(props) {
		super(props);

		this.state = {
			action_selected: '',
			message: '',
			message_type: '',
		};
	}

	selectOption = (e) => {
		this.setState({ action_selected: e.value });
	};

	sendUpdate = () => {
		if(
			this.props.action === 'delete'
		) {
			AuthService.deleteTransaction(this.props.data.order_id).then((data) => {
				console.log(data)
				if (data?.success) {
					this.setState({
						message: 'Success Menghapus',
						message_type: 'alert-success',
					});
					this.props.reload();
				} else {
					this.setState({
						message: data?.error?.message,
						message_type: 'alert-danger',
					});
				}
			})
		}else {
			AuthService.setStatus(
				this.props.data.invoice_number,
				this.props.action,
			).then((data) => {
				if (data.status) {
					this.setState({
						message: data.status,
						message_type: 'alert-success',
					});
					this.props.reload();
				} else {
					this.setState({
						message: data.error.message,
						message_type: 'alert-danger',
					});
				}
			});
		}
	};

	closeAction = () => {
		this.setState({ message: '', message_type: '' });
		this.props.close();
	};

	render() {
		return (
			<Modal
				show={this.props.show}
				onHide={this.closeAction}
				backdrop='static'
				keyboard={false}
				centered
				size='md'
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className='d-flex justify-content-center'>
							{this.props.action.toUpperCase() + ' Transaction'}
						</div>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{this.state.message ? (
						<div className={'alert ' + this.state.message_type}>
							{this.state.message}
						</div>
					) : (
						<div className='row' style={{ margin: 'auto' }}>
							<h5>
								Apakah anda yakin {this.props.action} transaksi
								ini ?
							</h5>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					{this.state.message ? (
						<div>
							<button
								className='btn btn-outline-secondary'
								onClick={this.closeAction}
							>
								Oke
							</button>
						</div>
					) : (
						<div>
							<button
								className='btn btn-outline-secondary'
								onClick={this.closeAction}
							>
								No
							</button>
							<button
								className='btn btn-outline-primary'
								onClick={this.sendUpdate}
							>
								Yes
							</button>
						</div>
					)}
				</Modal.Footer>
			</Modal>
		);
	}
}
