import React, { Component, Fragment } from 'react'
import AuthService from '../../api_getter/auth_service';
import Breadcrump from '../../common/breadcrumb';
import PopResponse from '../brand_kacamata/pop_response';

export default class AddNewBrandLensa extends Component {
constructor(props){
    super(props)

    this.state={
        message:'',
        message_type:'',
        nama_merek:'',
        files:null,
        logo_name:'',
        show:false
    }
}
ChangeLogo = (e) =>{
    let file = e.target.files[0];
    let name = file.name;
    this.setState({logo_name:name,files:file});
}

ChangeName = (e) =>{
    this.setState({nama_merek:e.target.value})
}

SaveBrand = () =>{
    AuthService.addNewBrandLens(this.state.files,this.state.nama_merek).then(
        (data)=>{
            if(data.results){
                this.setState({
                    message:data.results,
                    message_type:'alert-success',
                    show:true
                })
            }
            else{
                this.setState({
                    message:data.error.message,
                    message_type:'alert-danger',
                    show:true
                }) 
            }
        }
    )
}

GoExit = () =>{
    this.setState({
        message:'',
        message_type:'',
        nama_merek:'',
        files:null,
        logo_name:'',
        show:false
    })
    this.props.history.push("/lens-brands")
}

  render() {
    return (
      <Fragment>
          <Breadcrump title='Add New Lens Brand' parent="Products / Lens Brands" />
          <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h5>Indentitas merek</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <label>Nama merek</label><br/>
                        <input className='form-control' type='text' value={this.state.nama_merek} onChange={this.ChangeName}/>
                        </div>
                        <div className='col-md-6'>
                        <label>Upload logo brand</label><br/>
                        <div className="input-group mb-3">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="upload_logo" onChange={this.ChangeLogo}/>
                                <label className="custom-file-label" for="upload_logo">{this.state.logo_name}</label>
                            </div>
                        </div>
                        <label><small>** upload file dengan ekstensi *.jpg/*.png maksimal 5 MB</small></label>
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-primary' onClick={this.SaveBrand}>Daftarkan merek baru</button>
          </div>
          <PopResponse show={this.state.show} close={this.GoExit} message={this.state.message}
          message_type={this.state.message_type}
          />
      </Fragment>
    )
  }
}
