import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Tab_child from './tab_child';


export default class AllTransaction extends Component {
    constructor(props) {
        super(props);
        //set query params tab
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab') ? urlParams.get('tab') : 'pending';

        this.state = {
            tab_key: tab,
        }
    }

    setKey = (k) => {
        this.setState({tab_key: k})
    }

    render() {
        // const opt_pending = [
        //   { value: 'processing', label: 'Process' },
        //   { value: 'canceled', label: 'Cancel' },
        // ]
        // const opt_processing = [
        //   { value: 'canceled', label: 'Cancel' },
        // ]
        // const opt_canceled = [
        //   { value: 'processing', label: 'Processing' },
        // ]
        return (
            <Fragment>
                <Breadcrumb title="Semua Transaksi" parent="Transaksi"/>
                <div className="container-fluid">
                    <div className='card'>
                        {/* <div className='card-header'>
              </div> */}
                        <div className='card-body'>
                            <Tabs
                                activeKey={this.state.tab_key}
                                onSelect={(k) => this.setKey(k)}
                                className='mb-4'
                                id="trx_tabs"
                            >
                                <Tab eventKey="pending" title="Pending">
                                    <Tab_child id="pending" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="settlement" title="Settlement">
                                    <Tab_child id="settlement" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="shipping" title="Shipping">
                                    <Tab_child id="shipping" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="success" title="Success">
                                    <Tab_child id="success" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="complete" title="Complete">
                                    <Tab_child id="complete" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="expire" title="Expire">
                                    <Tab_child id="expire" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="cancel" title="Cancel">
                                    <Tab_child id="cancel" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="deny" title="Deny">
                                    <Tab_child id="deny" event={this.state.tab_key}/>
                                </Tab>
                                <Tab eventKey="challenge" title="Challenge">
                                    <Tab_child id="challenge" event={this.state.tab_key}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
