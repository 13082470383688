import React, { Component } from 'react'
import {Modal} from 'react-bootstrap';
import AuthService from '../api_getter/auth_service';
import { withRouter } from 'react-router-dom';

class AdminStatus extends Component {
    constructor(props){
        super(props);

        this.state={
            message:'',
            message_type:'',
        }
    }

    adminEditStatus = () =>{
        let datatosend ={

        }
    
        AuthService.editAdmin(this.props.admin_id,this.props.status)
        .then(
          (data) => {
            if (data.results){
                this.quit()
                this.props.history.push(`${process.env.PUBLIC_URL}/users/admins`);
            }
            else{
                this.setState({message:data.error.message,message_type:'alert-danger'})
            } 
          }
        )
    }
    quit = () =>{
        this.setState({
            message:'',
            message_type:''
        })
        this.props.close()
    }
    
    render() {
    let status = this.props.status
    let action = ''
    let action_text = ''
    if (status === 1){
        action = 'activate';
        action_text = 'mengaktifkan';
    }
    else{
        action = 'deactivate';
        action_text = 'menonaktifkan';
    }
    let admin_name = this.props.admin_name
        return (
            <Modal
            show={this.props.show}
            onHide={this.quit}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
            >
            <Modal.Header closeButton>
                <Modal.Title>Peringatan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.message?
            (<div class="alert alert-danger" role="alert">
                {this.state.message}
            </div>
            ):(<div className='d-flex justify-content-center'>
                <label><strong>Apakah anda yakin {action_text} {admin_name} ?</strong></label>
            </div>)}
            </Modal.Body>
            {this.state.message?(
            <Modal.Footer>
            <button className='btn btn-outline-danger' 
            onClick={this.quit}
            >TUTUP
            </button>
            </Modal.Footer>
            ):(
            <Modal.Footer>
            <button className='btn btn-outline-danger' 
            onClick={this.quit}
            >
                TIDAK
            </button>
            <button className='btn btn-outline-success' 
            onClick={this.adminEditStatus}>
                IYA
            </button>
            </Modal.Footer>)}
            
            </Modal>
        )
    }
}
export default withRouter(AdminStatus);
