import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Edit2, Trash2, Upload } from "react-feather";
import AuthService from "../../api_getter/auth_service";
import PopFrame from "./pop_frame";
import PopLensa from "./pop_lensa";
import SuccessPromo from "./successpromo";
import EditPromo from "./edit_promo";
import HapusPromo from "./hapus_promo";
import PopGlobal from "../../error/response_global";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default class New_Promo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file_name: "",
      files: null,
      imgurl: "",
      show_frame: false,
      show_lensa: false,
      message: "",
      message_type: "",
      data_loaded: false,
      id_frame: null,
      frame: "",
      merek_frame: "Unknown",
      harga_frame: 0,
      id_lensa: null,
      lensa: "",
      merek_lensa: "Unknown",
      harga_lensa: 0,
      promo: 0,
      show: false,
      data_row: {},
      show_edit: false,
      show_delete: false,
      code: 0,
      showGlobal: false,
      description: EditorState.createEmpty(),
    };
    this.data_all_promo = [];
    this.data_column = [
      { dataField: "promo_id", hidden: "true" },
      { dataField: "media_url", text: "Cover", formatter: this.PhotoFormater },
      { dataField: "frame", text: "Frame" },
      { dataField: "lensa", text: "Lensa" },
      { dataField: "promo", text: "Promo" },
      { dataField: "status", text: "Status" },
      { dataField: "actions", text: "Actions", formatter: this.ActionFormater },
    ];
    this.Style = {
      image: {
        background: "#fefefe",
        height: 100,
        width: 170,
      },
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      description: editorState,
    });
  };

  ChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  SelectCover = (e) => {
    let file = e.target.files[0];
    let name = file.name;
    this.setState({ data_state: "loaded", file_name: name, files: file });
  };
  CloseFrame = () => {
    this.setState({ show_frame: false });
  };
  CloseLensa = () => {
    this.setState({ show_lensa: false });
  };
  CloseEdit = () => {
    this.setState({ show_edit: false });
  };
  CloseDelete = () => {
    this.setState({ show_delete: false });
  };
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    AuthService.getPromo().then((data) => {
      if (data.results) {
        this.data_all_promo = data.results;
        this.setState({ data_loaded: true });
      } else {
        this.setState({
          message: data.error.message,
          message_type: "alert-danger",
          code: data.error.code,
          showGlobal: true,
        });
      }
    });
  };
  goToDashboard = () => {
    if (this.state.code === 307) {
      this.setState({
        message: "",
        message_type: "",
        showGlobal: false,
      });
      this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } else {
      this.setState({
        message: "",
        message_type: "",
        showGlobal: false,
      });
    }
  };

  ActionFormater = (cell, row) => {
    return (
      <div>
        <a
          href="javascript:void(0)"
          onClick={() => this.setState({ show_edit: true, data_row: row })}
        >
          <Edit2 color="green" size="20" />
        </a>
        &ensp;&ensp;
        <a
          href="javascript:void(0)"
          onClick={() => this.setState({ show_delete: true, data_row: row })}
        >
          <Trash2 color="red" size="20" />
        </a>
      </div>
    );
  };

  PhotoFormater = (cell, row) => {
    return (
      <div>
        <img
          src={row.media_url}
          className="img-fluid"
          style={this.Style.image}
          alt="loading"
        />
      </div>
    );
  };

  FrameSelect = (id, frame, merek, harga) => {
    this.setState({
      frame: frame,
      merek_frame: merek,
      harga_frame: harga,
      id_frame: id,
    });
  };

  LensaSelect = (id, lens, merek, harga) => {
    this.setState({
      lensa: lens,
      merek_lensa: merek,
      harga_lensa: harga,
      id_lensa: id,
    });
  };
  ChangeFoto = (e) => {
    let file = e.target.files[0];
    let name = file.name;
    this.setState({ file_name: name, files: file });
  };
  AddNewPromo = () => {
    let data = {
      kacamata_id: this.state.id_frame,
      lens_id: this.state.id_lensa,
      discount: this.state.promo,
      file: this.state.files,
      description: draftToHtml(
        convertToRaw(this.state.description.getCurrentContent())
      ),
    };
    AuthService.addPromo(data).then((data) => {
      if (data.results) {
        this.setState({
          show: true,
          message: data.results,
        });
      } else {
        this.setState({
          show: true,
          message: data.error.message,
        });
      }
    });
  };

  closeOnSuccess = () => {
    this.setState({
      file_name: "",
      files: null,
      imgurl: "",
      show_frame: false,
      show_lensa: false,
      message: "",
      message_type: "",
      data_loaded: false,
      id_frame: null,
      frame: "",
      merek_frame: "Unknown",
      harga_frame: 0,
      id_lensa: null,
      lensa: "",
      merek_lensa: "Unknown",
      harga_lensa: 0,
      promo: 0,
      show: false,
    });
    this.loadData();
  };
  render() {
    const { SearchBar, ClearSearchButton } = Search;
    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      firstPageText: "First",
      lastPageText: "Last",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {},
      onSizePerPageChange: function (page, sizePerPage) {},
    });
    return (
      <Fragment>
        <Breadcrumb title="Promotion" parent="Transaksi" />
        <div className="container-fluid matta-no-arrow">
          <div className="card">
            <div className="card-header">
              <h5>Tambah Bundle Promosi</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <label>Pilih Frame</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      value={this.state.frame}
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        type="button"
                        onClick={() => this.setState({ show_frame: true })}
                      >
                        Pilih
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <label>Merek Frame</label>
                  <br />
                  <br />
                  <label>{this.state.merek_frame.toUpperCase()}</label>
                </div>
                <div className="col-md-6">
                  <label>Harga Frame</label>
                  <div className="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input
                      type="number"
                      name="harga_frame"
                      className="form-control"
                      min="0"
                      step="1000"
                      max="999999999"
                      value={this.state.harga_frame}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label>Pilih Lensa</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.lensa}
                    />
                    <div class="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => this.setState({ show_lensa: true })}
                      >
                        Pilih
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <label>Merek Lensa</label>
                  <br />
                  <br />
                  <label>{this.state.merek_lensa.toUpperCase()}</label>
                </div>
                <div className="col-md-6">
                  <label>Harga Lensa</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Rp</span>
                    </div>
                    <input
                      type="number"
                      name="harga_lensa"
                      className="form-control"
                      value={this.state.harga_lensa}
                      min="0"
                      step="1000"
                      max="999999999"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>Upload foto cover</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.file_name}
                    />
                    <div className="input-group-append">
                      <a
                        type="button"
                        className="btn btn-primary"
                        style={{
                          lineHeight: 25 + "%",
                          paddingTop: 5,
                          paddingBottom: 1,
                        }}
                      >
                        <label for="file-input">
                          <i>
                            <Upload size="20" />
                          </i>
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          onChange={this.ChangeFoto}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>Besaran promo</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Rp</span>
                    </div>
                    <input
                      type="number"
                      name="promo"
                      className="form-control"
                      onChange={this.ChangeInput}
                      min="0"
                      step="1000"
                      max="999999999"
                      value={this.state.promo}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <Editor
                      editorState={this.state.description}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor border rounded p-3"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-6">
                  <label>Harga akhir</label>
                  <h5>
                    <strong>
                      Rp.{" "}
                      {this.state.harga_frame +
                        this.state.harga_lensa -
                        this.state.promo}
                    </strong>
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn btn-secondary form-control"
                    onClick={this.AddNewPromo}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5>Daftar Promosi</h5>
            </div>
            <div className="card-body">
              <ToolkitProvider
                bootstrap4
                keyField="promo_id"
                data={this.data_all_promo}
                columns={this.data_column}
                search
              >
                {(props) => (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />

                    <BootstrapTable
                      id="promo_table"
                      striped
                      hover
                      condensed
                      pagination={pagination}
                      {...props.baseProps}
                      classes="table table-borderless"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
        <PopFrame
          show={this.state.show_frame}
          close={this.CloseFrame}
          select={this.FrameSelect}
        />
        <PopLensa
          show={this.state.show_lensa}
          close={this.CloseLensa}
          select={this.LensaSelect}
        />
        <SuccessPromo
          show={this.state.show}
          close={this.closeOnSuccess}
          message={this.state.message}
        />
        <EditPromo
          show={this.state.show_edit}
          close={this.CloseEdit}
          id={this.state.data_row.promo_id}
          status={this.state.data_row.status}
          reload={this.loadData}
        />
        <HapusPromo
          show={this.state.show_delete}
          close={this.CloseDelete}
          id={this.state.data_row.promo_id}
          reload={this.loadData}
        />
        <PopGlobal
          show={this.state.showGlobal}
          close={this.goToDashboard}
          message={this.state.message}
          message_type={this.state.message_type}
        />
      </Fragment>
    );
  }
}
