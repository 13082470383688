import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AuthService from "../../api_getter/auth_service";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Edit2 } from "react-feather";

const animatedComponents = makeAnimated();

export default class InputResi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kurir: "",
      no_resi: "",
      edit: false,
      message: "",
      message_type: "",
      success: false,
      loading: false,
    };
    this.baseState = this.state;
  }

  UpdateResi = (id, noresi, kurir) => {
    let dataKurir = kurir
    if (dataKurir == 'J&T') {
      dataKurir = 'jnt'
    }
    this.setState({
      loading: true,
    });
    if (id === null || noresi === "") {
      this.setState({
        message: "nomor resi tidak boleh kosong",
      });
    } else {
      let data = {
        resi: noresi,
        courier: dataKurir.toLowerCase(),
      };
      AuthService.getTrackNew(JSON.stringify(data)).then((response) => {
        let data = response.data.data;
        if (data.error) {
          this.setState({
            message_type: "alert-danger",
            success: false,
            edit: false,
            message: "Waybill not found",
            // message:data.error.message
          });
        } else {
          AuthService.updateResi(id, noresi).then((data) => {
            if (data.status == "successfully") {
              this.setState({
                message_type: "alert-success",
                success: true,
                edit: false,
                message: "Success",
                // message:data.results
              });
            } else {
              this.setState({
                message_type: "alert-danger",
                success: false,
                edit: false,
                message: "Error",
                // message:data.error.message
              });
            }
          });
        }
        this.setState({
          loading: false,
        });
      });
    }
  };

  selectOption = (e) => {
    this.setState({
      kurir: e.label,
    });
  };

  closeModal = () => {
    this.setState({
      kurir: "",
      no_resi: "",
      edit: false,
      message: "",
      message_type: "",
      success: false,
    });
    this.props.close();
    this.props.reload();
  };

  render() {
    let data = this.props.data;
    let kurir = "";
    let sliceKurir = [];
    let noresi = "";
    let id_shipping = null;
    if (data.receipt !== undefined) {
      noresi = data.receipt;
    }

    if (data.shipping_id !== undefined) {
      id_shipping = data.shipping_id;
      kurir = data.shipper_name;
      sliceKurir = data.shipper_name.split(" ");
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Input nomor resi</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.message === "" ? (
            <div>
              <div className="d-flex">
                <div className="col-3">Nama Kurir</div>
                <div className="col-9 font-weight-bold">{kurir}</div>
              </div>
              {/* {this.state.edit&&(<div className='col-md-4'>
                    <label>Nama kurir</label>
                    <Select
                        components={animatedComponents}
                        options={kurir}
                        onChange={this.selectOption}
                        name="kurir"
                        placeholder="Pilih kurir"
                        
                    />
                </div>)} */}
              <div className="col-12 mt-3">
                <label>Nomor resi</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan nomor resi"
                    disabled={this.state.edit ? "" : "disabled"}
                    onChange={(e) => this.setState({ no_resi: e.target.value })}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.setState({ edit: true })}
                      >
                        <Edit2 color="grey" size="18" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className={"alert " + this.state.message_type} role="alert">
                {this.state.message}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.state.edit && (
            <button
              className="btn btn-outline-info"
              onClick={() =>
                this.UpdateResi(id_shipping, this.state.no_resi, sliceKurir[0])
              }
              disabled={this.state.loading}
            >
              {this.state.loading ? "MEMPROSES" : "SIMPAN"}
            </button>
          )}

          {this.state.success && (
            <button className="btn btn-outline-info" onClick={this.closeModal}>
              TUTUP
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
